import React, { useState, useEffect } from "react";
import RequestForReassignModal from "./RequestForReassignModal";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, Navigate, Link } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import Badge from "react-bootstrap/Badge";
import {
  fetchActionList,
  fetchSingleAction,
  processRequestForReassign,
  updateIsComplete,
} from "../../redux/actions/actionAction/ActionAction";
import { logOut } from "../../redux/actions/authActions/authAction";
import IsCompleteModal from "./IsCompleteModal";
import LoaderButton from "../Common/LoaderButton";
const ViewActionDetails = (props) => {
  console.log(props);

  const userData = JSON.parse(localStorage.getItem("userData"));
  const accessToken = localStorage.getItem("accessToken");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authData = useSelector((state) => state.auth);
  const meetingData = useSelector((state) => state.meeting);
  const loginUserData = useSelector((state) => state.user);
  const actionData = useSelector((state) => state.action);
  const employeeData = useSelector((state) => state.user);
  const [isRequestForReassignModalOpen, setIsRequestForReassignModalOpen] =
    useState(false);
  const [isCompleteModalOpen, setIsCompleteModalOpen] = useState(false);
  const [actionId, setActionId] = useState(null);

  const [isComplete, setIsComplete] = useState(null);
  console.log(authData);
  if (authData.isInValidUser) {
    console.log("innnnnnnnnnnnnnnnnnnnnnnnnnnn");
    localStorage.removeItem("accessToken");
    localStorage.removeItem("userData");
    localStorage.removeItem("rememberMe");
    dispatch(logOut());
    navigate("/login");
  }

  useEffect(() => {
    document.title = "View Action Details: Meeting Plus";
    console.log("inside use effect", actionData);

    if (actionData.isSuccess) {
      console.log("inside use effect 222222222");
      setIsCompleteModalOpen(false);
      setIsRequestForReassignModalOpen(false);
    }
    return () => {};
  }, [
    actionData?.singleActionDetails?.isComplete,
    actionData.isSuccess,
    actionData.isFetchedActionList,
  ]);

  const handleRequestForReassign = (reason) => {
    console.log("ccccccccccc", actionId, reason);

    dispatch(
      processRequestForReassign(
        actionId,
        { requestDetails: reason },
        accessToken
      )
    );
    setActionId(null);
    setIsRequestForReassignModalOpen(false);
  };

  const handleUpdateIsComplete = (comment) => {
    console.log("ccccccccccc", actionId, comment);
    dispatch(
      updateIsComplete(
        props.actionId,
        { isComplete: !actionData?.singleActionDetails?.isComplete, comment },
        accessToken
      )
    );
    setActionId(null);
  };
  const handleRequestForReassignModal = (actionId) => {
    console.log("gggggggggggggg", actionId);
    setActionId(actionId);
    setIsRequestForReassignModalOpen(true);
  };

  const checkHandler = (actionId) => {
    console.log("gggggggggggggg", actionId);
    setActionId(actionId);
    setIsCompleteModalOpen(true);

    // const { name, value, checked } = e.target;

    // console.log(checked);
    // setIsComplete(!isComplete);
    // dispatch(
    //   updateIsComplete(
    //     props.actionId,
    //     { isComplete: !actionData?.singleActionDetails?.isComplete },
    //     accessToken
    //   )
    // );
  };
  console.log(actionData?.singleActionDetails?.reassigneRequestDetails);
  console.log(actionData?.singleActionDetails?.reassigneRequestDetails?.length);
  console.log(employeeData?.userData?._id);
  const isUserFind =
    actionData?.singleActionDetails?.reassigneRequestDetails?.filter(
      (user) =>
        user?.userId?.toString() === employeeData?.userData?._id?.toString()
    );
  console.log(isUserFind);
  console.log(actionData?.singleActionDetails?.reassigneRequestDetails);
  console.log(actionData?.singleActionDetails?.assignedUserId);
  return (
    <>
      {/* <div className="meeting-header-text actionDetail-header"> */}

      {/* <div>
      <h4 className="mb-0">Actions</h4>
    </div> */}

      {/* <div className="right-tbl-bottom">
      <a href="">
        <button className="view-meeting Mom-btn">
          <p>View Minutes</p>
        </button>
      </a>
    </div> */}

      {/* <div className="action-details-close-button"><a href="/actions">
        <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="currentColor" className="bi bi-x"
          viewBox="0 0 16 16">
          <path
            d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
        </svg></a>
    </div>  */}

      {/* </div> */}

      {/* <div className="mt-3 mb-3 row details-container"> */}
      <div className="mt-3 mb-3">
        <div className="left-detailbox">
          <h4 className="Detailsheading">Details</h4>

          <div className="mt-3 form-group">
            <div className="row">
              <div className="col-md-4">
                <label className="mb-1">Meeting Title</label>
              </div>
              <div className="col-md-8">
                <Link
                  to={`/view-meeting-details/${actionData?.singleActionDetails?.meetingDetail?._id}`}
                  // state={{ meetingId: props.meetingId,isViewMeetingPage:true}}
                  style={{
                    textDecoration: "none",
                    color: "black",
                  }}
                >
                  <p>
                    {actionData?.singleActionDetails?.meetingDetail?.title
                      .charAt(0)
                      .toUpperCase() +
                      actionData?.singleActionDetails?.meetingDetail?.title
                        .slice(1)
                        .toLowerCase()}
                  </p>
                </Link>
              </div>
            </div>
          </div>

          <div className="form-group">
            <div className="row">
              <div className="col-md-4">
                <label className="mb-1">Held On</label>
              </div>
              <div className="col-md-4">
                <p>{actionData?.singleActionDetails?.meetingDetail?.date}</p>
              </div>
            </div>
          </div>

          <div className="form-group">
            <div className="row">
              <div className="col-md-4">
                <label className="mb-1">Action Description</label>
              </div>
              <div className="col-md-8">
                <p>
                  {actionData?.singleActionDetails?.description
                    .charAt(0)
                    .toUpperCase() +
                    actionData?.singleActionDetails?.description
                      .slice(1)
                      .toLowerCase()}
                </p>
              </div>
            </div>
          </div>

          <div className="form-group">
            <div className="row">
              <div className="col-md-4">
                <label className="mb-1">Agenda</label>
              </div>
              <div className="col-md-8">
                <p>
                  {actionData?.singleActionDetails?.agendaDetail?.title
                    .charAt(0)
                    .toUpperCase() +
                    actionData?.singleActionDetails?.agendaDetail?.title
                      .slice(1)
                      .toLowerCase()}
                </p>
              </div>
            </div>
          </div>

          <div className="form-group">
            <div className="row">
              <div className="col-md-4">
                <label className="mb-1">Action Due Date</label>
              </div>
              <div className="col-md-8">
                <p>{actionData?.singleActionDetails?.dueDate}</p>
              </div>
            </div>
          </div>

          {/* <div className="form-group">
            <div className="row">
              <div className="col-md-4">
                <label className="mb-1">Action assigned id</label>
              </div>
              <div className="col-md-8">
              <p>{actionData?.singleActionDetails?._id}</p>
                <p>{actionData?.singleActionDetails?.assignedUserId}</p>
                <p>{ employeeData?.userData?._id?.toString() }</p>
              </div>
            </div>
          </div> */}

          <div className="form-group">
            <div className="row">
              <div className="col-md-4">
                <label className="mb-1">Priority</label>
              </div>
              <div className="col-md-8">
                <p>
                  {actionData?.singleActionDetails?.priority
                    .charAt(0)
                    .toUpperCase() +
                    actionData?.singleActionDetails?.priority
                      .slice(1)
                      .toLowerCase()}
                </p>
              </div>
            </div>
          </div>

          <div className="form-group">
            <div className="row">
              <div className="col-md-4">
                <label className="mb-1">Status</label>
              </div>
              <div className="col-md-8">
                {}
                {/* <p>
                  {actionData?.singleActionDetails?.isComplete === true ? (
                    <Badge bg="success">Completed</Badge>
                  ) : (employeeData.userData.isAdmin ||
                      employeeData.userData.isMeetingOrganiser) &&
                    actionData?.singleActionDetails?.reassigneRequestDetails
                      .length !== 0 &&
                    actionData?.singleActionDetails?.reassigneRequestDetails[
                      actionData?.singleActionDetails?.reassigneRequestDetails
                        .length - 1
                    ].isAccepted === false &&
                    actionData?.singleActionDetails?.reassigneRequestDetails[
                      actionData?.singleActionDetails?.reassigneRequestDetails
                        .length - 1
                    ].isRejected === false ? (
                    <Badge bg="warning"> Reassignment Requested</Badge>
                  ) : !employeeData.userData.isAdmin &&
                    !employeeData.userData.isMeetingOrganiser &&
                    actionData?.singleActionDetails?.reassigneRequestDetails
                      .length !== 0 &&
                    actionData?.singleActionDetails?.reassigneRequestDetails[
                      actionData?.singleActionDetails?.reassigneRequestDetails
                        .length - 1
                    ].isAccepted === false ? (
                    <Badge bg="warning"> Reassignment Requested</Badge>
                  ) : (
                    <Badge bg="warning"> Pending</Badge>
                  )}
                </p> */}

                <p>
                  {actionData?.singleActionDetails?.isComplete === true ? (
                    <Badge bg="success">Completed</Badge>
                  ) : employeeData?.userData?.isAdmin === false &&
                    employeeData?.userData?.isMeetingOrganiser === false &&
                    actionData?.singleActionDetails?.reassigneRequestDetails
                      ?.length !== 0 &&
                    actionData?.singleActionDetails?.reassigneRequestDetails[
                      actionData?.singleActionDetails?.reassigneRequestDetails
                        .length - 1
                    ].isAccepted === false &&
                    actionData?.singleActionDetails?.reassigneRequestDetails[
                      actionData?.singleActionDetails?.reassigneRequestDetails
                        .length - 1
                    ].isRejected === false &&
                    actionData?.singleActionDetails?.reassigneRequestDetails[
                      actionData?.singleActionDetails?.reassigneRequestDetails
                        ?.length - 1
                    ]?.userId?.toString() ===
                      employeeData?.userData?._id?.toString() ? (
                    //  &&
                    // action?.reassigneRequestDetails?.find(
                    //     (details) =>
                    //       details?.userId?.toString() ===
                    //       userData?._id?.toString()
                    //   )?.isRejected === false
                    <Badge bg="warning"> Reassignment Requested</Badge>
                  ) : (employeeData.userData.isAdmin ||
                      employeeData.userData.isMeetingOrganiser) &&
                    actionData?.singleActionDetails?.reassigneRequestDetails
                      .length !== 0 &&
                    actionData?.singleActionDetails?.reassigneRequestDetails[
                      actionData?.singleActionDetails?.reassigneRequestDetails
                        .length - 1
                    ].isAccepted === false &&
                    actionData?.singleActionDetails?.reassigneRequestDetails[
                      actionData?.singleActionDetails?.reassigneRequestDetails
                        .length - 1
                    ].isRejected === false ? (
                    <Badge bg="warning"> Reassignment Requested</Badge>
                  ) : (
                    <Badge bg="danger"> Pending</Badge>
                  )}
                </p>
              </div>
            </div>
          </div>
          {/* {employeeData?.userData?.isAdmin === true ? null : ( */}
          <>
            {(actionData?.singleActionDetails?.reassigneRequestDetails
              ?.length === 0 ||
              actionData?.singleActionDetails?.reassigneRequestDetails[
                actionData?.singleActionDetails?.reassigneRequestDetails
                  .length - 1
              ]?.isAccepted === false &&   actionData?.singleActionDetails?.reassigneRequestDetails[
                actionData?.singleActionDetails?.reassigneRequestDetails
                  .length - 1
              ]?.isRejected === true) &&
            // !isUserFind &&
            actionData?.singleActionDetails?.isComplete === false &&
            actionData?.singleActionDetails?.assignedUserId.toString() ===
              employeeData?.userData?._id?.toString() ? (
              <div className="complete-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  // value={formData.isAction}
                  id="flexCheckDefault"
                  name="isComplete"
                  onChange={() => checkHandler(props.actionId)}
                  //  checked={formData.isAction}
                  checked={actionData?.singleActionDetails?.isComplete}
                />
                <label>Mark as completed</label>
              </div>
            ) : null}
            {
              // (actionData?.singleActionDetails?.reassigneRequestDetails
              //   ?.length !== 0 &&
              //   isUserFind) ||
              // employeeData?.userData?._id?.toString() !==
              //   actionData?.singleActionDetails?.assignedUserId.toString() ||
              // actionData?.singleActionDetails?.isComplete === true
(
              // (actionData?.singleActionDetails?.reassigneRequestDetails
              //   ?.length === 0 ||
              //   actionData?.singleActionDetails?.reassigneRequestDetails[
              //     actionData?.singleActionDetails?.reassigneRequestDetails
              //       .length - 1
              //   ]?.isAccepted === false) &&
              (isUserFind?.length===0 ||
                ((isUserFind[isUserFind?.length-1].isAccepted === false &&
                  isUserFind[isUserFind?.length-1].isRejected === true)
                //    ||
                // (isUserFind[isUserFind?.length-1].isAccepted === false &&
                //   isUserFind[isUserFind?.length-1].isRejected === false)
                )) &&
               actionData?.singleActionDetails?.isComplete === false &&
              actionData?.singleActionDetails?.assignedUserId.toString() ===
                employeeData?.userData?._id?.toString()) ? (
                <>
                  {actionData.loading && !actionData.isSuccess ? (
                    <button className="add-btn Mom-btn mt-5" type="button">
                      <Spinner
                        as="span"
                        animation="grow"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                      Processing...
                    </button>
                  ) : (
                    <button
                      className="add-btn Mom-btn mt-5"
                      type="button"
                      onClick={() => {
                        handleRequestForReassignModal(props.actionId);
                      }}
                    >
                      <p>Request for Reassignment</p>
                    </button>
                  )}
                </>
              ) : null
            }
          </>
          {/* )} */}
          {/* <div className="tbl-bottom">
    <div className="left-tbl-bottom">
      <button className="reset">
        <p>Cancel</p>
      </button>
    </div>

    <div className="right-tbl-bottom">
      <button className="view-meeting Mom-btn">
        <p>View Meeting</p>
      </button>
    </div>
  </div>  */}
        </div>
      </div>

      {/* <ng-template #requestModalContent let-modal>
  <div className="modal-header">
    <h4 className="modal-title">Reassign Request Details</h4>
    <button type="button" className="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div className="modal-body delete-txt">
    <h6>Request</h6>
    <textarea cols="40" rows="4" id="remark" name="remark" placeholder="" required></textarea>
  </div>
  <div className="modal-footer">
    <button type="button" className="reset btn btn-secondary bg-white border-primary text-primary"
    (click)="modal.close('Close click')">
    <p>Close</p>
  </button>
    <button type="button" className="Mom-btn btn btn-secondary bg-primary border-primary">
      <p>Submit Request</p>
    </button>
  
  </div>

 
</ng-template> */}
      <RequestForReassignModal
        setIsModalOpen={setIsRequestForReassignModalOpen}
        isModalOpen={isRequestForReassignModalOpen}
        handleSubmit={handleRequestForReassign}
      />
      <IsCompleteModal
        setIsModalOpen={setIsCompleteModalOpen}
        isModalOpen={isCompleteModalOpen}
        handleSubmit={handleUpdateIsComplete}
      />
    </>
  );
};

export default ViewActionDetails;
