import React, { useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import Modal from "react-bootstrap/Modal";
import { useSelector, useDispatch } from "react-redux";
import AttendeeRsvpTable from "./AttendeeRsvpTable";
import AddAttendees from "./AddAttendees";
const AddAttendeesModal = (props) => {
  console.log(props);
  const { attendees } = props;
  const meetingData = useSelector((state) => state.meeting);
  console.log(attendees);
  console.log("inside -------------------55566")
  useEffect(() => {
    console.log("inside -------------------555")
    if (meetingData.isSuccess) {
      props.setIsAddAttendeeModal(false);
    }
  }, [meetingData.isSuccess]);
  return (
    <>
      <Modal
        show={props.isAddAttendeeModal}
        onHide={(e) => props.setIsAddAttendeeModal(false)}
      >
       

        <div className="modal-header attendees-modal">
        <h4 className="modal-title">Add Attendees</h4>
            <div>
            <button
                type="button"
                className="btn-close"
                aria-label="Close"
                onClick={(e) => props.setIsAddAttendeeModal(false)}
              ></button>
            </div>
          </div>



       

       

          {/* <Button variant="primary"> 
           Save changes 
          </Button> 
          <Button variant="secondary"> 
           Close 
          </Button>  */}
      
        <Modal.Body>
            {/* <div className="attendees-popup modal-margin">
            <AttendeeRsvpTable rsvpObject={rsvpObject}/>
             </div> */}
            <AddAttendees meetingData={props.meetingData} />
          </Modal.Body>
          
      </Modal>
    </>
  );
};

export default AddAttendeesModal;
