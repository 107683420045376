
import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import * as constantMessages from "../../constants/constatntMessages";

const AmendmentRequestModal = (props) => {
  console.log(props);
  const [details, setDetails] = useState("");
  const [errors, setErrors] = useState({});
  const handleSubmit = () => {
    const newErrors = validateForm();
    setErrors(newErrors);
    if (Object.keys(newErrors).length === 0) {
      props.handleSubmit(details);
      setDetails("");
    }
  };
  const validateForm = () => {
    console.error(details);
    const errors = {};
    if (!details.trim()) {
      errors.details = constantMessages.detailsRequired;
    }
    setErrors(errors);
    return errors;
  };

  return (
    <>
      <Modal
        show={props.amendmentModal}
        onHide={(e) => props.setAmendmentModal(false)}
      >
        <div className="modal-header">
          <h4 className="modal-title">Request for Amendment</h4>
          <button
            type="button"
            className="btn-close"
            aria-label="Close"
            onClick={(e) => props.setAmendmentModal(false)}
          ></button>
        </div>
        <div className="modal-body delete-txt">
          <h6>Amendment Detail</h6>
          <textarea
            cols="40"
            rows="4"
            id="details"
            name="details"
            onBlur={validateForm}
            onChange={(e) => setDetails(e.target.value)}
            value={details}
            placeholder="Enter your amendment detail here..."
            required
          ></textarea>
          {errors.details && (
            <span className="error-message">{errors.details}</span>
          )}
        </div>

        <div className="modal-footer">
          <button
            type="button"
            onClick={handleSubmit}
            className="Mom-btn btn btn-secondary bg-primary border-primary"
          >
            <p>Submit Remark</p>
          </button>
          <button
            type="button"
            onClick={(e) => props.setAmendmentModal(false)}
            className="reset btn btn-light bg-white border-primary text-primary "
          >
            <p>Close</p>
          </button>
        </div>
      </Modal>
    </>
  );
};

export default AmendmentRequestModal;
