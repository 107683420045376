import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import * as constantMessages from "../../constants/constatntMessages";
import { setFinalMinuteData } from "../../redux/actions/minuteActions/MinuteAction";
import { useSelector, useDispatch } from "react-redux";
import { textBoxStrLength } from "../../config/config";
import { checkCharacterLeft } from "../../helpers/commonHelpers";

const IsCompleteModal = (props) => {
  console.log(props);

  const [reason, setReason] = useState("");
  const [errors, setErrors] = useState({});

  const actionData = useSelector((state) => state.action);
  useEffect(() => {
    return () => {
      console.log("call--------------->>>>>>>>>>>>>>");

      if (actionData.isSuccess) {
        console.log("inside use effect 222222222");
        setReason("");
      }
    };
  }, [actionData?.singleActionDetails?.isComplete, actionData.isSuccess]);

  const handleSubmit = () => {
    const newErrors = validateForm();
    setErrors(newErrors);
    if (Object.keys(newErrors).length === 0) {
      props.handleSubmit(reason);
    }
  };
  const validateForm = () => {
    console.error(reason);
    const errors = {};
    if (!reason.trim()) {
      errors.reason = constantMessages.commentsRequired;
    } else if (reason.trim().length < 3 || reason.trim().length > 100) {
      errors.reason = constantMessages.smallStringLengthError;
    }

    setErrors(errors);
    return errors;
  };

  const closeModal = () => {
    setReason("");
    setErrors({});
    props.setIsModalOpen(false);
  };

  const updateValues = (e) => {
    const { name, value } = e.target;
    if (value.length <= textBoxStrLength) {
      setReason(value);
    }
  };

  return (
    <>
      <Modal show={props.isModalOpen} onHide={closeModal}>
        <div className="modal-header">
          <h4 className="modal-title">Mark As Completed</h4>
          <button
            type="button"
            className="btn-close"
            aria-label="Close"
            onClick={closeModal}
          ></button>
        </div>
        <div className="modal-body delete-txt">
          <label className="mb-1">
            Remark
            <span className="star-mark">*</span>
          </label>
          <textarea
            cols="40"
            rows="4"
            id="remark"
            name="remark"
            onBlur={validateForm}
            onChange={updateValues}
            value={reason}
            required
          ></textarea>
          <p className="success-message">
            {checkCharacterLeft(reason)} Characters left
          </p>

          {errors.reason && (
            <span className="error-message">{errors.reason}</span>
          )}
        </div>

        <div className="modal-footer">
          <button
            type="button"
            onClick={handleSubmit}
            className="Mom-btn btn btn-secondary bg-primary border-primary"
          >
            <p>Update</p>
          </button>
        </div>
      </Modal>
    </>
  );
};

export default IsCompleteModal;
