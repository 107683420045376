import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, Navigate, Link, useParams } from "react-router-dom";
import Badge from "react-bootstrap/Badge";
import {
  downloadMOM,
  getSingleMeetingDetails,
  getTimelineList,
  setCreateNewMeetingPage,
  unSetSingleMeetingDetails,
} from "../../redux/actions/meetingActions/MeetingAction";
import Header from "../Common/Header/Header";
import MeetingHeader from "../Common/Header/MeetingHeader";
import Sidebar from "../Common/Sidebar/Sidebar";
import {
  convertFirstLetterOfFullNameToCapital,
  customName,
  formatDateTimeFormat,
  getTimeSession,
} from "../../helpers/commonHelpers";
import NoDataFound from "../Common/NoDataFound";
import Loader from "../Common/Loader";
import Alert from "../Common/Alert";
import {
  addAgendaDetails,
  createMinutes,
  processAmendmentRequest,
  rejectAcceptMinute,
  setSuccess,
  updateAmendmentRequest,
} from "../../redux/actions/minuteActions/MinuteAction";
import "./style/timeLine.css";
//import AddMinuteAgendaModal from "./AddMinuteAgendaModal";

const TimelinePage = (props) => {
  let { meetingId } = useParams();
  const location = useLocation();
  const stateData = location.state;
  console.log(stateData);
  const accessToken = localStorage.getItem("accessToken");
  const userData = JSON.parse(localStorage.getItem("userData"));
  const dispatch = useDispatch();
  const employeeData = useSelector((state) => state.user);
  const meetingRoomData = useSelector((state) => state.meetingRoom);
  const minuteData = useSelector((state) => state.minute);
  const meetingData = useSelector((state) => state.meeting);
  const [isMinutesRsvpStatusModal, setIsMinutesRsvpStatusModal] =
    useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [attendeesData, setAttendeesData] = useState([]);
  const [isDeleteMinuteModal, setIsDeleteMinuteModal] = useState(false);
  const [isEditMinuteModal, setIsEditMinuteModal] = useState(false);
  const [minuteDetails, setMinuteDetails] = useState(null);
  const [agendaData, setAgendaData] = useState(null);
  const [amendmentModal, setAmendmentModal] = useState(false);
  const [createdById, setCreatedById] = useState(null);
  const [details, setDetails] = useState(null);
  const [amendmentViewModal, setAmendmentViewModal] = useState(false);
  const [isDeleteAgendaModal, setIsDeleteAgendaModal] = useState(false);
  const [status, setStatus] = useState(null);
  const [minuteId, setMinuteId] = useState(null);
  console.log(minuteData);
  console.log("use effect------------------------------------");
  useEffect(() => {
    document.title = "View Minutes: Meeting Plus";
    console.log("use effect------------------------------------");
    console.log(stateData);
    dispatch(getTimelineList(meetingId, accessToken));
    // dispatch()
    return () => {
      console.log("return useeffect--------------->>>>>>>>>>>>>>");
      // dispatch(unSetSingleMeetingDetails);
    };
  }, []);
  console.log(employeeData);
  console.log(meetingData.timeLineDatas);

  return (
    <>
      <Header />
      <Sidebar />
      {meetingData?.timeLineDatas?.length !== 0 ? (
        <div className="main-content">
          <section className="meeting">
            <div className="meeting-page">
              <div className="row">
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 detail-col">
                  <div className="meeting-header-text">
                    <h4 className="mb-2">Timeline</h4>
                  </div>

                  <form className="details-form">
                    {" "}
                    {meetingData.loading ? (
                      <div
                        className="meeting-page "
                        style={{ textAlign: "center", paddingTop: 20 }}
                      >
                        <Loader />
                      </div>
                    ) : (
                      <div className="inner-detail-form">
                        {meetingData?.timeLineDatas?.followonMeetingDetails
                          ?.length !== 0 &&
                          meetingData?.timeLineDatas?.followonMeetingDetails?.map(
                            (meeting, index) => {
                              return (
                                <div className="meeting-1 pb-5">
                                  <div className="meeting-count1">
                                    {index + 1}
                                  </div>
                                  <div className="meeting-1-details">
                                    <div>
                                      <h5 className="meeting-day">
                                        {meeting?.title}
                                      </h5>
                                    </div>
                                    <div>
                                      <h6 className="mt-1 meeting-time">
                                        {/* Meeting closed at {meeting?.closedAtTime} on the {meeting?.closedAtDate} */}
                                      </h6>
                                    </div>
                                    {meeting?.isMinuteAdded === true ? (
                                      <div className="mt-3">
                                        <Link
                                          to={`/view-minute-detail/${meeting?._id}`}
                                          state={{
                                            meetingId: props.meetingId,
                                            isViewMeetingPage: true,
                                          }}
                                          style={{
                                            textDecoration: "none",
                                          }}
                                          className="view-minutes"
                                        >
                                          View Minutes
                                        </Link>
                                      </div>
                                    ) : (
                                      <div className="mt-3">
                                        <Link
                                          to={`/view-meeting-details/${meeting?._id}`}
                                          state={{
                                            meetingId: props.meetingId,
                                            isViewMeetingPage: true,
                                          }}
                                          style={{
                                            textDecoration: "none",
                                          }}
                                          className="view-minutes"
                                        >
                                          View Meeting
                                        </Link>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              );
                            }
                          )}
                        <div className="meeting-3">
                          <div className="meeting-count2">
                            <div className="meeting-circle-add pointer">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="18"
                                height="18"
                                fill="#fff"
                                className="bi bi-plus-lg"
                                viewBox="0 0 16 16"
                              >
                                <path
                                  fill-rule="evenodd"
                                  d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2"
                                />
                              </svg>
                            </div>
                          </div>
                          <div className="meeting-1-details">
                            <Link
                              to="/create-meeting"
                              state={{
                                isNewMeeting: true,
                                parentMeetingData:
                                  meetingData?.timeLineDatas
                                    ?.parentMeetingDetails,
                              }}
                              style={{ textDecoration: "none" }}
                            >
                              {" "}
                              <button
                                className="create-follow"
                                onClick={() => {
                                  dispatch(setCreateNewMeetingPage(true));
                                }}
                              >
                                {" "}
                                Create Follow On{" "}
                              </button>
                            </Link>
                          </div>
                        </div>
                      </div>
                    )}
                  </form>
                </div>
              </div>
            </div>
          </section>
        </div>
      ) : meetingData?.timeLineDatas?.length === 0 ? (
        <div className="main-content">
          <div className="row">
            <NoDataFound />
          </div>
        </div>
      ) : (
        <div>
          <Loader />
        </div>
      )}
    </>
  );
};

export default TimelinePage;
