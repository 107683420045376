import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, Navigate, Link } from "react-router-dom";
import Badge from "react-bootstrap/Badge";
import {
  generateMOM,
  getAgendaWithMinutesDetails,
  getSingleMeetingDetails,
  unSetSingleMeetingDetails,
} from "../../redux/actions/meetingActions/MeetingAction";
import Header from "../Common/Header/Header";
import MeetingHeader from "../Common/Header/MeetingHeader";
import Sidebar from "../Common/Sidebar/Sidebar";
import { ToastContainer, toast } from "react-toastify";
import {
  convertFirstLetterOfFullNameToCapital,
  customName,
  formatDateTimeFormat,
  getTimeSession,
} from "../../helpers/commonHelpers";
import NoDataFound from "../Common/NoDataFound";
import Loader from "../Common/Loader";
import Alert from "../Common/Alert";
import {
  acceptMOM,
  addAgendaDetails,
  createMinutes,
  processAmendmentRequest,
  rejectAcceptMinute,
  setSuccess,
  updateAmendmentRequest,
} from "../../redux/actions/minuteActions/MinuteAction";
import MinutesRsvpStatusModal from "./MinutesRsvpStatusModal";
import AttendeesModal from "../Meeting/AttendeesModal";
import LoaderButton from "../Common/LoaderButton";
import AmendmentRequestModal from "./AmendmentRequestModal";
import AmendmentRequestViewModal from "./AmendmentRequestViewModal";
import MeetingActivities from "../Meeting/MeetingActivities";
import EditAgendaModal from "./EditAgendaModal";
import DeleteAgendaModal from "./DeleteAgendaModal";
import EditMinuteModal from "./EditMinuteModal";
import DeleteMeetingModal from "./DeleteMeetingModal";
import MeetingAttendance from "../Meeting/MeetingAttendance";
import { Dropdown } from "bootstrap";
import ActionDropDown from "./ActionDropDown";
import AddAttendeesModal from "../Meeting/AddAttendeesModal";
import { logOut } from "../../redux/actions/authActions/authAction";
//import AddMinuteAgendaModal from "./AddMinuteAgendaModal";

const ViewMinutes = (props) => {
  console.log(props);
  const location = useLocation();
  const stateData = location.state;
  console.log(stateData);
  const accessToken = localStorage.getItem("accessToken");
  const userData = JSON.parse(localStorage.getItem("userData"));

  const authData = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const employeeData = useSelector((state) => state.user);
  const meetingRoomData = useSelector((state) => state.meetingRoom);
  const minuteData = useSelector((state) => state.minute);
  const meetingData = useSelector((state) => state.meeting);
  const [isMinutesRsvpStatusModal, setIsMinutesRsvpStatusModal] =
    useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [attendeesData, setAttendeesData] = useState([]);
  const [isAttendace, setIsAttendace] = useState(false);
  const [isAttendaceModalOpen, setIsAttendaceModalOpen] = useState(false);
  const [isAccepted, setIsAccepted] = useState(false);
  const [isDeleteMinuteModal, setIsDeleteMinuteModal] = useState(false);
  const [isEditMinuteModal, setIsEditMinuteModal] = useState(false);
  const [minuteDetails, setMinuteDetails] = useState(null);
  const [agendaData, setAgendaData] = useState(null);
  const [amendmentModal, setAmendmentModal] = useState(false);
  const [createdById, setCreatedById] = useState(null);
  const [details, setDetails] = useState(null);
  const [amendmentViewModal, setAmendmentViewModal] = useState(false);
  const [isDeleteAgendaModal, setIsDeleteAgendaModal] = useState(false);
  const [isAddAttendeeModal, setIsAddAttendeeModal] = useState(false);

  const [status, setStatus] = useState(null);
  const [minuteId, setMinuteId] = useState(null);
  console.log(meetingData);
  console.log("use effect------------------------------------");
  useEffect(() => {
    console.log("use effect------------------------------------");
    console.log(stateData);

    // dispatch(getSingleMeetingDetails(stateData.meetingId, accessToken));
    // dispatch()
    return () => {
      console.log("return useeffect--------------->>>>>>>>>>>>>>");
      dispatch(unSetSingleMeetingDetails);
    };
  }, []);
  console.log(employeeData, props.meetingId);

  const isAttendee = meetingData?.meetingDetails?.attendees?.find(
    (item) => item?._id?.toString() === employeeData?.userData?._id?.toString()
  );
  console.log("isAttendee----------------", isAttendee);
  const canWriteMOM =
    props.inWriteMinute === true
      ? meetingData?.meetingDetails?.canWriteMOM
      : true;
  const momDetailsCondition =
    props.inWriteMinute === true
      ? true
      : meetingData?.meetingDetails?.momGenerationDetails?.length !== 0;
  console.log("momDetailsCondition", momDetailsCondition);
  console.log(
    employeeData?.userData?.isAdmin,
    employeeData?.userData?.isMeetingOrganiser
  );
  let isRoleAllowed = false;
  // if (meetingData?.meetingDetails && employeeData?.userData) {
  //   if (
  //     employeeData?.userData?.isAdmin === true
  //   ) {
  //     console.log("allowed1");
  //     isRoleAllowed = true;

  //     // alert("inside if")
  //   } else {
  //     // alert("inside else")
  //     console.log("blocked1");
  //     if (employeeData?.userData?.isMeetingOrganiser === true) {
  //       console.log("allowed2");
  //       isRoleAllowed = true;
  //     } else {
  //       console.log("blocked2");
  //       if (isAttendee) {
  //         console.log("allowed3");
  //         isRoleAllowed = true;
  //       } else {
  //         isRoleAllowed = false;
  //         console.log("blocked4");
  //       }
  //     }

  //     console.log(
  //       isRoleAllowed,
  //       (meetingData?.meetingDetails?.meetingStatus?.status === "closed" || meetingData?.meetingDetails?.meetingStatus?.status === "scheduled" || meetingData?.meetingDetails?.meetingStatus?.status === "rescheduled"),
  //       canWriteMOM,
  //       momDetailsCondition
  //     );

  //     if (
  //       isRoleAllowed === true &&
  //       (meetingData?.meetingDetails?.meetingStatus?.status === "closed" || meetingData?.meetingDetails?.meetingStatus?.status === "scheduled" || meetingData?.meetingDetails?.meetingStatus?.status === "rescheduled")
  //          &&
  //       canWriteMOM &&
  //       momDetailsCondition
  //     ) {
  //       console.log("final allowed");
  //     } else {
  //       console.log("final blocked");
  //       if (accessToken) {
  //         localStorage.removeItem("accessToken");
  //         localStorage.removeItem("userData");
  //         localStorage.removeItem("rememberMe");
  //         dispatch(logOut());
  //         navigate("/login");
  //         toast.error("Sorry. You do not have permission for this page.", {
  //           position: "top-right",
  //           autoClose: 3000,
  //           hideProgressBar: false,
  //           closeOnClick: true,
  //           pauseOnHover: true,
  //           draggable: true,
  //           progress: undefined,
  //           theme: "colored",
  //           // transition: Bounce,
  //         });
  //        }
  //     }
  //   }
  // }

  const handleAmendmentRequest = (details) => {
    const payload = {
      details,
      meetingId: meetingData?.meetingDetails?._id,
    };
    console.log("ccccccccccc", payload, minuteId);
    dispatch(processAmendmentRequest(payload, minuteId, accessToken));
    setMinuteId(null);
    setAmendmentModal(false);
  };

  const handleAmendmentRequestModal = (minuteId) => {
    setMinuteId(minuteId);
    setAmendmentModal(true);
  };

  const handleAmendmentUpdate = (status) => {
    const payload = {
      createdById,
      status,
      meetingId: meetingData.meetingDetails._id,
    };
    console.log("ccccccccccc", payload, minuteId);
    dispatch(updateAmendmentRequest(payload, minuteId, accessToken));
    setMinuteId(null);
    setDetails(null);
    setCreatedById(null);
    setAmendmentViewModal(false);
  };

  const handleAmendmentUpdateByButton = (status, createdById, minuteId) => {
    const payload = {
      createdById,
      status,
      meetingId: meetingData.meetingDetails._id,
    };
    console.log("ccccccccccc", payload, minuteId);
    dispatch(updateAmendmentRequest(payload, minuteId, accessToken));
    setMinuteId(null);
    setDetails(null);
    setCreatedById(null);
    setAmendmentViewModal(false);
  };

  const handleAmendmentUpdateModal = (minuteId, details, createdById) => {
    setMinuteId(minuteId);
    setDetails(details);
    setCreatedById(createdById);
    setAmendmentViewModal(true);
  };

  const submitAgendaDetails = () => {
    console.log(minuteData);
    // if (minuteData.finalAgendaMinutesData.length!==0) {
    //   // const payload = {
    //   //   meetingId:meetingData?.meetingDetails?._id,
    //   //   agendaData:{
    //   //     topic: formData.topic,
    //   //     title: formData.title,
    //   //     timeLine: formData.timeLine.toString(),
    //   //   },
    //   //   minutes:meetingData.finalAgendaMinutesData,
    //   //   // sendNotification: false,
    //   // //  agendas: newAgendaData,
    //   //   organizationId: userData.organizationId
    //   // };
    //   // console.log(payload);
    //   dispatch(
    //     addAgendaDetails(
    //       {
    //         agendaData:minuteData.finalAgendaMinutesData.map(
    //              ({ uid, ...keepAttrs }) =>
    //                keepAttrs
    //            ),
    //         // organizationId: userData.organizationId,

    //         // minutes: minuteData.finalAgendaMinutesData.map(
    //         //   ({ uid, attendyType, responsiblePersonId, ...keepAttrs }) =>
    //         //     keepAttrs
    //         // ),
    //       },
    //       meetingData.meetingDetails._id,
    //       accessToken
    //     )
    //   );
    // } else {
    dispatch(
      createMinutes(
        {
          meetingId: meetingData.meetingDetails._id,
          minutes: minuteData.finalMinutesData.map(
            ({ uid, attendyType, responsiblePersonId, ...keepAttrs }) =>
              keepAttrs
          ),
        },
        accessToken
      )
    );
  };
  console.log(meetingData.meetingDetails);

  const updateIsMinutesRsvpStatusModal = (status, attendees) => {
    console.log(status, attendees);
    if (attendees.length !== 0) {
      setStatus(status);
      setAttendeesData(attendees);
      setIsMinutesRsvpStatusModal(!isMinutesRsvpStatusModal);
    }
  };
  console.log(isMinutesRsvpStatusModal);
  const setModalStatus = (value, attendeesData) => {
    if (attendeesData.length !== 0) {
      setIsModalOpen(value);
      setAttendeesData([...attendeesData]);
      // setIsUser(isUser)
    }
  };

  const setAttendanceModalStatus = (value, attendeesData) => {
    if (attendeesData.length !== 0) {
      setIsAttendaceModalOpen(value);
      setIsModalOpen(value);
      setAttendeesData([...attendeesData]);
      setIsAttendace(true);
      // setIsUser(isUser)
    }
  };

  const setAddAttendeesModal = (status, attendeesData) => {
    console.log(attendeesData);
    // dispatch(setSuccess(false));
    // setMinuteDetails(minute);
    // setIsDeleteMinuteModal(status);
    setAttendeesData([...attendeesData]);
    setIsAddAttendeeModal(status);
  };

  const setIsAgendaShowModal = (value) => {
    setIsAgendaShowModal(value);
  };

  // const setEditAgendaModalStatus = (status, agenda) => {
  //   console.log(agenda);
  //   dispatch(setSuccess(false));
  //   setAgendaData(agenda);
  //   setIsEditAgendaModal(status);
  // };

  const setDeleteAgendaModalStatus = (status, agenda) => {
    console.log(agenda, status);
    dispatch(setSuccess(false));
    setAgendaData(agenda);
    setIsDeleteAgendaModal(status);
  };

  const handleEditMinuteModal = (status, minute) => {
    console.log(minute);
    dispatch(setSuccess(false));
    setMinuteDetails(minute);
    setIsEditMinuteModal(status);
  };
  const handleDeleteMinuteModal = (status, minute) => {
    console.log(minute);
    dispatch(setSuccess(false));
    setMinuteDetails(minute);
    setIsDeleteMinuteModal(status);
  };
  const momId =
    meetingData?.meetingDetails?.momGenerationDetails?.length !== 0
      ? meetingData?.meetingDetails?.momGenerationDetails[
          meetingData?.meetingDetails?.momGenerationDetails?.length - 1
        ]?.momId
      : null;
  const acceptMOMData = (meetingId) => {
    if (isAccepted) {
      dispatch(acceptMOM(meetingId, { momId }, accessToken));
    } else {
      toast.warning("Please accept MOM condition.", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        // transition: Bounce,
      });
    }
  };

  const generateMOMDetails = () => {
    const meetingId = props.meetingId
      ? props.meetingId
      : meetingData.meetingDetails?._id;
    const payload = {
      organizationId: userData.organizationId,
      attendees: meetingData?.meetingDetails?.attendees?.map(
        ({ rsvp, isAttended, isEmployee, ...keepAttrs }) => keepAttrs
      ),
    };
    dispatch(generateMOM(meetingId, payload, accessToken));
  };
  const isMomAccepted =
    meetingData?.meetingDetails?.momAcceptDetails?.length > 0 &&
    meetingData?.meetingDetails?.momAcceptDetails?.find(
      (user) =>
        user?.userId?.toString() == employeeData?.userData?._id.toString()
    )
      ? true
      : false;
  console.log(isMomAccepted);
  console.log(employeeData?.userData?.isAdmin);
  return (
    <>
      <div className="save-meet-head">
        <div className="meeting-header-text">
          {props.meetingId ? <h4>MOM Details</h4> : <h4>MOM Preview</h4>}
        </div>
        {!props.meetingId &&
        (employeeData?.userData?.isMeetingOrganiser === true ||
          employeeData?.userData?.isAdmin === true) ? (
          <div className="save-meet-btn">
            <div className="row">
              <div className="col">
                {meetingData?.meetingDetails?.isMinutesAdded ? (
                  <div className="d-inline-block">
                    {meetingData?.loading ? (
                      <LoaderButton />
                    ) : (
                      <button className="Mom-btn" onClick={generateMOMDetails}>
                        <p>Save Minutes</p>
                      </button>
                    )}
                    {/* <div aria-labelledby="dropdownBasic1">
                  <button>Save Meeting</button>
                  <button>Save Meeting and Notification</button>
                </div> */}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        ) : null}
        {/* {!props.meetingId && meetingData?.meetingDetails?.momGenerationDetails?.length!==0 ? (
          <div className="save-meet-btn">
            <div className="row">
              <div className="col">
                <div className="d-inline-block">
                  <button className="Mom-btn" onClick={generateMOMDetails}>
                    <p>Download</p>
                  </button>
                 
                </div>
              </div>
            </div>
          </div>
        ) : null} */}
      </div>
      {meetingData.meetingDetails ? (
        <form className="mt-2 details-form details-form-right">
          <div className="form-group">
            <div className="row">
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                <label className="mb-1">Meeting ID</label>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                <p>{meetingData.meetingDetails?.meetingId}</p>
              </div>
            </div>
          </div>
          <div className="form-group">
            <div className="row">
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                <label className="mb-1">Title</label>
              </div>
              <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                <p>
                  {convertFirstLetterOfFullNameToCapital(
                    meetingData.meetingDetails?.title
                  )}
                </p>
              </div>
            </div>
          </div>

          <div className="form-group">
            <div className="row">
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                <label className="mb-1">Meeting Mode</label>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                <p>
                  {convertFirstLetterOfFullNameToCapital(
                    meetingData.meetingDetails?.mode
                  )}
                </p>
              </div>
            </div>
          </div>

          <div className="form-group ">
            <div className="row">
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                <label className="mb-1">Location</label>
              </div>
              <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                <p>
                  {meetingData?.meetingDetails?.locationDetails
                    .isMeetingRoom === true
                    ? meetingData?.meetingDetails?.roomDetail[0]?.title +
                      ", " +
                      meetingData?.meetingDetails?.roomDetail[0]?.location
                    : meetingData?.meetingDetails?.locationDetails?.location}
                </p>
              </div>
            </div>
          </div>

          <div className="form-group">
            <div className="row">
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                <label className="mb-1">Meeting Link</label>
              </div>
              <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                <p>
                  {meetingData.meetingDetails?.link
                    ? meetingData.meetingDetails?.link
                    : "NA"}
                </p>
              </div>
            </div>
          </div>

          <div className="form-group">
            <div className="row">
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                <label className="mb-1">Date & Time</label>
              </div>
              <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                <p>
                  {
                    formatDateTimeFormat(meetingData.meetingDetails?.date)
                      .formattedDate
                  }
                  ,{meetingData.meetingDetails?.fromTime} to{" "}
                  {meetingData.meetingDetails?.toTime}{" "}
                </p>
              </div>
            </div>
          </div>

          {meetingData.meetingDetails.attendees.length !== 0 ? (
            <>
              <div className="row align-items-center">
                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                  <label className="pb-1"> Attendee(s) </label>
                </div>
                <div
                  className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12"
                  onClick={(e) => {
                    setModalStatus(true, meetingData.meetingDetails?.attendees);
                  }}
                >
                  <div className="attendees">
                    {meetingData.meetingDetails?.attendees.map((attendee) => {
                      return (
                        // <div > {customName(attendee.name)}</div>
                        <>
                          {/* <div>{customName(attendee)}</div> */}
                          <div className="attendee-list">
                            {customName(attendee.name)}
                          </div>
                        </>
                      );
                    })}
                    {/* <p className="m-0">+5 More</p> */}
                    <p className="m-0">
                      {meetingData.meetingDetails?.attendees.length > 5
                        ? `+${
                            meetingData.meetingDetails?.attendees.length - 5
                          } More`
                        : null}
                    </p>
                    {/* <button
                      className="add-minutes Mom-btn mt-2"
                      type="button"
                      onClick={(e) => {
                        setAddAttendeesModal(
                          true,
                          meetingData.meetingDetails?.attendees
                        );
                      }}
                    >
                      <p>Add More</p>
                    </button> */}
                  </div>
                </div>
              </div>

              <div className="form-group">
                <div className="row">
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12"></div>
                  <div
                    className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12"
                    onClick={(e) => {
                      setModalStatus(
                        true,
                        meetingData.meetingDetails?.attendees
                      );
                    }}
                  >
                    <div className="attendees"></div>
                  </div>
                </div>
                {props.meetingId ||
                (employeeData?.userData?.isMeetingOrganiser === false &&
                  employeeData?.userData?.isAdmin === false) ? null : (
                  <>
                    <div className="d-flex btn-outerbox mt-3">
                      <button
                        className="add-minutes Mom-btn mt-2"
                        type="button"
                        onClick={(e) => {
                          setAddAttendeesModal(
                            true,
                            meetingData.meetingDetails?.attendees
                          );
                        }}
                      >
                        <p>Update New Attendees</p>
                      </button>

                      <button
                        className="add-minutes Mom-btn mt-2"
                        type="button"
                        onClick={(e) => {
                          setAttendanceModalStatus(
                            true,
                            meetingData.meetingDetails?.attendees
                          );
                        }}
                      >
                        <p>Update Attendance & Time</p>
                      </button>
                    </div>
                  </>
                )}
              </div>
            </>
          ) : null}
          <div className="minutes-border"></div>
          {meetingData?.agendasDetail?.length !== 0 ? (
            <div className="form-group agenda">
              <label className="mt-3 mb-3 add-agenda">
                <h4>Agenda(s)</h4>
                {/* <div>
                  <button
                    className="add-minutes Mom-btn"
                    type="button"
                    onClick={() => props.openAgendaForm()}
                  >
                    <p>Add Agenda</p>
                  </button>
                </div> */}
              </label>
              {meetingData?.agendaDetails?.length !== 0 &&
                meetingData?.agendaDetails?.map((agenda, index) => {
                  return (
                    <div className="mt-2 agenda-box-border">
                      <div className="form-group  m-0">
                        <div className="topic-head p-2 d-flex align-items-center justify-content-between">
                          <div>
                            <label> Agenda {index + 1}</label>
                          </div>
                          {/* <div className="btn-outerbox">
                            <button
                              type="button"
                              className="add-minutes Mom-btn"
                              onClick={(e) => {
                                setEditAgendaModalStatus(true, agenda);
                              }}
                            >
                              <p> Edit</p>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-pencil-square me-0 filter-svg"
                                viewBox="0 0 16 16"
                              >
                                <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                <path
                                  fill-rule="evenodd"
                                  d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"
                                />
                              </svg>
                            </button>
                            <button
                            type="button"
                            className="add-minutes Mom-btn "
                            onClick={(e) => {
                              setDeleteAgendaModalStatus(true, agenda);
                            }}
                          >
                            <p> Delete</p>

                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi-minus-circle bi bi-dash-circle me-0 filter-svg"
                              viewBox="0 0 16 16"
                            >
                              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                              <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8" />
                            </svg>
                          </button>
                          </div> */}
                        </div>
                      </div>

                      {/* <div className="col-12">
                            <label className="mt-1 p-3 topic-head">
                              {" "}
                              Agenda {index + 1}
                              <button
                                type="button"
                                className="btn rounded-pill add-btn Mom-btn d-flex align-items-center justify-content-center"
                                id="open-form-btn"
                                // onClick={(e) => setfilter(true)}
                                onClick={(e) => {
                                  setEditAgendaModalStatus(true, agenda);
                                }}
                              >
                                <p> Edit</p>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  className="bi bi-pencil-square me-0 filter-svg"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                  <path
                                    fill-rule="evenodd"
                                    d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"
                                  />
                                </svg>
                              </button>
                              <button
                              type="button"
                              className="btn rounded-pill add-btn Mom-btn d-flex align-items-center justify-content-center"
                              id="open-form-btn"
                              // onClick={(e) => setfilter(true)}
                              onClick={(e) => {
                                setDeleteAgendaModalStatus(true, agenda);
                              }}
                            >
                              <p> Delete</p>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-pencil-square me-0 filter-svg"
                                viewBox="0 0 16 16"
                              >
                                <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                <path
                                  fill-rule="evenodd"
                                  d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"
                                />
                              </svg>
                            </button>
                            </label>
                      
                          </div> */}
                      {/* </div> */}
                      {/* </div> */}

                      <div className="p-3">
                        <div className="form-group">
                          <div className="row">
                            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                              <label className="mt-1 mb-1">Agenda Title</label>
                            </div>
                            <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                              <p> {agenda.title}</p>
                            </div>
                          </div>
                        </div>
                        {agenda.topic !== "" ? (
                          <>
                            <div className="form-group">
                              <div className="row">
                                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                                  <label className="mt-2 topic">
                                    Topic to Discuss
                                  </label>
                                </div>
                                <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                                  <p className="mb-2">{agenda.topic} </p>
                                </div>
                              </div>
                            </div>
                          </>
                        ) : null}
                        <div className=" form-group">
                          <div className="row">
                            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                              <label className="mt-1 mb-1">Timeline</label>
                            </div>
                            <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                              <p> {agenda.timeLine} Min</p>
                            </div>
                          </div>
                        </div>

                        {agenda?.minutesDetail?.filter(
                          (item) => item.isActive === true
                        ).length !== 0 ? (
                          <>
                            <div></div>
                            <div className=" minutes-border"></div>

                            {agenda?.minutesDetail?.filter(
                              (item) => item.isActive === true
                            ).length !== 0 &&
                              agenda?.minutesDetail
                                ?.filter((item) => item.isActive === true)
                                .map((minute, index) => {
                                  return (
                                    <>
                                      <div className="agenda-minutes">
                                        <div className=" form-group ">
                                          <div className="row">
                                            <div className="col-md-4">
                                              <label className="mt-1 mb-1">
                                                Minutes {index + 1}
                                              </label>
                                            </div>
                                            <div className="col-md-6">
                                              <p> {minute.description}</p>
                                            </div>

                                            <div className="col-md-2 d-flex justify-content-end">
                                              <div className="btn-outerbox">
                                                {!props.meetingId &&
                                                (employeeData?.userData
                                                  ?.isMeetingOrganiser ===
                                                  true ||
                                                  employeeData?.userData
                                                    ?.isAdmin === true ||
                                                  meetingData?.meetingDetails
                                                    ?.canWriteMOM === true) ? (
                                                  <div className="d-inline-block menu-dropdown custom-dropdown">
                                                    <ActionDropDown
                                                      minuteId={minute._id}
                                                      //  status={meeting.meetingStatus.status}
                                                      handleRequestForEditModal={() => {
                                                        handleEditMinuteModal(
                                                          true,
                                                          minute
                                                        );
                                                      }}
                                                      handleRequestForDeleteModal={() => {
                                                        handleDeleteMinuteModal(
                                                          true,
                                                          minute
                                                        );
                                                      }}
                                                    />
                                                  </div>
                                                ) : null}
                                                {/* <p>
                                                <button type="button">
                                                  <Badge
                                                    bg="secondary"
                                                    onClick={() => {
                                                      handleEditMinuteModal(
                                                        true,
                                                        minute
                                                      );
                                                    }}
                                                  >
                                                    Edit
                                                    <svg
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      width="11"
                                                      height="11"
                                                      fill="currentColor"
                                                      className="bi bi-pencil-square me-0 filter-svg"
                                                      viewBox="0 0 16 16"
                                                    >
                                                      <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                                      <path
                                                        fill-rule="evenodd"
                                                        d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"
                                                      />
                                                    </svg>
                                                  </Badge>
                                                </button>
                                              </p>
                                              <p>
                                                <button type="button">
                                                  <Badge
                                                    bg="secondary"
                                                    onClick={() => {
                                                      handleDeleteMinuteModal(
                                                        true,
                                                        minute
                                                      );
                                                    }}
                                                  >
                                                    Delete
                                                    <svg
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      width="11"
                                                      height="11"
                                                      fill="currentColor"
                                                      className="bi-minus-circle bi bi-dash-circle me-0 filter-svg"
                                                      viewBox="0 0 16 16"
                                                    >
                                                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                                                      <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8" />
                                                    </svg>
                                                  </Badge>
                                                </button>
                                              </p> */}
                                              </div>
                                            </div>
                                          </div>
                                          <div className="row">
                                            <div className="col-md-4">
                                              <label className="mt-1 mb-1">
                                                Status
                                              </label>
                                            </div>
                                            <div className="col-md-6">
                                              <p>
                                                <Badge bg="info">
                                                  {minute.status}
                                                </Badge>
                                              </p>
                                            </div>
                                          </div>
                                        </div>

                                        {minute.isAction ? (
                                          <>
                                            <div className=" form-group">
                                              <div className="row">
                                                <div className="col-md-4">
                                                  <label className="mt-1 mb-1">
                                                    Due Date
                                                  </label>
                                                </div>
                                                <div className="col-md-8">
                                                  <p>
                                                    {" "}
                                                    {
                                                      formatDateTimeFormat(
                                                        minute?.dueDate
                                                      ).formattedDate
                                                    }
                                                  </p>
                                                </div>
                                              </div>
                                            </div>

                                            <div className=" form-group">
                                              <div className="row">
                                                <div className="col-md-4">
                                                  <label className="mt-1 mb-1">
                                                    Priority
                                                  </label>
                                                </div>
                                                <div className="col-md-8">
                                                  <p>
                                                    {" "}
                                                    {minute.priority
                                                      .charAt(0)
                                                      .toUpperCase() +
                                                      minute.priority
                                                        .slice(1)
                                                        .toLowerCase()}
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                            {minute?.isAction ? (
                                              <div className=" form-group">
                                                <div className="row">
                                                  <div className="col-md-4">
                                                    <label className="mt-1 mb-1">
                                                      Responsible Person
                                                    </label>
                                                  </div>
                                                  <div className="col-md-8">
                                                    <p>
                                                      {minute
                                                        ?.assignedUserDetails
                                                        ?.name
                                                        ? convertFirstLetterOfFullNameToCapital(
                                                            minute
                                                              ?.assignedUserDetails
                                                              ?.name
                                                          )
                                                        : null}
                                                    </p>
                                                  </div>
                                                </div>
                                              </div>
                                            ) : null}
                                          </>
                                        ) : null}

                                        {minute.reassignDetails ? (
                                          <div className=" form-group">
                                            <div className="row">
                                              <div className="col-md-4">
                                                <label className="mt-1 mb-1">
                                                  Reassigned To
                                                </label>
                                              </div>
                                              <div className="col-md-8 detail ">
                                                {/* <p routerLink="/action-detail">Rakesh Baral</p> */}
                                                <p>Rakesh Baral</p>
                                              </div>
                                            </div>
                                          </div>
                                        ) : null}

                                        {minute?.attendees?.length == 0 ? (
                                          <>
                                            <div className="form-group mt-4">
                                              <div className="row">
                                                <div className="col-md-4">
                                                  <label className="mt-2 topic">
                                                    Accepted By
                                                  </label>
                                                </div>
                                                <div
                                                  className="col-md-8"
                                                  onClick={() =>
                                                    updateIsMinutesRsvpStatusModal(
                                                      "Accepted",
                                                      minute?.attendees?.filter(
                                                        (attendee) =>
                                                          attendee.status ===
                                                          "ACCEPTED"
                                                      )
                                                    )
                                                  }
                                                >
                                                  {minute?.attendees?.filter(
                                                    (attendee) =>
                                                      attendee.status ===
                                                      "ACCEPTED"
                                                  ).length !== 0 &&
                                                    minute?.attendees
                                                      ?.filter(
                                                        (attendee) =>
                                                          attendee.status ===
                                                          "ACCEPTED"
                                                      )
                                                      .map((attendee) => {
                                                        return (
                                                          <>
                                                            <div className="attendees mb-2">
                                                              <div className="attendee-list">
                                                                {customName(
                                                                  attendee.name
                                                                )}
                                                              </div>
                                                              {}
                                                              {/* <p className="plus-more-text m-0">
                                                        +1 More
                                                      </p> */}
                                                              <p className="plus-more-text m-0">
                                                                {minute
                                                                  .attendees
                                                                  .length > 5
                                                                  ? `+${
                                                                      minute
                                                                        .attendees
                                                                        .length -
                                                                      5
                                                                    } More`
                                                                  : null}
                                                              </p>
                                                            </div>
                                                          </>
                                                        );
                                                      })}

                                                  <p>
                                                    Accepted by{" "}
                                                    {
                                                      minute?.attendees?.filter(
                                                        (attendee) =>
                                                          attendee.status ===
                                                          "ACCEPTED"
                                                      ).length
                                                    }
                                                    /{minute.attendees.length}{" "}
                                                    attendants
                                                  </p>
                                                </div>
                                              </div>
                                            </div>

                                            <div className="form-group mt-4">
                                              <div className="row">
                                                <div className="col-md-4">
                                                  <label className="mt-2 topic">
                                                    Rejected By
                                                  </label>
                                                </div>
                                                <div
                                                  className="col-md-8"
                                                  onClick={() =>
                                                    updateIsMinutesRsvpStatusModal(
                                                      "Rejected",
                                                      minute?.attendees?.filter(
                                                        (attendee) =>
                                                          attendee.status ===
                                                          "REJECTED"
                                                      )
                                                    )
                                                  }
                                                >
                                                  {minute?.attendees?.filter(
                                                    (attendee) =>
                                                      attendee.status ===
                                                      "REJECTED"
                                                  ).length !== 0 &&
                                                    minute?.attendees
                                                      ?.filter(
                                                        (attendee) =>
                                                          attendee.status ===
                                                          "REJECTED"
                                                      )
                                                      .map((attendee) => {
                                                        return (
                                                          <>
                                                            <div className="attendees mb-2">
                                                              <div className="attendee-list">
                                                                {customName(
                                                                  attendee.name
                                                                )}
                                                              </div>
                                                              {}
                                                              {/* <p className="plus-more-text m-0">
                                                        +1 More
                                                      </p> */}
                                                              <p className="plus-more-text m-0">
                                                                {minute
                                                                  .attendees
                                                                  .length > 5
                                                                  ? `+${
                                                                      minute
                                                                        .attendees
                                                                        .length -
                                                                      5
                                                                    } More`
                                                                  : null}
                                                              </p>
                                                            </div>
                                                          </>
                                                        );
                                                      })}

                                                  <p>
                                                    Rejected by{" "}
                                                    {
                                                      minute?.attendees?.filter(
                                                        (attendee) =>
                                                          attendee.status ===
                                                          "REJECTED"
                                                      ).length
                                                    }
                                                    /{minute.attendees.length}{" "}
                                                    attendants
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                          </>
                                        ) : null}
                                        {!employeeData.userData
                                          .isMeetingOrganiser &&
                                        props.meetingId ? (
                                          <div>
                                            <>
                                              {/* {minute?.acceptRejectStatus ===
                                              "PENDING" ||
                                            minute?.acceptRejectStatus ===
                                              null ? (
                                              <div>
                                                {minuteData.loading &&
                                                minuteData.status ===
                                                  "ACCEPTED" ? (
                                                  <LoaderButton />
                                                ) : (
                                                  <button
                                                    className="add-minutes Mom-btn me-3"
                                                    onClick={() =>
                                                      dispatch(
                                                        rejectAcceptMinute(
                                                          {
                                                            status: "ACCEPTED",
                                                            meetingId:
                                                              minute.meetingId,
                                                          },
                                                          minute._id,
                                                          accessToken
                                                        )
                                                      )
                                                    }
                                                  >
                                                    <p>Accept</p>
                                                  </button>
                                                )}
                                              </div>
                                            ) : null} */}

                                              {/* <button
                                            className="add-minutes Mom-btn me-3"
                                            onClick={() => {
                                              setAmendmentModal(true);
                                            }} */}

                                              <button
                                                className="add-minutes Mom-btn me-3"
                                                type="button"
                                                // onClick={() => props.openAgendaForm()}
                                                onClick={() =>
                                                  handleAmendmentRequestModal(
                                                    minute._id
                                                  )
                                                }
                                              >
                                                <p>Request for Amendment</p>
                                              </button>

                                              {/* {minute?.acceptRejectStatus ===
                                              "PENDING" ||
                                            minute?.acceptRejectStatus ===
                                              null ? (
                                              <div>
                                                {minuteData.loading &&
                                                minuteData.status ===
                                                  "REJECTED" ? (
                                                  <LoaderButton />
                                                ) : (
                                                  <button
                                                    className=" reset"
                                                    onClick={() =>
                                                      dispatch(
                                                        rejectAcceptMinute(
                                                          {
                                                            status: "REJECTED",
                                                            meetingId:
                                                              minute.meetingId,
                                                          },
                                                          minute._id,
                                                          accessToken
                                                        )
                                                      )
                                                    }
                                                  >
                                                    <p>Reject</p>
                                                  </button>
                                                )}
                                              </div>
                                            ) : null} */}
                                            </>
                                            <div className=" minutes-border"></div>
                                          </div>
                                        ) : null}

                                        {employeeData?.userData
                                          ?.isMeetingOrganiser &&
                                        minute?.amendmentDetails?.length !==
                                          0 ? (
                                          <div className="added-by">
                                            {minute?.amendmentDetails?.map(
                                              (amendment) => {
                                                return (
                                                  <>
                                                    <div className="add-agenda">
                                                      <p className="add-text">
                                                        Amendment Requested By
                                                      </p>
                                                      <div className="attendee1 attendee-list">
                                                        {customName(
                                                          amendment.name
                                                        )}
                                                      </div>
                                                    </div>
                                                    <div
                                                      className="view"
                                                      onClick={() => {
                                                        handleAmendmentUpdateModal(
                                                          minute._id,
                                                          amendment.details,
                                                          amendment.createdById
                                                        );
                                                      }}
                                                    >
                                                      <p>View Details</p>
                                                    </div>

                                                    <div className="add-agenda">
                                                      <div className="pe-2">
                                                        {/* <button className="add-minutes Mom-btn" 
                                                  type="button" 
                                                  onClick={()=> handleAmendmentUpdateByButton("ACCEPTED",amendment.createdById,minute._id) }>
                                                    <p>Accept</p>
                                                  </button> */}

                                                        {minuteData.loading &&
                                                        minuteData.status ===
                                                          "REJECTED" ? (
                                                          <LoaderButton />
                                                        ) : (
                                                          <button
                                                            className="add-minutes Mom-btn"
                                                            type="button"
                                                            onClick={() =>
                                                              handleAmendmentUpdateByButton(
                                                                "ACCEPTED",
                                                                amendment.createdById,
                                                                minute._id
                                                              )
                                                            }
                                                          >
                                                            <p>Accept</p>
                                                          </button>
                                                        )}
                                                      </div>

                                                      <div>
                                                        {minuteData.loading &&
                                                        minuteData.status ===
                                                          "REJECTED" ? (
                                                          <LoaderButton />
                                                        ) : (
                                                          <button
                                                            className=" reset"
                                                            type="button"
                                                            onClick={() =>
                                                              handleAmendmentUpdateByButton(
                                                                "REJECTED",
                                                                amendment.createdById,
                                                                minute._id
                                                              )
                                                            }
                                                          >
                                                            <p>Reject</p>
                                                          </button>
                                                        )}

                                                        {/* <button className=" reset"
                                                    type="button" 
                                                    onClick={()=> handleAmendmentUpdateByButton("REJECTED",amendment.createdById,minute._id) }
                                                  >
                                                    <p>Reject</p>
                                                  </button> */}
                                                      </div>
                                                    </div>
                                                  </>
                                                );
                                              }
                                            )}
                                          </div>
                                        ) : null}
                                      </div>
                                      <div className=" minutes-border"></div>
                                    </>
                                  );
                                })}
                          </>
                        ) : null}
                      </div>
                    </div>
                  );
                })}
              {employeeData?.userData?.isAdmin === true ? null : (
                <>
                  {employeeData?.userData?.isMeetingOrganiser === false &&
                  props.meetingId &&
                  isMomAccepted === false ? (
                    <div className="mom-acceptance">
                      <div className="meeting-organiser d-flex align-items-center">
                        <input
                          type="checkbox"
                          name="isAccepted"
                          checked={isAccepted}
                          onChange={() => {
                            setIsAccepted(!isAccepted);
                          }}
                        />
                        <span>
                          By acknowledging this Minutes of Meeting (MOM), you
                          confirm the accuracy and completeness of the
                          discussions and agreements recorded herein.{" "}
                        </span>
                      </div>
                      <button
                        className="add-minutes Mom-btn mt-2"
                        type="button"
                        onClick={(e) => {
                          acceptMOMData(meetingData.meetingDetails._id);
                        }}
                      >
                        <p>I Accept the MOM</p>
                      </button>
                    </div>
                  ) : employeeData?.userData?.isMeetingOrganiser === false &&
                    props.meetingId &&
                    isMomAccepted ? (
                    <div className="mom-acceptance meeting-organiser d-flex align-items-center">
                      <button
                        className="add-minutes Mom-btn mt-2"
                        type="button"
                      >
                        <p>MOM Accepted</p>
                      </button>
                    </div>
                  ) : null}
                </>
              )}
            </div>
          ) : null}
        </form>
      ) : null}
      {props.meetingId ? null : (
        <>
          <form className="mt-4 details-form details-form-right">
            <MeetingAttendance />
          </form>
          <form className="mt-4 details-form details-form-right">
            <MeetingActivities />
          </form>
        </>
      )}
      <AmendmentRequestViewModal
        handleSubmit={handleAmendmentUpdate}
        details={details}
        setAmendmentViewModal={setAmendmentViewModal}
        amendmentViewModal={amendmentViewModal}
      />

      <AmendmentRequestModal
        amendmentModal={amendmentModal}
        setAmendmentModal={setAmendmentModal}
        handleSubmit={handleAmendmentRequest}
      />
      <AttendeesModal
        IsModalOpen={isModalOpen}
        attendees={attendeesData}
        setIsModalOpen={setIsModalOpen}
        loginUserData={employeeData}
        isShow={false}
        isAttendace={isAttendace}
        meetingData={meetingData?.meetingDetails}
      />
      <AddAttendeesModal
        isAddAttendeeModal={isAddAttendeeModal}
        attendees={attendeesData}
        setIsAddAttendeeModal={setIsAddAttendeeModal}
        // loginUserData={employeeData}
        // isShow={false}
        // isAttendace={isAttendace}
        meetingData={meetingData?.meetingDetails}
      />
      <DeleteMeetingModal
        meetingId={minuteDetails?.meetingId}
        minuteId={minuteDetails?._id}
        IsDeleteMinuteModal={isDeleteMinuteModal}
        setIsDeleteMinuteModal={setIsDeleteMinuteModal}
        accessToken={accessToken}
      />
      <EditMinuteModal
        IsEditMinuteModal={isEditMinuteModal}
        minuteData={minuteDetails}
        setIsEditMinuteModal={setIsEditMinuteModal}
        // meetingData={meetingData}
      />

      {/* <EditAgendaModal
        IsEditAgendaModal={isEditAgendaModal}
        agendaData={agendaData}
        setIsEditAgendaModal={setIsEditAgendaModal}
        meetingData={meetingData}
      />
      <DeleteAgendaModal
        setIsDeleteAgendaModal={setIsDeleteAgendaModal}
        isDeleteAgendaModal={isDeleteAgendaModal}
        agendaData={agendaData}
      /> */}

      <MinutesRsvpStatusModal
        IsMinutesRsvpStatusModal={isMinutesRsvpStatusModal}
        attendees={attendeesData}
        setIsMinutesRsvpStatusModal={setIsMinutesRsvpStatusModal}
        status={status}
        //  loginUserData={loginUserData}
        //  rsvpCount={rsvpCount}
        // attendeeData={meeting.attendees}
      />
      {/* <AddMinuteAgendaModal isAgendaShow={isAgendaShow} setIsAgendaShow={setIsAgendaShow}/> */}
    </>
  );
};

export default ViewMinutes;
