import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { customName } from "../../helpers/commonHelpers";
import * as constantMessages from "../../constants/constatntMessages";
import { useSelector, useDispatch } from "react-redux";
import {
  getAgendaWithMinutesDetails,
  giveMomWritePermission,
  updateAttendanceAndTimeRanges,
} from "../../redux/actions/meetingActions/MeetingAction";

const AttendeesModal = (props) => {
  console.log(props);
  const dispatch = useDispatch();
  const employeeData = useSelector((state) => state.user);
  const meetingDetails = useSelector((state) => state.meeting);
  const accessToken = localStorage.getItem("accessToken");
  const { attendees, meetingData } = props;
  const [attendeesArray, setAttendeesArray] = useState([]);
  const [updateAttendance, setUpdateAttendance] = useState(null);
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    fromTime: "",
    toTime: "",
  });
  console.log(
    "out use effect------------------------------------------------------"
  );
  useEffect(() => {
    console.log(
      "inside use effect------------------------------------------------------"
    );
    setAttendeesArray(attendees);
    setFormData({
      ...formData,
      fromTime: meetingData?.actualFromTime,
      toTime: meetingData?.actualToTime,
      // fromTime:
      //   meetingData?.fromTime?.split(" ")[0].split(":")[0] < 10
      //     ? `0${meetingData?.fromTime?.split(" ")[0].split(":")[0]}:${
      //         meetingData?.fromTime?.split(" ")[0].split(":")[1]
      //       }`
      //     : meetingData?.fromTime?.split(" ")[0],
      // toTime:
      //   meetingData?.toTime?.split(" ")[0].split(":")[0] < 10
      //     ? `0${meetingData?.toTime?.split(" ")[0].split(":")[0]}:${
      //         meetingData?.toTime?.split(" ")[0].split(":")[1]
      //       }`
      //     : meetingData?.toTime?.split(" ")[0],
    });
    if (meetingDetails.isAttendanceStatus && accessToken) {
      dispatch(getAgendaWithMinutesDetails(meetingData._id, accessToken));
      props.setIsModalOpen(false);
    }
  }, [
    props.isAttendace,
    props.IsModalOpen,
    meetingDetails.isAttendanceStatus,
    meetingDetails.loading,
  ]);

  const checkHandler = (e, attendeeId) => {
    const { checked } = e.target;
    console.log(attendeeId, checked);
    if (attendeeId) {
      const modifiedAttendees = attendeesArray.map((obj) => {
        if (obj._id === attendeeId) {
          return { ...obj, isAttended: checked };
        }
        return obj;
      });

      console.log(modifiedAttendees);
      setAttendeesArray(modifiedAttendees);

      // dispatch(setFinalMinuteData(modifiedMinutes));
    }
    //  else {
    //   setFormData({
    //     ...formData,
    //     isAction: !formData.isAction,
    //   });
    // }
  };

  const checkMomWritePermissionHandler = (e, attendeeId) => {
    const { checked } = e.target;

    console.log(attendeeId, checked);
    if (attendeeId) {
      const modifiedAttendees = attendeesArray.map((obj) => {
        if (obj._id === attendeeId) {
          return { ...obj, canWriteMOM: checked };
        }
        return obj;
      });

      console.log(modifiedAttendees);
      setAttendeesArray(modifiedAttendees);
    }
  };

  const handleChange = (e) => {
    setErrors({});
    //  dispatch(updateOtpProcessed(false));
    //  console.log("9999999999999999999999999999999999999", authData);
    const { name, value } = e.target;
    console.log(name, value);
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const fromDateFieldValidationCheck = (e) => {
    const errors = {};
    if (!formData.fromTime.trim()) {
      errors.fromTime = constantMessages.timeRequired;
    } else if (formData.toTime.trim()) {
      console.log(formData);
      const fromTimeArray = formData.fromTime.split(":");
      const fromTimeHour = fromTimeArray[0];
      const fromTimeMinute = fromTimeArray[1];
      const toTimeArray = formData.toTime.split(":");
      const toTimeHour = toTimeArray[0];
      const toTimeMinute = toTimeArray[1];

      console.log(fromTimeHour);
      console.log(fromTimeMinute);
      console.log(toTimeHour);
      console.log(toTimeMinute);
      if (fromTimeHour > toTimeHour) {
        errors.fromTime = constantMessages.invalidFromHour;
      } else if (
        fromTimeHour === toTimeHour &&
        fromTimeMinute >= toTimeMinute
      ) {
        errors.fromTime = constantMessages.invalidFromMinute;
      }
    }
    setErrors(errors);
  };

  const toDateFieldValidationCheck = (e) => {
    const errors = {};
    if (!formData.toTime.trim()) {
      errors.toTime = constantMessages.timeRequired;
    } else if (formData.fromTime.trim()) {
      console.log(formData);
      const fromTimeArray = formData.fromTime.split(":");
      const fromTimeHour = fromTimeArray[0];
      const fromTimeMinute = fromTimeArray[1];
      const toTimeArray = formData.toTime.split(":");
      const toTimeHour = toTimeArray[0];
      const toTimeMinute = toTimeArray[1];
      console.log(fromTimeHour);
      console.log(fromTimeMinute);
      console.log(toTimeHour);
      console.log(toTimeMinute);
      if (fromTimeHour > toTimeHour) {
        errors.toTime = constantMessages.invalidToHour;
      } else if (
        fromTimeHour === toTimeHour &&
        fromTimeMinute >= toTimeMinute
      ) {
        errors.toTime = constantMessages.invalidToMinute;
      }
    }
    setErrors(errors);
  };

  const validateForm = (data) => {
    console.error(data);
    const errors = {};

    if (!data.fromTime.trim()) {
      errors.fromTime = constantMessages.timeRequired;
    } else if (formData.toTime.trim()) {
      console.log(formData);
      const fromTimeArray = formData.fromTime.split(":");
      const fromTimeHour = fromTimeArray[0];
      const fromTimeMinute = fromTimeArray[1];
      const toTimeArray = formData.toTime.split(":");
      const toTimeHour = toTimeArray[0];
      const toTimeMinute = toTimeArray[1];

      console.log(fromTimeHour);
      console.log(fromTimeMinute);
      console.log(toTimeHour);
      console.log(toTimeMinute);
      if (fromTimeHour > toTimeHour) {
        errors.fromTime = constantMessages.invalidFromHour;
      } else if (fromTimeHour === toTimeHour && fromTimeMinute > toTimeMinute) {
        errors.fromTime = constantMessages.invalidFromMinute;
      }
    }
    if (!data.toTime.trim()) {
      errors.toTime = constantMessages.timeRequired;
    } else if (formData.fromTime.trim()) {
      console.log(formData);
      const fromTimeArray = formData.fromTime.split(":");
      const fromTimeHour = fromTimeArray[0];
      const fromTimeMinute = fromTimeArray[1];
      const toTimeArray = formData.toTime.split(":");
      const toTimeHour = toTimeArray[0];
      const toTimeMinute = toTimeArray[1];
      console.log(fromTimeHour);
      console.log(fromTimeMinute);
      console.log(toTimeHour);
      console.log(toTimeMinute);
      if (fromTimeHour > toTimeHour) {
        errors.toTime = constantMessages.invalidToHour;
      } else if (
        fromTimeHour === toTimeHour &&
        fromTimeMinute >= toTimeMinute
      ) {
        errors.toTime = constantMessages.invalidToMinute;
      }
    }

    return errors;
  };

  const submitAttendance = () => {
    const newErrors = validateForm(formData);
    setErrors(newErrors);
    if (Object.keys(newErrors).length === 0) {
      const payload = {
        fromTime: formData.fromTime,
        toTime: formData.toTime,
        attendees: attendeesArray.map(
          ({ name, email, isEmployee, ...keepAttrs }) => keepAttrs
        ),
      };
      console.log("payload-------------", payload);
      dispatch(
        updateAttendanceAndTimeRanges(meetingData._id, payload, accessToken)
      );
    }
  };

  const submitMomWritePermission = () => {
    const payload = {
      attendees: attendeesArray.map(
        ({ name, email, isEmployee, ...keepAttrs }) => keepAttrs
      ),
    };
    dispatch(giveMomWritePermission(meetingData?._id, payload, accessToken));
  };
  console.log(attendees);
  console.log(attendeesArray);
  console.log( props.isAttendace);
  console.log( props.loginUserData?.userData)
  return (
    <>
      <Modal
        show={props.IsModalOpen}
        onHide={(e) => props.setIsModalOpen(false)}
      >
        <Modal.Dialog>
          {/* <Modal.Title> */}
          <div className="modal-header attendees-modal">
            {props.isAttendace ? (
              <div>
                <h4 className="modal-title">Update Attendance & Time</h4>
              </div>
            ) : (
              <div>
                <h4 className="modal-title">Attendees</h4>
              </div>
            )}

            <div>
              <button
                type="button"
                className="btn-close"
                aria-label="Close"
                onClick={(e) => props.setIsModalOpen(false)}
              ></button>
            </div>
          </div>

          <Modal.Body>
            {props.isAttendace ? (
              <>
                <div className="mb-3">
                  <div className="row">
                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-6">
                      <div className="position-relative resp-input-margin-bottom">
                        <label className="mb-1"> From Time</label>
                        <span className="star-mark">*</span>
                        <input
                          type="time"
                          className="input-time"
                          name="fromTime"
                          value={formData.fromTime}
                          onChange={handleChange}
                          onBlur={fromDateFieldValidationCheck}
                        />
                        {/* <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      fill="#fff"
                      className="bi bi-stopwatch-fill time"
                      viewBox="0 0 16 16"
                    >
                      <path d="M6.5 0a.5.5 0 0 0 0 1H7v1.07A7.001 7.001 0 0 0 8 16a7 7 0 0 0 5.29-11.584l.013-.012.354-.354.353.354a.5.5 0 1 0 .707-.707l-1.414-1.415a.5.5 0 1 0-.707.707l.354.354-.354.354-.012.012A6.97 6.97 0 0 0 9 2.071V1h.5a.5.5 0 0 0 0-1zm2 5.6V9a.5.5 0 0 1-.5.5H4.5a.5.5 0 0 1 0-1h3V5.6a.5.5 0 1 1 1 0" />
                    </svg> */}
                      </div>
                      {errors.fromTime && (
                        <span className="error-message">{errors.fromTime}</span>
                      )}
                    </div>

                    <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-6">
                      <div className="position-relative ">
                        <label className="mb-1">To Time</label>
                        <span className="star-mark">*</span>
                        <input
                          type="time"
                          className="input-time2"
                          name="toTime"
                          value={formData.toTime}
                          onChange={handleChange}
                          onBlur={toDateFieldValidationCheck}
                        />
                        {/* <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      fill="#fff"
                      className="bi bi-stopwatch-fill time2"
                      viewBox="0 0 16 16"
                    >
                      <path d="M6.5 0a.5.5 0 0 0 0 1H7v1.07A7.001 7.001 0 0 0 8 16a7 7 0 0 0 5.29-11.584l.013-.012.354-.354.353.354a.5.5 0 1 0 .707-.707l-1.414-1.415a.5.5 0 1 0-.707.707l.354.354-.354.354-.012.012A6.97 6.97 0 0 0 9 2.071V1h.5a.5.5 0 0 0 0-1zm2 5.6V9a.5.5 0 0 1-.5.5H4.5a.5.5 0 0 1 0-1h3V5.6a.5.5 0 1 1 1 0" />
                    </svg> */}
                      </div>
                      {errors.toTime && (
                        <span className="error-message">{errors.toTime}</span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="minutes-border"></div>
              </>
            ) : null}
            <div className="attendees-popup">
              {attendeesArray &&
                attendeesArray.map((attendee, index) => {
                  return (
                    <div className="attendee-content" key={index}>
                      {props.loginUserData?.userData?.isMeetingOrganiser ||
                      props.loginUserData?.userData?.isAdmin ? (
                        <div className="check-attendee">
                          {props.isShow &&
                          (meetingData?.meetingStatus?.status === "scheduled" ||
                            meetingData?.meetingStatus?.status ===
                              "rescheduled" ||
                            meetingData?.meetingStatus?.status === "draft") ? (
                            <input
                              type="checkbox"
                              name="updateAttendance"
                              id=""
                              className="form-check-input"
                              onChange={(e) =>
                                checkMomWritePermissionHandler(e, attendee._id)
                              }
                              //  checked={formData.isAction}
                              // onChange={(e) => checkHandler(e, minute.uid)}
                              // checked={minute.isAction}
                              disabled={
                                attendee?._id?.toString() === props?.meetingData?.createdById?.toString() ||
                                (attendee?._id?.toString() === employeeData.userData._id.toString() && employeeData.userData.isAdmin===true)||
                                (attendee?._id?.toString() === employeeData.userData._id.toString() && employeeData.userData.isMeetingOrganiser===true)
                                  ? true
                                  : false
                              }
                              checked={
                                // attendee._id.toString() ===
                                // employeeData.userData._id.toString()
                                attendee?._id?.toString() === props?.meetingData?.createdById?.toString() ||
                                (attendee?._id?.toString() === employeeData.userData._id.toString() &&employeeData.userData.isAdmin===true)||
                                (attendee?._id?.toString() === employeeData.userData._id.toString() && employeeData.userData.isMeetingOrganiser===true)
                                  ? true
                                  : attendee.canWriteMOM
                              }
                            />
                          ) : null}

                          {props.isAttendace ? (
                            <input
                              type="checkbox"
                              name="attendaceId"
                              id=""
                              className="form-check-input"
                              onChange={(e) => checkHandler(e, attendee._id)}
                              //  checked={formData.isAction}
                              // onChange={(e) => checkHandler(e, minute.uid)}
                              // checked={minute.isAction}
                              checked={attendee.isAttended}
                            />
                          ) : null}

                          <div className="attendee1 attendee-list sl">
                            {" "}
                            {customName(attendee.name)}
                          </div>
                        </div>
                      ) : (
                        <div className="attendee-content" key={index}>
                          <div className="attendee1 attendee-list sl">
                            {" "}
                            {customName(attendee.name)}
                          </div>
                        </div>
                      )}

                      <div className="action-cmnt-text">
                        <p className="detail-name">
                          {" "}
                          { attendee?._id?.toString() === props?.meetingData?.createdById?.toString() 
                            ? attendee.name + " (Organiser)"
                            :attendee?._id?.toString() === employeeData.userData._id.toString() && employeeData.userData.isAdmin===true ?
                            attendee.name + " (Admin)"
                            :attendee.name}
                        </p>
                        {/* body > ngb-modal-window > div > div > div.modal-body.attendees-popup > div:nth-child(1) > div.action-cmnt-text >  */}
                        <p className="name-undertext comment-text">
                          {attendee.email}
                        </p>

                        {props.loginUserData?.userData?.isMeetingOrganiser &&
                        props.isShow &&
                        (attendee._id.toString() ===
                        employeeData.userData._id.toString()
                          ? true
                          : attendee.canWriteMOM) &&
                        (meetingData?.meetingStatus?.status === "scheduled" ||
                          meetingData?.meetingStatus?.status ===
                            "rescheduled" ||
                          meetingData?.meetingStatus?.status === "draft") ? (
                          <span className="permission">
                            With MOM write permission
                          </span>
                        ) : null}
                      </div>
                    </div>
                  );
                })}
            </div>
          </Modal.Body>

          {/* <Button variant="primary"> 
           Save changes 
          </Button> 
          <Button variant="secondary"> 
           Close 
          </Button>  */}
          <div className="">
            { !props.isAttendace && (props.loginUserData?.userData?.isMeetingOrganiser || props.loginUserData?.userData?.isAdmin) &&
            (meetingData?.meetingStatus?.status === "scheduled" ||
              meetingData?.meetingStatus?.status === "rescheduled" ||
              meetingData?.meetingStatus?.status === "draft") ? (
              <Modal.Footer>
                <button
                  type="button"
                  className="Mom-btn btn btn-secondary bg-primary border-primary"
                  onClick={submitMomWritePermission}
                >
                  <p>Set MOM Write Permission</p>
                </button>
              </Modal.Footer>
            ) : props.isAttendace ? (
              <Modal.Footer>
                <button
                  type="button"
                  className="Mom-btn btn btn-secondary bg-primary border-primary"
                  onClick={submitAttendance}
                >
                  <p>Update</p>
                </button>
              </Modal.Footer>
            ) : null}
            {/* <button
                type="button"
                onClick={(e) => props.setIsModalOpen(false)}
                className="reset btn btn-secondary bg-white border-primary text-primary"
              >
                <p>Close</p>
              </button> */}
          </div>
        </Modal.Dialog>
      </Modal>
    </>
  );
};

export default AttendeesModal;
