import React, { useEffect, useState } from "react";
import Select from "react-select";

const EditDesignationDropdown = ({
  designations,
  selectedDesignationId,
  handleChange,
  value,
}) => {
  const [designation, setDesignation] = useState(value);
  useEffect(() => {
    setDesignation(value);
  }, []);
  const designationOptions = designations
    .filter((des) => des.isActive)
    .map((designation) => ({
      value: designation?._id,
      label: designation?.name,
    }));

  const handleDesignationChange = (selectedOption) => {
    console.log(selectedOption);
    setDesignation({
      name: "designationId",
      label: selectedOption ? selectedOption.label : "",
      value: selectedOption ? selectedOption.value : "",
    });
    handleChange({
      name: "designationId",
      label: selectedOption ? selectedOption.label : "",
      value: selectedOption ? selectedOption.value : "",
    });
  };
  return (
    <div className="form-group">
      <label className="mb-1">
        Designation<span className="star-mark"> *</span>
      </label>
      <Select
        name="designationId"
        value={designation}
        onChange={handleDesignationChange}
        options={designationOptions}
        placeholder="Select Designation"
      />
    </div>
  );
};
export default EditDesignationDropdown;
