import React from "react";
import Select from "react-select";

const DepartmentDropdown = ({
  departments,
  employeeData,
  handleChange,
  errors,
}) => {
  const departmentOptions = departments
    .filter((dep) => dep?.isActive === true)
    .map((department) => ({
      value: department?._id,
      label: department?.name,
    }));

  const handleDesignationChange = (selectedOption) => {
    handleChange({
      target: {
        name: "departmentId",
        value: selectedOption ? selectedOption.value : "",
      },
    });
  };

  return (
    <div className="col-md-4 col-sm-6">
      <div className="form-group">
        <label className="mb-1">
          Department<span className="star-mark"> *</span>
        </label>
        <Select
          name="departmentId"
          value={
            departmentOptions.find(
              (option) => option.value === employeeData?.departmentId
            ) || null
          }
          onChange={handleDesignationChange}
          options={departmentOptions}
          placeholder="Select Department"
        />
        {errors.departmentId && (
          <span className="error-message">{errors?.departmentId}</span>
        )}
      </div>
    </div>
  );
};

export default DepartmentDropdown;
