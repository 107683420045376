import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import AddMinute from "./AddMinute";
import ActionDropDown from "./ActionDropDown";
import Badge from "react-bootstrap/Badge";
import {
  convertFirstLetterOfFullNameToCapital,
  customName,
  formatDateTimeFormat,
} from "../../helpers/commonHelpers";
import LoaderButton from "../Common/LoaderButton";
import NoDataFound from "../Common/NoDataFound";
import Alert from "../Common/Alert";
import { setSuccess } from "../../redux/actions/minuteActions/MinuteAction";
import ActionModal from "../Action/ReopenActionModal";
import ReopenActionModal from "../Action/ReopenActionModal";
import {
  getAgendaWithMinutesDetails,
  processCloseMeeting,
  setParentAgendaWithMinutesDetails,
  viewParentMeeting,
} from "../../redux/actions/meetingActions/MeetingAction";
import CommonModal from "../Common/CommonModal";
import { cancelAction } from "../../redux/actions/actionAction/ActionAction";

const ViewParentActions = (props) => {
  const dispatch = useDispatch();
  const accessToken = localStorage.getItem("accessToken");
  const [isReopenMinuteModal, setIsReopenMinuteModal] = useState(false);
  const [isCloseModalOpen, setIsCloseModalOpen] = useState(false);
  const [minuteId, setMinuteId] = useState(null);

  const [minuteDetails, setMinuteDetails] = useState(null);
  const employeeData = useSelector((state) => state.user);
  const meetingData = useSelector((state) => state.meeting);
  const minuteData = useSelector((state) => state.minute);
  const actionData = useSelector((state) => state.action);
  console.log(props);
  console.log(actionData?.isSuccess);
  useEffect(() => {
    console.log("inside use effect")
    if (props.parentMeetingId) {
      dispatch(viewParentMeeting(props.parentMeetingId, accessToken));
    } else {
      dispatch(setParentAgendaWithMinutesDetails(null));
    }
    if (actionData?.isSuccess === true) {
      setIsCloseModalOpen(false);
    }

    return () => {
      console.log("call--------------->>>>>>>>>>>>>>");
      setMinuteDetails(null);
    };
  }, [props.parentMeetingId, actionData.isSuccess]);

  // const handleEditMinuteModal = (status, minute) => {
  //     console.log(minute);
  //     dispatch(setSuccess(false));
  //     setMinuteDetails(minute);
  //     setIsEditMinuteModal(status);
  //   };
  // const isActionAvailable= meetingData?.agendaDetails?.filter((agenda)=>agenda?.minutesDetail?.filter(
  //   (minute) => minute.isAction === true
  // ))
  const isActionAvailable = meetingData?.parentAgendaDetails?.filter(
    (agenda) => {
      console.log(agenda);
      console.log(agenda.minutesDetail);
      if (agenda.minutesDetail) {
        const abc = agenda.minutesDetail.filter(
          (minute) => minute.isAction === true
        );
        console.log(abc);
        if (abc.length !== 0) {
          return agenda;
        }
      }
    }
  );

  const handleReopenActionModal = (status, minute) => {
    console.log(minute);
    minute["modalStatus"] = "REOPEN";
    dispatch(setSuccess(false));
    setMinuteDetails(minute);
    setIsReopenMinuteModal(status);
  };

  const handleApproveActionModal = (status, minute) => {
    console.log(minute);
    minute["modalStatus"] = "APPROVE";
    dispatch(setSuccess(false));
    setMinuteDetails(minute);
    setIsReopenMinuteModal(status);
  };
  // const handleDeleteMinuteModal = (status, minute) => {
  //   console.log(minute);
  //   dispatch(setSuccess(false));
  //   setMinuteDetails(minute);
  //   setIsDeleteMinuteModal(status);
  // };
  const handleCancelAction = (status, minute) => {
    console.log("ccccccccccc", minute);
    // dispatch(
    //   cancelAction( props.parentMeetingId,minute?._id,accessToken)
    // );
    //   dispatch(processCloseMeeting(minuteId, {isCancelled:true,isComplete:false,isApprove:false,isReopened:false},accessToken));
   // setMinuteId(null);
    setMinuteDetails(minute);
    setIsCloseModalOpen(status);
  };

  const submitCancelAction = () => {
    const payload = {
     // remark: reason,
      assignedUserDetails: minuteDetails?.assignedUserDetails,
    };
    dispatch(
      cancelAction(props.parentMeetingId, minuteDetails?._id, accessToken,payload)
    );
  };
  console.log(meetingData.status);
  
  return (
    <>
      {meetingData?.parentAgendaDetails?.length !== 0 ? (
        <form className="mt-2 details-form details-form-right">
          <div className="form-group agenda">
            <label className="mt-3 mb-3 add-agenda">
              <h4>Last Meeting Action Points Status</h4>
              {/* <div>
                  <button
                    className="add-minutes Mom-btn"
                    type="button"
                    onClick={() => props.openAgendaForm()}
                  >
                    <p>Add Agenda</p>
                  </button>
                </div> */}
            </label>
            {isActionAvailable?.length !== 0 ? (
              <>
                {isActionAvailable.map((agenda, index) => {
                  return (
                    <>
                      {agenda?.minutesDetail?.length !== 0 &&
                      agenda?.minutesDetail?.filter(
                        (minute) => minute.isAction === true
                      ).length !== 0 ? (
                        <div className="mt-2 agenda-box-border">
                          <div className="form-group  m-0">
                            <div className="topic-head p-2 d-flex align-items-center justify-content-between">
                              <div>
                                <label> Agenda {index + 1}</label>
                              </div>
                            </div>
                          </div>
                          <div className="p-3">
                            <div className="form-group">
                              <div className="row">
                                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                                  <label className="mt-1 mb-1">
                                    Agenda Title
                                  </label>
                                </div>
                                <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                                  <p> {agenda.title}</p>
                                </div>
                              </div>
                            </div>
                            {agenda.topic !== "" ? (
                              <>
                                <div className="form-group">
                                  <div className="row">
                                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                                      <label className="mt-2 topic">
                                        Topic to Discuss
                                      </label>
                                    </div>
                                    <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                                      <p className="mb-2">{agenda.topic} </p>
                                    </div>
                                  </div>
                                </div>
                              </>
                            ) : null}
                            <div className=" form-group">
                              <div className="row">
                                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                                  <label className="mt-1 mb-1">Timeline</label>
                                </div>
                                <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                                  <p> {agenda.timeLine} Min</p>
                                </div>
                              </div>
                            </div>

                            {agenda?.minutesDetail?.filter(
                              (item) => item.isActive === true
                            ).length !== 0 ? (
                              <>
                                <div></div>
                                <div className=" minutes-border"></div>
                                {/* users.filter(obj => obj.name == filter.name && obj.address == filter.address) */}
                                {agenda?.minutesDetail?.filter(
                                  (item) =>
                                    item.isActive === true &&
                                    item.isAction === true
                                ).length !== 0 &&
                                  agenda?.minutesDetail
                                    ?.filter(
                                      (item) =>
                                        item.isActive === true &&
                                        item.isAction === true
                                    )
                                    .map((minute, index) => {
                                      function updateIsMinutesRsvpStatusModal(
                                        arg0,
                                        arg1
                                      ) {
                                        throw new Error(
                                          "Function not implemented."
                                        );
                                      }

                                      function handleAmendmentRequestModal(
                                        _id
                                      ) {
                                        throw new Error(
                                          "Function not implemented."
                                        );
                                      }

                                      return (
                                        <>
                                          <div className="agenda-minutes">
                                            <div className=" form-group ">
                                              <div className="row">
                                                <div className="col-md-4">
                                                  <label className="mt-1 mb-1">
                                                    Minutes {index + 1}
                                                  </label>
                                                </div>
                                                <div className="col-md-6">
                                                  <p> {minute.description}</p>
                                                </div>

                                                <div className="col-md-2 d-flex justify-content-end">
                                                  <div className="btn-outerbox">
                                                    {!props.meetingId 
                                                    && 
                                                    !props.isHideOptions &&
                                                    //minute.isCancelled===false &&
                                                    (employeeData?.userData
                                                      ?.isMeetingOrganiser ===
                                                      true ||
                                                      employeeData?.userData
                                                        ?.isAdmin === true ||
                                                      meetingData
                                                        ?.meetingDetails
                                                        ?.canWriteMOM ===
                                                        true) && minute.isCancelled===false ? (
                                                      <div className="d-inline-block menu-dropdown custom-dropdown">
                                                        <ActionDropDown
                                                          minuteId={minute._id}
                                                          actionDetails={minute}
                                                          isFollowOnMeeting={
                                                            true
                                                          }
                                                          //  status={meeting.meetingStatus.status}
                                                          handleReopenActionModal={() => {
                                                            handleReopenActionModal(
                                                              true,
                                                              minute
                                                            );
                                                          }}
                                                          handleApproveActionModal={() => {
                                                            handleApproveActionModal(
                                                              true,
                                                              minute
                                                            );
                                                          }}
                                                          handleCancelActionModal={() => {
                                                            handleCancelAction(
                                                              true,
                                                              minute
                                                            );
                                                          }}
                                                        />
                                                      </div>
                                                    ) : null}
                                                    {/* <p>
                                                <button type="button">
                                                  <Badge
                                                    bg="secondary"
                                                    onClick={() => {
                                                      handleEditMinuteModal(
                                                        true,
                                                        minute
                                                      );
                                                    }}
                                                  >
                                                    Edit
                                                    <svg
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      width="11"
                                                      height="11"
                                                      fill="currentColor"
                                                      className="bi bi-pencil-square me-0 filter-svg"
                                                      viewBox="0 0 16 16"
                                                    >
                                                      <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                                      <path
                                                        fill-rule="evenodd"
                                                        d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"
                                                      />
                                                    </svg>
                                                  </Badge>
                                                </button>
                                              </p>
                                              <p>
                                                <button type="button">
                                                  <Badge
                                                    bg="secondary"
                                                    onClick={() => {
                                                      handleDeleteMinuteModal(
                                                        true,
                                                        minute
                                                      );
                                                    }}
                                                  >
                                                    Delete
                                                    <svg
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      width="11"
                                                      height="11"
                                                      fill="currentColor"
                                                      className="bi-minus-circle bi bi-dash-circle me-0 filter-svg"
                                                      viewBox="0 0 16 16"
                                                    >
                                                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                                                      <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8" />
                                                    </svg>
                                                  </Badge>
                                                </button>
                                              </p> */}
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="row">
                                                <div className="col-md-4">
                                                  <label className="mt-1 mb-1">
                                                    Status
                                                  </label>
                                                </div>
                                                <div className="col-md-6">
                                                  <p>
                                                    {minute.isComplete ===
                                                    true ? (
                                                      <Badge bg="success">
                                                        Completed
                                                      </Badge>
                                                    ) : minute.isReopened ===
                                                      true ? (
                                                      <Badge bg="danger">
                                                        Reopened
                                                      </Badge>
                                                    ) : minute.isApproved ===
                                                      true ? (
                                                      <Badge bg="info">
                                                        Approved
                                                      </Badge>
                                                    ) : minute.isCancelled ===
                                                      true ? (
                                                      <Badge bg="secondary">
                                                        Cancelled
                                                      </Badge>
                                                    ) : (
                                                      <Badge bg="warning">
                                                        Pending
                                                      </Badge>
                                                    )}
                                                  </p>
                                                </div>
                                              </div>
                                            </div>

                                            {minute.isAction ? (
                                              <>
                                                <div className=" form-group">
                                                  <div className="row">
                                                    <div className="col-md-4">
                                                      <label className="mt-1 mb-1">
                                                        Due Date
                                                      </label>
                                                    </div>
                                                    <div className="col-md-8">
                                                      <p>
                                                        {" "}
                                                        {
                                                          formatDateTimeFormat(
                                                            minute?.dueDate
                                                          ).formattedDate
                                                        }
                                                      </p>
                                                    </div>
                                                  </div>
                                                </div>

                                                <div className=" form-group">
                                                  <div className="row">
                                                    <div className="col-md-4">
                                                      <label className="mt-1 mb-1">
                                                        Priority
                                                      </label>
                                                    </div>
                                                    <div className="col-md-8">
                                                      <p>
                                                        {" "}
                                                        {minute.priority
                                                          .charAt(0)
                                                          .toUpperCase() +
                                                          minute.priority
                                                            .slice(1)
                                                            .toLowerCase()}
                                                      </p>
                                                    </div>
                                                  </div>
                                                </div>
                                                {minute?.isAction ? (
                                                  <div className=" form-group">
                                                    <div className="row">
                                                      <div className="col-md-4">
                                                        <label className="mt-1 mb-1">
                                                          Responsible Person
                                                        </label>
                                                      </div>
                                                      <div className="col-md-8">
                                                        <p>
                                                          {convertFirstLetterOfFullNameToCapital(
                                                            minute
                                                              ?.assignedUserDetails
                                                              ?.name
                                                          )}
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </div>
                                                ) : null}
                                              </>
                                            ) : null}

                                            {minute.reassignDetails ? (
                                              <div className=" form-group">
                                                <div className="row">
                                                  <div className="col-md-4">
                                                    <label className="mt-1 mb-1">
                                                      Reassigned To
                                                    </label>
                                                  </div>
                                                  <div className="col-md-8 detail ">
                                                    {/* <p routerLink="/action-detail">Rakesh Baral</p> */}
                                                    <p>Rakesh Baral</p>
                                                  </div>
                                                </div>
                                              </div>
                                            ) : null}

                                            {minute?.attendees?.length == 0 ? (
                                              <>
                                                <div className="form-group mt-4">
                                                  <div className="row">
                                                    <div className="col-md-4">
                                                      <label className="mt-2 topic">
                                                        Accepted By
                                                      </label>
                                                    </div>
                                                    <div
                                                      className="col-md-8"
                                                      onClick={() =>
                                                        updateIsMinutesRsvpStatusModal(
                                                          "Accepted",
                                                          minute?.attendees?.filter(
                                                            (attendee) =>
                                                              attendee.status ===
                                                              "ACCEPTED"
                                                          )
                                                        )
                                                      }
                                                    >
                                                      {minute?.attendees?.filter(
                                                        (attendee) =>
                                                          attendee.status ===
                                                          "ACCEPTED"
                                                      ).length !== 0 &&
                                                        minute?.attendees
                                                          ?.filter(
                                                            (attendee) =>
                                                              attendee.status ===
                                                              "ACCEPTED"
                                                          )
                                                          .map((attendee) => {
                                                            return (
                                                              <>
                                                                <div className="attendees mb-2">
                                                                  <div className="attendee-list">
                                                                    {customName(
                                                                      attendee.name
                                                                    )}
                                                                  </div>
                                                                  {}
                                                                  {/* <p className="plus-more-text m-0">
                                                        +1 More
                                                      </p> */}
                                                                  <p className="plus-more-text m-0">
                                                                    {minute
                                                                      .attendees
                                                                      .length >
                                                                    5
                                                                      ? `+${
                                                                          minute
                                                                            .attendees
                                                                            .length -
                                                                          5
                                                                        } More`
                                                                      : null}
                                                                  </p>
                                                                </div>
                                                              </>
                                                            );
                                                          })}

                                                      <p>
                                                        Accepted by{" "}
                                                        {
                                                          minute?.attendees?.filter(
                                                            (attendee) =>
                                                              attendee.status ===
                                                              "ACCEPTED"
                                                          ).length
                                                        }
                                                        /
                                                        {
                                                          minute.attendees
                                                            .length
                                                        }{" "}
                                                        attendants
                                                      </p>
                                                    </div>
                                                  </div>
                                                </div>

                                                <div className="form-group mt-4">
                                                  <div className="row">
                                                    <div className="col-md-4">
                                                      <label className="mt-2 topic">
                                                        Rejected By
                                                      </label>
                                                    </div>
                                                    <div
                                                      className="col-md-8"
                                                      onClick={() =>
                                                        updateIsMinutesRsvpStatusModal(
                                                          "Rejected",
                                                          minute?.attendees?.filter(
                                                            (attendee) =>
                                                              attendee.status ===
                                                              "REJECTED"
                                                          )
                                                        )
                                                      }
                                                    >
                                                      {minute?.attendees?.filter(
                                                        (attendee) =>
                                                          attendee.status ===
                                                          "REJECTED"
                                                      ).length !== 0 &&
                                                        minute?.attendees
                                                          ?.filter(
                                                            (attendee) =>
                                                              attendee.status ===
                                                              "REJECTED"
                                                          )
                                                          .map((attendee) => {
                                                            return (
                                                              <>
                                                                <div className="attendees mb-2">
                                                                  <div className="attendee-list">
                                                                    {customName(
                                                                      attendee.name
                                                                    )}
                                                                  </div>
                                                                  {}
                                                                  {/* <p className="plus-more-text m-0">
                                                        +1 More
                                                      </p> */}
                                                                  <p className="plus-more-text m-0">
                                                                    {minute
                                                                      .attendees
                                                                      .length >
                                                                    5
                                                                      ? `+${
                                                                          minute
                                                                            .attendees
                                                                            .length -
                                                                          5
                                                                        } More`
                                                                      : null}
                                                                  </p>
                                                                </div>
                                                              </>
                                                            );
                                                          })}

                                                      <p>
                                                        Rejected by{" "}
                                                        {
                                                          minute?.attendees?.filter(
                                                            (attendee) =>
                                                              attendee.status ===
                                                              "REJECTED"
                                                          ).length
                                                        }
                                                        /
                                                        {
                                                          minute.attendees
                                                            .length
                                                        }{" "}
                                                        attendants
                                                      </p>
                                                    </div>
                                                  </div>
                                                </div>
                                              </>
                                            ) : null}
                                            {!employeeData.userData
                                              .isMeetingOrganiser &&
                                            props.meetingId ? (
                                              <div>
                                                <>
                                                  {/* {minute?.acceptRejectStatus ===
                                              "PENDING" ||
                                            minute?.acceptRejectStatus ===
                                              null ? (
                                              <div>
                                                {minuteData.loading &&
                                                minuteData.status ===
                                                  "ACCEPTED" ? (
                                                  <LoaderButton />
                                                ) : (
                                                  <button
                                                    className="add-minutes Mom-btn me-3"
                                                    onClick={() =>
                                                      dispatch(
                                                        rejectAcceptMinute(
                                                          {
                                                            status: "ACCEPTED",
                                                            meetingId:
                                                              minute.meetingId,
                                                          },
                                                          minute._id,
                                                          accessToken
                                                        )
                                                      )
                                                    }
                                                  >
                                                    <p>Accept</p>
                                                  </button>
                                                )}
                                              </div>
                                            ) : null} */}

                                                  {/* <button
                                            className="add-minutes Mom-btn me-3"
                                            onClick={() => {
                                              setAmendmentModal(true);
                                            }} */}

                                                  <button
                                                    className="add-minutes Mom-btn me-3"
                                                    type="button"
                                                    // onClick={() => props.openAgendaForm()}
                                                    onClick={() =>
                                                      handleAmendmentRequestModal(
                                                        minute._id
                                                      )
                                                    }
                                                  >
                                                    <p>Request for Amendment</p>
                                                  </button>

                                                  {/* {minute?.acceptRejectStatus ===
                                              "PENDING" ||
                                            minute?.acceptRejectStatus ===
                                              null ? (
                                              <div>
                                                {minuteData.loading &&
                                                minuteData.status ===
                                                  "REJECTED" ? (
                                                  <LoaderButton />
                                                ) : (
                                                  <button
                                                    className=" reset"
                                                    onClick={() =>
                                                      dispatch(
                                                        rejectAcceptMinute(
                                                          {
                                                            status: "REJECTED",
                                                            meetingId:
                                                              minute.meetingId,
                                                          },
                                                          minute._id,
                                                          accessToken
                                                        )
                                                      )
                                                    }
                                                  >
                                                    <p>Reject</p>
                                                  </button>
                                                )}
                                              </div>
                                            ) : null} */}
                                                </>
                                                <div className=" minutes-border"></div>
                                              </div>
                                            ) : null}

                                            {employeeData?.userData
                                              ?.isMeetingOrganiser &&
                                            minute?.amendmentDetails?.length !==
                                              0 ? (
                                              <div className="added-by">
                                                {minute?.amendmentDetails?.map(
                                                  (amendment) => {
                                                    function handleAmendmentUpdateByButton(
                                                      arg0,
                                                      createdById,
                                                      _id
                                                    ) {
                                                      throw new Error(
                                                        "Function not implemented."
                                                      );
                                                    }

                                                    return (
                                                      <>
                                                        <div className="add-agenda">
                                                          <p className="add-text">
                                                            Amendment Requested
                                                            By
                                                          </p>
                                                          <div className="attendee1 attendee-list">
                                                            {customName(
                                                              amendment.name
                                                            )}
                                                          </div>
                                                        </div>
                                                        <div
                                                          className="view"
                                                          onClick={() => {
                                                            handleAmendmentRequestModal(
                                                              minute._id,
                                                              amendment.details,
                                                              amendment.createdById
                                                            );
                                                          }}
                                                        >
                                                          <p>View Details</p>
                                                        </div>

                                                        <div className="add-agenda">
                                                          <div className="pe-2">
                                                            {/* <button className="add-minutes Mom-btn" 
                                                  type="button" 
                                                  onClick={()=> handleAmendmentUpdateByButton("ACCEPTED",amendment.createdById,minute._id) }>
                                                    <p>Accept</p>
                                                  </button> */}

                                                            {minuteData.loading &&
                                                            minuteData.status ===
                                                              "REJECTED" ? (
                                                              <LoaderButton />
                                                            ) : (
                                                              <button
                                                                className="add-minutes Mom-btn"
                                                                type="button"
                                                                onClick={() =>
                                                                  handleAmendmentUpdateByButton(
                                                                    "ACCEPTED",
                                                                    amendment.createdById,
                                                                    minute._id
                                                                  )
                                                                }
                                                              >
                                                                <p>Accept</p>
                                                              </button>
                                                            )}
                                                          </div>

                                                          <div>
                                                            {minuteData.loading &&
                                                            minuteData.status ===
                                                              "REJECTED" ? (
                                                              <LoaderButton />
                                                            ) : (
                                                              <button
                                                                className=" reset"
                                                                type="button"
                                                                onClick={() =>
                                                                  handleAmendmentUpdateByButton(
                                                                    "REJECTED",
                                                                    amendment.createdById,
                                                                    minute._id
                                                                  )
                                                                }
                                                              >
                                                                <p>Reject</p>
                                                              </button>
                                                            )}

                                                            {/* <button className=" reset"
                                                    type="button" 
                                                    onClick={()=> handleAmendmentUpdateByButton("REJECTED",amendment.createdById,minute._id) }
                                                  >
                                                    <p>Reject</p>
                                                  </button> */}
                                                          </div>
                                                        </div>
                                                      </>
                                                    );
                                                  }
                                                )}
                                              </div>
                                            ) : null}
                                          </div>
                                          <div className=" minutes-border"></div>
                                        </>
                                      );
                                    })}
                              </>
                            ) : null}
                          </div>
                        </div>
                      ) : null}
                    </>
                  );
                })}
              </>
            ) : (
              <div className="no-data-img">
                <Alert
                  status={"info"}
                  message={"No data available."}
                  timeoutSeconds={0}
                />
                <NoDataFound dataType={"meetingActivities"} />
              </div>
            )}
          </div>
          <ReopenActionModal
            meetingId={minuteDetails?.meetingId}
            minuteDetails={minuteDetails}
            IsReopenMinuteModal={isReopenMinuteModal}
            setIsReopenMinuteModal={setIsReopenMinuteModal}
            accessToken={accessToken}
            parentMeetingId={props.parentMeetingId}
          />
          <CommonModal
            message={"Do you want to cancel the action ?"}
            title={"Cancel Action"}
            setIsModalOpen={setIsCloseModalOpen}
            isModalOpen={isCloseModalOpen}
            handleSubmit={submitCancelAction}
            buttonName={"Cancel"}
            isCalledFromOthers={true}
          />
        </form>
      ) : null}
    </>
  );
};

export default ViewParentActions;
