import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useSelector, useDispatch } from "react-redux";
import AttendeeRsvpTable from "../Meeting/AttendeeRsvpTable";
import MinutesAcceptRejectStatusTable from "./MinutesAcceptRejectStatusTable";
import AddMinuteAgenda from "./AddMinuteAgenda";
import * as constantMessages from "../../constants/constatntMessages";
import { editAgenda } from "../../redux/actions/minuteActions/MinuteAction";
import { checkCharacterLeft } from "../../helpers/commonHelpers";
import { textBoxStrLength } from "../../config/config";
const EditAgendaModal = (props) => {
  console.log(props);
  const regularExpression = /^[0-9a-zA-Z .(),/-]+$/;
  const dispatch = useDispatch();
  const accessToken = localStorage.getItem("accessToken");
  const minuteData = useSelector((state) => state.minute);
  const { agendaData, meetingData } = props;
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    title: "",
    topic: "",
    timeLine: "0",
  });
  console.log(agendaData);
  useEffect(() => {
    console.log(formData);
    console.log(minuteData);
    setFormData({
      ...formData,
      title: agendaData?.title,
      topic: agendaData?.topic,
      timeLine: agendaData?.timeLine,
    });
    if (minuteData.isSuccess) {
      props.setIsEditAgendaModal(false);
    }
  }, [props.IsEditAgendaModal, minuteData.isSuccess]);

  const handleSubmit = () => {
    console.log("2222222222222")
    console.log(formData);
    const newErrors = validateForm(formData);
    setErrors(newErrors);
    console.log(newErrors);
    if (Object.keys(newErrors).length === 0) {
      const payload = {
        ...formData,
        organizationId: agendaData.organizationId,
        meetingId: agendaData.meetingId,
      };
      console.log(payload);
      dispatch(editAgenda(payload, agendaData._id, accessToken));
    }
  };

  const validateAgendaTitle = () => {
    console.log(formData);
    const errors = {};
    if (!formData?.title.trim()) {
      console.log(formData);
      errors.title = constantMessages.titleRequired;
      // errors.index = formData.index;
    } else if (
      formData.title.trim().length < 5 ||
      formData.title.trim().length > 100
    ) {
      errors.title = constantMessages.stringLengthError;
    } else if (!regularExpression.test(formData.title)) {
      errors.title = constantMessages.titleRegexError;
    }
    setErrors(errors);
  };
  const validateAgendaTime = () => {
    console.log(formData);
    const errors = {};

    if (formData.timeLine > 365 || formData.timeLine < 0) {
      errors.timeLine = constantMessages.invalidTime;
    }
    setErrors(errors);
  };

  const handleChange = (e, uid) => {
    // dispatch(updateIsCreateMeetingProcessed(false));
    setErrors({});
    //  dispatch(updateOtpProcessed(false));
    //  console.log("9999999999999999999999999999999999999", authData);
    const { name, value } = e.target;
    console.log(name, value);
    if(name==="topic"){
      if(value.length<=textBoxStrLength){
      setFormData({
        ...formData,
        //   index: props.index ,
        [name]: value,
      });
    }
    
    }
    else{
      setFormData({
        ...formData,
        //   index: props.index ,
        [name]: value,
      });
    }
  };

  const validateForm = () => {
    //  console.log(formData.title);
    const errors = {};

    if (!formData?.title.trim()) {
      errors.title = constantMessages.titleRequired;
      // errors.index = formData.index;
    } else if (
      formData.title.trim().length < 5 ||
      formData.title.trim().length > 100
    ) {
      errors.title = constantMessages.stringLengthError;
    
  } else if (!regularExpression.test(formData.title)) {
    errors.title = constantMessages.titleRegexError;
  }

    if (formData.timeLine > 365 || formData.timeLine < 0) {
      errors.timeLine = constantMessages.invalidTime;
    }

    return errors;
  };

  console.log(formData);
  return (
    <>
      <Modal
        show={props.IsEditAgendaModal}
        onHide={(e) => props.setIsEditAgendaModal(false)}
      >
        <Modal.Dialog>
          {/* <Modal.Title> */}
          <div className="modal-header attendees-modal">
            <div>
              <h4 className="modal-title">Edit Agenda</h4>
            </div>
            <div>
              <button
                type="button"
                className="btn-close"
                aria-label="Close"
                onClick={(e) => props.setIsEditAgendaModal(false)}
              ></button>
            </div>
          </div>

          <Modal.Body className="attendees-popup modal-margin">
            <div className="open-div">
              <div>
                <div className="form-group">
                  <div className="mb-2">
                    <div className="row">
                      <div className="col-md-4">
                        <label className="mb-1">Agenda Title</label>
                      </div>
                      <div className="col-md-8">
                        <input
                          type="text"
                          placeholder="Enter agenda title here"
                          name="title"
                          value={formData.title}
                          onChange={handleChange}
                          onBlur={validateAgendaTitle}
                          autoComplete="off"
                        />

                        {errors.title ? (
                          <span className="error-message">{errors.title}</span>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <div className="row">
                    <div className="col-md-4">
                      <label className="mb-1">
                        What are the topic to discuss ?
                      </label>
                    </div>
                    <div className="col-md-8">
                      <textarea
                        name="topic"
                        value={formData.topic}
                        onChange={handleChange}
                        // onBlur={() => {
                        //   props.agendaData(formData);
                        // }}
                        autoComplete="off"
                        placeholder="Enter issue to discuss..."
                        id=""
                        cols="56"
                        rows="4"
                      ></textarea>
                      
                                 <p className="success-message">
            {checkCharacterLeft(formData.topic?formData.topic:"")} Characters left
          </p>
                    </div>
                  </div>
                </div>

                <div className="form-group m-0">
                  <div className="row">
                    <div className="col-md-4">
                      <label className="mb-1">
                        How long will this agenda item take to discuss?
                      </label>
                    </div>
                    <div className="col-md-8">
                      <div className="time-taken">
                        <input
                          // max="360"
                          // min="0"
                          name="timeLine"
                          value={formData.timeLine}
                          onChange={handleChange}
                          onBlur={validateAgendaTime}
                          // autoComplete="off"
                          required="required"
                          type="number"
                          autocomplete="off"
                        />
                        <div className="minute_box">mins</div>
                      </div>
                      {errors.timeLine && (
                        <span className="error-message">{errors.timeLine}</span>
                      )}

                      {/* {props.errorData.index===props.agenda.index && props.errorData.time && ( 
                  <span className="error-message">
                    {props.errorData.time}
                  </span>
                 )} */}
                    </div>
                  </div>

                  <div className="mt-3 plus pointer"></div>
                
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
                    <Button
                      variant="light"
                      onClick={(e) => props.setIsEditAgendaModal(false)}
                      className="btn-light"
                    >
                      Cancel
                    </Button>
                    <Button variant="primary" onClick={handleSubmit}>
                      {/* {props.buttonName} */}
                      Update
                    </Button>
                  </Modal.Footer>
          {/* <Button variant="primary"> 
           Save changes 
          </Button> 
          <Button variant="secondary"> 
           Close 
          </Button>  */}
        </Modal.Dialog>
      </Modal>
    </>
  );
};

export default EditAgendaModal;
