import React, { useState, useEffect } from "react";
import "./style/Login.css";
import meetingPlusLogo from "../../assets/images/meeting-plus-logo.svg";
import { Navigate, Link, useParams, useNavigate } from "react-router-dom";
import LoginImage from "./LoginImage";
import { useSelector, useDispatch } from "react-redux";
import {
  sendOtp,
  updateIsSuccess,
  updateOtpProcessed,
} from "../../redux/actions/authActions/authAction";
import * as constantMessages from "../../constants/constatntMessages";
import LoaderButton from "../Common/LoaderButton";
import Alert from "../Common/Alert";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { convertFirstLetterToCapital } from "../../helpers/commonHelpers";
import favImage  from "../../assets/images/favicon.ico";

const LoginByOtp = () => {
  let { userId, meetingId, rsvp } = useParams();
  const navigate = useNavigate();
  // const [formData, setFormData] = useState({
  //   organizationName: null,
  //   organizationEmail: null,
  //   organizationLogo: null,
  // });
  console.log(userId, meetingId, rsvp);
  // useEffect(() => {
  //   fetchDetails();
  //  // navigate("/login");
  // }, []);

  // const redirectToLogin = () => {
  //   console.log("innnnnnnnnnnnnnnnnnnnnnnn");
  //   navigate("/login");
  // };
  const updateRsvpByEmail = async () => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/api/V1/meeting/updateRsvpByEmail/${meetingId}`,
        {
          userId,
          rsvp,
        }
      );

      if (response.data.success) {
        toast.success(
          await constantMessages.rsvpConfirmationMessage(
            convertFirstLetterToCapital(rsvp)
          ),
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          }
        );
        navigate("/login");
        // setTimeout(() => redirectToLogin(), 6000);
      } else {
        toast.error(response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    } catch (error) {
      toast.error(error.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      console.log("Error while updating Employee:", error);
    }
  };

  const dispatch = useDispatch();
  const authData = useSelector((state) => state.auth);
  const [formData, setFormData] = useState({
    email: "",
  });

  const [isSetPassword, setIsSetPassword] = useState(false);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    document.title = "Log In: Meeting Plus";
    if (meetingId) {
      updateRsvpByEmail();
    }
  }, []);

  const handleChange = (e) => {
    setErrors({});
    dispatch(updateOtpProcessed(false));
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const newErrors = validateForm(formData);
    setErrors(newErrors);
    dispatch(updateOtpProcessed(false));
    if (Object.keys(newErrors).length === 0) {
      // Form submission logic here
      dispatch(sendOtp(formData.email, isSetPassword));
      console.log("Form submitted successfully!");
    } else {
      console.log(`Form submission failed
       due to validation errors.`);
    }
  };

  const fieldValidationCheck = (e) => {
    e.preventDefault();

    const newErrors = validateForm(formData);
    setErrors(newErrors);
    if (Object.keys(newErrors).length === 0) {
      // Form submission logic here

      console.log("Form submitted successfully!");
    } else {
      console.log(`Form submission failed
       due to validation errors.`);
    }
  };

  const validateForm = (data) => {
    const errors = {};
    if (!data.email.trim()) {
      errors.email = constantMessages.emailRequired;
    } else if (!/\S+@\S+\.\S+/.test(data.email)) {
      errors.email = constantMessages.invalidEmail;
    }
    return errors;
  };

  return (
    <section className="sign-in login-page">
      {authData.isSuccess && !authData.isSetPassword ? (
        <Navigate to="/otp-verify" />
      ) : null}
      {authData.isSuccess && authData.isSetPassword ? (
        <Navigate to="/set-password" />
      ) : null}
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
            <div className="loginform-container abstract-bg">
              <div className="logo-container">
                <img
                  className="login-logo"
                  // @ts-ignore
                  src={meetingPlusLogo}
                  alt="logo"
                />
                
              </div>
              <form onSubmit={handleSubmit}>
                <div className="text">
                  <h4>Welcome to Meeting Plus</h4>
                  <p>Enter your email id to logging in to your account</p>
                </div>

                <div className="form-group">
                  <div className="email-group">
                    <label className="mb-1">
                      Email <span className="imp-field">*</span>
                    </label>
                    <div className="inner-group">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        fill="currentColor"
                        className="bi bi-envelope-at"
                        viewBox="0 0 16 16"
                      >
                        <path d="M2 2a2 2 0 0 0-2 2v8.01A2 2 0 0 0 2 14h5.5a.5.5 0 0 0 0-1H2a1 1 0 0 1-.966-.741l5.64-3.471L8 9.583l7-4.2V8.5a.5.5 0 0 0 1 0V4a2 2 0 0 0-2-2zm3.708 6.208L1 11.105V5.383zM1 4.217V4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v.217l-7 4.2z" />
                        <path d="M14.247 14.269c1.01 0 1.587-.857 1.587-2.025v-.21C15.834 10.43 14.64 9 12.52 9h-.035C10.42 9 9 10.36 9 12.432v.214C9 14.82 10.438 16 12.358 16h.044c.594 0 1.018-.074 1.237-.175v-.73c-.245.11-.673.18-1.18.18h-.044c-1.334 0-2.571-.788-2.571-2.655v-.157c0-1.657 1.058-2.724 2.64-2.724h.04c1.535 0 2.484 1.05 2.484 2.326v.118c0 .975-.324 1.39-.639 1.39-.232 0-.41-.148-.41-.42v-2.19h-.906v.569h-.03c-.084-.298-.368-.63-.954-.63-.778 0-1.259.555-1.259 1.4v.528c0 .892.49 1.434 1.26 1.434.471 0 .896-.227 1.014-.643h.043c.118.42.617.648 1.12.648m-2.453-1.588v-.227c0-.546.227-.791.573-.791.297 0 .572.192.572.708v.367c0 .573-.253.744-.564.744-.354 0-.581-.215-.581-.8Z" />
                      </svg>
                      <input
                        type="text"
                        placeholder="Type Your Email"
                        name="email"
                        onChange={handleChange}
                        onBlur={fieldValidationCheck}
                        value={formData.email}
                        autocomplete="off"
                      />
                    </div>
                    {errors.email && (
                      <span className="error-message">{errors.email}</span>
                    )}

                    {/* {authData.isOtpProcessed ? (
                      <Alert
                        status={authData.isSuccess}
                        message={authData.message}
                        timeoutSeconds={0}
                      />
                    ) : null} */}
                  </div>
                </div>
                {!authData.loading ? (
                  <button
                    disabled={authData.loading}
                    className="signin-btn1"
                    type="submit"
                    onClick={() => setIsSetPassword(false)}
                  >
                    Send OTP
                  </button>
                ) : (
                  <LoaderButton />
                )}

                <div className="or">or</div>

                <Link to="/login-by-password" state={formData}>
                  <button
                    disabled={authData.loading}
                    className="signin-btn2 background-wh"
                    onClick={() => {
                      dispatch(updateIsSuccess(true));
                    }}
                  >
                    Sign In With Password
                  </button>
                </Link>

                <div className="set-pwd">
                  <button
                    type="submit"
                    className="signin-btn2"
                    onClick={() => setIsSetPassword(true)}
                    disabled={authData.loading}
                  >
                    Set Password
                  </button>
                </div>
              </form>
            </div>
          </div>

          <LoginImage />
          <ToastContainer />
        </div>
      </div>
    </section>
  );
};

export default LoginByOtp;
