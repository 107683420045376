import React, { useState, useEffect } from "react";
import Header from "../../Common/Header/Header";
import Sidebar from "../../Common/Sidebar/Sidebar";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import "./alert.css";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, Navigate, Link } from "react-router-dom";
import { logOut } from "../../../redux/actions/authActions/authAction";
import LoaderButton from "../../Common/LoaderButton";
const Alert = () => {
  const userData = JSON.parse(localStorage.getItem("userData"));
  const authData = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  if (authData.isInValidUser) {
    console.log("innnnnnnnnnnnnnnnnnnnnnnnnnnn");
    localStorage.removeItem("accessToken");
    localStorage.removeItem("userData");
    localStorage.removeItem("rememberMe");
    dispatch(logOut());
    navigate("/login");
  }
  const organizationId = userData.organizationId;
  const accessToken = localStorage.getItem("accessToken");
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingDays, setIsLoadingDays] = useState(false);
  const generateOptions = (maxValue, step) => {
    return Array.from(
      { length: Math.floor(maxValue / step) + 1 },
      (_, i) => i * step
    );
  };
  const hourOptions = Array.from({ length: 24 }, (_, i) => i + 0);

  const minuteOptions = generateOptions(60, 5);
  const chaseUpOptions = Array.from({ length: 30 }, (_, i) => i + 1);

  const [meetingReminders, setMeetingReminders] = useState({
    hours: 0,
    minutes: 0,
  });

  const [chaseUpActions, setChaseUpActions] = useState(0);
  const [savedSettings, setSavedSettings] = useState({});

  useEffect(() => {
    const fetchAlertSettings = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/V1/configuration/viewConfiguration/${organizationId}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: accessToken,
            },
          }
        );
        if (response.data && response.data.data) {
          const { mettingReminders, chaseOfAction } = response.data.data;
          console.log("setMeetingReminders adat-->", response.data.data);
          setMeetingReminders(mettingReminders);
          setChaseUpActions(chaseOfAction);
          setSavedSettings(response.data.data);
        }
      } catch (error) {
        console.error("Error fetching alert settings:", error);
      }
    };

    fetchAlertSettings();
  }, [organizationId]);

  const validateMeetingReminders = () => {
    const newErrors = {};

    if (!meetingReminders.hours && meetingReminders.hours !== 0) {
      newErrors.hours = "Hours is required.";
    }

    if (!meetingReminders.minutes && meetingReminders.minutes !== 0) {
      newErrors.minutes = "Minutes is required.";
    }
    if (!chaseUpActions) {
      newErrors.chaseUpActions = "Chase up actions is required.";
    }
    setErrors(newErrors);
    return newErrors;
  };

  const validateDays = () => {
    const newErrors = {};
    setErrors(newErrors);
  };
  const handleBlurValidation = (name, value) => {
    validateMeetingReminders();

    const minHourLimit = 0;
    const maxHourLimit = 100;
    const minMinuteLimit = 0;
    const maxMinuteLimit = 1000;
    const minChaseOfActionLimit = 0;
    const maxChaseOfActionLimit = 100;
    let error = "";

    if (name === "hours" && (value < minHourLimit || value > maxHourLimit)) {
      error = `Hours must be between ${minHourLimit} and ${maxHourLimit}.`;
    }
    if (
      name === "minutes" &&
      (value < minMinuteLimit || value > maxMinuteLimit)
    ) {
      error = `Minutes must be  between ${minMinuteLimit} and ${maxMinuteLimit}.`;
    }

    if (name === "chaseUpActions" && value < minChaseOfActionLimit) {
      error = `Days must be  above ${minChaseOfActionLimit}.`;
    }
    if (name === "chaseUpActions" && value > maxChaseOfActionLimit) {
      error = `Days must be below ${maxChaseOfActionLimit}.`;
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: error,
    }));
  };

  const isValidate = () => {
    let isValid = true;
    let errors = {};
    if (meetingReminders.hours == 0 && meetingReminders.minutes == 0) {
      errors.hours = "Time should not be 0 hour 0 minutes";
      isValid = false;
    }
    // if (
    //   !Number.isInteger(meetingReminders.hours) ||
    //   meetingReminders.hours < 0
    // ) {
    //   errors.hours = "Hours should not be empty.";
    //   isValid = false;
    // }

    // if (
    //   !Number.isInteger(meetingReminders.minutes) ||
    //   meetingReminders.minutes < 0
    // ) {
    //   errors.minutes = "Minutes should not be empty.";
    //   isValid = false;
    // }

    // if (!Number.isInteger(chaseUpActions) || chaseUpActions < 0) {
    //   errors.chaseUpActions = "Days should not be empty.";
    //   isValid = false;
    // }

    setErrors(errors);
    return isValid;
  };

  const handleSaveAlert = async (e) => {
    e.preventDefault();

    if (isValidate()) {
      try {
        const minHourLimit = 0;
        const maxHourLimit = 100;
        const minMinuteLimit = 0;
        const maxMinuteLimit = 1000;

        if (
          meetingReminders.hours >= minHourLimit &&
          meetingReminders.hours <= maxHourLimit &&
          meetingReminders.minutes >= minMinuteLimit &&
          meetingReminders.minutes <= maxMinuteLimit
        ) {
          const alertData = {
            organizationId,
            mettingReminders: meetingReminders,
            chaseOfAction: chaseUpActions,
            isAlert: true,
          };
          console.log(alertData);
          const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/api/V1/alert/editAlert`,
            { ...alertData, organizationId },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: accessToken,
              },
            }
          );

          if (response.data.success) {
            toast.success(response.data.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
            setSavedSettings(alertData);
          } else {
            toast.error(response.data.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          }
        } else {
          toast.error(
            `Value must be above ${minHourLimit} hour and between ${minMinuteLimit} to ${maxMinuteLimit} minutes`,
            {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            }
          );
        }
      } catch (error) {
        console.error("Error saving alert settings:", error.message);
      } finally {
        setIsLoading(false);
        setIsLoadingDays(false);
      }
    } else {
      setIsLoading(false);
    }
  };

  const handleMeetingReminders = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await handleSaveAlert(e);
    } catch (error) {
      console.log(error);
      // toast.error("Error in meeting", {
      //   position: "top-right",
      //   autoClose: 5000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "colored",
      // });
      setIsLoading(false);
    }
  };

  const handleChaseOfAction = async (e) => {
    e.preventDefault();
    setIsLoadingDays(true);
    try {
      await handleSaveAlert(e);
    } catch (error) {
      console.log(error);
      setIsLoadingDays(false);
    }
  };

  const handleChange = (e) => {
    setErrors({});

    const { name, value } = e.target;
    const intValue = parseInt(value, 10);

    if (intValue < 0) {
      console.log(intValue);
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "Must be a Number greater than 0.",
      }));
    } else {
      if (name === "hours" || name === "minutes") {
        setMeetingReminders((prevMeetingReminders) => ({
          ...prevMeetingReminders,
          [name]: intValue,
        }));
      } else if (name === "chaseUpActions") {
        setChaseUpActions(intValue);
      }

      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));
    }
  };

  return (
    <div>
      <Header />
      <Sidebar />
      <div className="main-content">
        <div className="meeting-room-page">
          <div className="meeting-header-text">
            <h4>Alerts</h4>
          </div>
          <div className="mt-2 table-box">
            <form className="meeting-form">
              <div className="pb-3 alert-box">
                <label className="checkbox-container pb-3 d-flex">
                  Send Meeting Reminders
                </label>
                <div className="d-flex">
                  <div className="alert-send-time">
                    <p>
                      <select
                        value={meetingReminders.hours}
                        name="hours"
                        onChange={handleChange}
                      >
                        {hourOptions.map((option) => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>
                      hour(s)
                    </p>
                  </div>

                  <div className="alert-send-time pe-2">
                    <p>
                      <select
                        value={meetingReminders.minutes}
                        name="minutes"
                        onChange={handleChange}
                      >
                        {minuteOptions.map((option) => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>
                      min(s)
                    </p>
                  </div>
                  <div>
                    <p>before meeting</p>
                  </div>
                </div>
                <div className="error-container">
                  {errors.hours && (
                    <p className="error">
                      <span className="error-message">{errors.hours}</span>
                    </p>
                  )}
                  {errors.minutes && (
                    <p className="error">
                      <span className="error-message">{errors.minutes}</span>
                    </p>
                  )}
                </div>
                <div className="mt-3">
                  {isLoading ? (
                    <LoaderButton />
                  ) : (
                    <button
                      className="add-btn send-email Mom-btn"
                      type="button"
                      onClick={handleMeetingReminders}
                      disabled={isLoading}
                    >
                      <p>Save Alert Setting</p>
                    </button>
                  )}
                </div>
              </div>
              <div className="mt-3 alert-box">
                <label className="checkbox-container pb-3 d-flex">
                  Chase Up Actions Every
                </label>
                <div>
                  <div className="alert-send-time  time-width">
                    <select
                      name="chaseUpActions"
                      value={chaseUpActions}
                      onChange={handleChange}
                    >
                      {chaseUpOptions.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>
                    <p>days</p>
                  </div>
                </div>
                <div className="error-container">
                  {errors.chaseUpActions && (
                    <p className="error">
                      <span className="error-message">
                        {errors.chaseUpActions}
                      </span>
                    </p>
                  )}
                </div>
                <div className="mt-3">
                  {isLoadingDays ? (
                    <LoaderButton />
                  ) : (
                    <button
                      className="add-btn send-email Mom-btn"
                      type="button"
                      onClick={handleChaseOfAction}
                      disabled={isLoadingDays}
                    >
                      <p>Save Alert Setting</p>
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Alert;
