import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import * as constantMessages from "../../constants/constatntMessages";
import { setFinalMinuteData } from "../../redux/actions/minuteActions/MinuteAction";
import { checkCharacterLeft } from "../../helpers/commonHelpers";
import { textBoxStrLength } from "../../config/config";

const RequestForReassignModal = (props) => {
  console.log(props);
  const [reason, setReason] = useState("");
  const [errors, setErrors] = useState({});

  useEffect(() => {
    return () => {
      console.log("call--------------->>>>>>>>>>>>>>");
      setReason("");
    };
  }, []);

  const handleSubmit = () => {
    const newErrors = validateForm();
    setErrors(newErrors);
    if (Object.keys(newErrors).length === 0) {
      props.handleSubmit(reason);
    }
  };
  const validateForm = () => {
    console.error(reason);
    const errors = {};
    if (!reason.trim()) {
      errors.reason = constantMessages.reasonRequired;
    }
    setErrors(errors);
    return errors;
  };

  const closeModal = () => {
    setReason("");
    setErrors({});
    props.setIsModalOpen(false);
  };

  const updateValues = (e) => {
    const { name, value } = e.target;
    if(value.length<=textBoxStrLength){
      setReason(value);
    }
  };

  return (
    <>
      <Modal show={props.isModalOpen} onHide={closeModal}>
        <div className="modal-header">
          <h4 className="modal-title">Request for Reassignment</h4>
          
          <button
            type="button"
            className="btn-close"
            aria-label="Close"
            onClick={closeModal}
          ></button>
        </div>
        <div className="modal-body delete-txt">
     
          <label className="mb-1">Reason
          <span className="star-mark">*</span>
          </label>
          <textarea
            cols="40"
            rows="4"
            id="remark"
            name="remark"
            onBlur={validateForm}
            onChange={updateValues}
            value={reason}
          
            required
          ></textarea>
           <p className="success-message">
            {checkCharacterLeft(reason)} Characters left
          </p>
          {errors.reason && (
            <span className="error-message">{errors.reason}</span>
          )}
        </div>

        <div className="modal-footer">
      
          <button
            type="button"
            onClick={handleSubmit}
            className="Mom-btn btn btn-secondary bg-primary border-primary"
          >
            <p>Submit Request</p>
          </button>
        </div>
      </Modal>
    </>
  );
};

export default RequestForReassignModal;
