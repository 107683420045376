import { setInValidUser } from "../authActions/authAction";
import {
  FAIL_REQUEST,
  MAKE_REQUEST,
  CREATE_MINUTE_RESPONSE,
  SET_FINAL_MINUTES_DATA,
  CREATE_NEW_AGENDA_RESPONSE,
  SET_FINAL_AGENDA_MINUTES_DATA,
  SET_AGENDA_DATAS,
  SET_SUCCESS,
  UPDATE_MINUTE_RESPONSE,
  PROCESS_AMENDMENT_REQUEST,
  UPDATE_AMENDMENT_REQUEST,
  EDIT_AGENDA_RESPONSE,
  DELETE_AGENDA_RESPONSE,
  SET_MOM_ACCEPT_DETAILS,
} from "./actionTypes";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import * as constantMessages from "../../../constants/constatntMessages";
import {
  getAgendaWithMinutesDetails,
  getMeetingActivitiesDetails,
} from "../meetingActions/MeetingAction";

export const makeRequest = (data) => {
  return {
    type: MAKE_REQUEST,
    payload: data,
  };
};
export const failRequest = (err) => {
  return {
    type: FAIL_REQUEST,
    payload: err,
  };
};

export const createMinutes = (payload, accessToken) => {
  console.log(payload, accessToken);
  return (dispatch) => {
    dispatch(makeRequest());
    dispatch(setSuccess(false));
    const webApiUrl = `${process.env.REACT_APP_API_URL}/api/V1/minute/createMinutes`;
    const headerObject = {
      headers: {
        "Content-Type": "application/json",
        Authorization: accessToken,
      },
    };
    // console.log("webApiUrl----------------", webApiUrl);
    // console.log("accessToken------------>>>>>", accessToken);

    // const bodyPayload = {
    //   organizationId: payload.organizationId,
    // };
    axios
      .post(webApiUrl, payload, headerObject)
      .then((res) => {
        const resData = res.data;
        console.log("resData-------------------------------->", resData);
        if (resData.data?.isInValidUser) {
          dispatch(setInValidUser(true));
          toast.error(resData.message, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            // transition: Bounce,
          });
        }
        if (resData.success) {
          dispatch(getAgendaWithMinutesDetails(payload.meetingId, accessToken));
          dispatch(getMeetingActivitiesDetails(payload.meetingId, accessToken));
          toast.success(constantMessages.minuteCreated, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            // transition: Bounce,
          });
        } else {
          toast.error(resData.message, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            // transition: Bounce,
          });
        }
        dispatch(createMinuteResponse(resData));
      })
      .catch((err) => {
        dispatch(failRequest(err.message));
        toast.error(constantMessages.serverErrorMessage, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          // transition: Bounce,
        });
      });
  };
};

export const createMinuteResponse = (data) => {
  return {
    type: CREATE_MINUTE_RESPONSE,
    payload: data,
  };
};

export const setFinalMinuteData = (data) => {
  return {
    type: SET_FINAL_MINUTES_DATA,
    payload: data,
  };
};

export const addAgendaDetails = (payload, meetingId, accessToken) => {
  console.log(payload, accessToken);
  return (dispatch) => {
    dispatch(makeRequest());
    dispatch(setSuccess(false));
    const webApiUrl = `${process.env.REACT_APP_API_URL}/api/V1/agenda/createAgendaWithMinutes`;
    const headerObject = {
      headers: {
        "Content-Type": "application/json",
        Authorization: accessToken,
      },
    };
    // console.log("webApiUrl----------------", webApiUrl);
    // console.log("accessToken------------>>>>>", accessToken);

    // const bodyPayload = {
    //   organizationId: payload.organizationId,
    // };
    axios
      .post(webApiUrl, payload, headerObject)
      .then((res) => {
        const resData = res.data;
        console.log("resData-------------------------------->", resData);
        if (resData.data?.isInValidUser) {
          dispatch(setInValidUser(true));
          toast.error(resData.message, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            // transition: Bounce,
          });
        }
        if (resData.success) {
          dispatch(getAgendaWithMinutesDetails(meetingId, accessToken));
          dispatch(getMeetingActivitiesDetails(meetingId, accessToken));
          toast.success(resData.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            // transition: Bounce,
          });
        } else {
          toast.error(resData.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            // transition: Bounce,
          });
        }
        dispatch(createAgendaResponse(resData));
      })
      .catch((err) => {
        dispatch(failRequest(err.message));
        toast.error(constantMessages.serverErrorMessage, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          // transition: Bounce,
        });
      });
  };
};

export const createAgendaResponse = (data) => {
  return {
    type: CREATE_NEW_AGENDA_RESPONSE,
    payload: data,
  };
};
export const setAgendaData = (data) => {
  console.log(data);
  return {
    type: SET_AGENDA_DATAS,
    payload: data,
  };
};
export const setFinalAgendaMinuteData = (data) => {
  return {
    type: SET_FINAL_AGENDA_MINUTES_DATA,
    payload: data,
  };
};

export const setSuccess = (data) => {
  return {
    type: SET_SUCCESS,
    payload: data,
  };
};

export const rejectAcceptMinute = (payload, minuteId, accessToken) => {
  console.log(payload, accessToken);
  return (dispatch) => {
    dispatch(makeRequest(payload.status));
    dispatch(setSuccess(false));
    const webApiUrl = `${process.env.REACT_APP_API_URL}/api/V1/minute/acceptOrRejectMinutes/${minuteId}`;
    const headerObject = {
      headers: {
        "Content-Type": "application/json",
        Authorization: accessToken,
      },
    };
    // console.log("webApiUrl----------------", webApiUrl);
    // console.log("accessToken------------>>>>>", accessToken);

    // const bodyPayload = {
    //   organizationId: payload.organizationId,
    // };
    axios
      .put(webApiUrl, payload, headerObject)
      .then((res) => {
        const resData = res.data;
        console.log("resData-------------------------------->", resData);
        if (resData.data?.isInValidUser) {
          dispatch(setInValidUser(true));
          toast.error(resData.message, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            // transition: Bounce,
          });
        }
        if (resData.success) {
          dispatch(getAgendaWithMinutesDetails(payload.meetingId, accessToken));
          toast.success(resData.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            // transition: Bounce,
          });
        } else {
          toast.error(resData.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            // transition: Bounce,
          });
        }
        dispatch(updateMinuteResponse(resData));
      })
      .catch((err) => {
        dispatch(failRequest(err.message));
        toast.error(constantMessages.serverErrorMessage, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          // transition: Bounce,
        });
      });
  };
};

export const updateMinuteResponse = (data) => {
  return {
    type: UPDATE_MINUTE_RESPONSE,
    payload: data,
  };
};

export const processAmendmentRequest = (payload, minuteId, accessToken) => {
  console.log(payload, accessToken);
  return (dispatch) => {
    dispatch(makeRequest(payload.status));
    dispatch(setSuccess(false));
    const webApiUrl = `${process.env.REACT_APP_API_URL}/api/V1/minute/createAmendmentRequest/${minuteId}`;
    const headerObject = {
      headers: {
        "Content-Type": "application/json",
        Authorization: accessToken,
      },
    };
    // console.log("webApiUrl----------------", webApiUrl);
    // console.log("accessToken------------>>>>>", accessToken);

    // const bodyPayload = {
    //   organizationId: payload.organizationId,
    // };
    axios
      .put(webApiUrl, payload, headerObject)
      .then((res) => {
        const resData = res.data;
        console.log("resData-------------------------------->", resData);
        if (resData.data?.isInValidUser) {
          dispatch(setInValidUser(true));
          toast.error(resData.message, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            // transition: Bounce,
          });
        }
        if (resData.success) {
          dispatch(getAgendaWithMinutesDetails(payload.meetingId, accessToken));

          toast.success(resData.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            // transition: Bounce,
          });
        } else {
          toast.error(resData.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            // transition: Bounce,
          });
        }
        dispatch(processAmendmentRequestResponse(resData));
      })
      .catch((err) => {
        dispatch(failRequest(err.message));
        toast.error(constantMessages.serverErrorMessage, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          // transition: Bounce,
        });
      });
  };
};

export const processAmendmentRequestResponse = (data) => {
  return {
    type: PROCESS_AMENDMENT_REQUEST,
    payload: data,
  };
};

export const updateAmendmentRequest = (payload, minuteId, accessToken) => {
  console.log(payload, accessToken);
  return (dispatch) => {
    dispatch(makeRequest(payload.status));
    dispatch(setSuccess(false));
    const webApiUrl = `${process.env.REACT_APP_API_URL}/api/V1/minute/updateAmendmentRequest/${minuteId}`;
    const headerObject = {
      headers: {
        "Content-Type": "application/json",
        Authorization: accessToken,
      },
    };
    // console.log("webApiUrl----------------", webApiUrl);
    // console.log("accessToken------------>>>>>", accessToken);

    // const bodyPayload = {
    //   organizationId: payload.organizationId,
    // };
    axios
      .put(webApiUrl, payload, headerObject)
      .then((res) => {
        const resData = res.data;
        console.log("resData-------------------------------->", resData);
        if (resData.data?.isInValidUser) {
          dispatch(setInValidUser(true));
          toast.error(resData.message, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            // transition: Bounce,
          });
        }
        if (resData.success) {
          dispatch(getAgendaWithMinutesDetails(payload.meetingId, accessToken));
          dispatch(getMeetingActivitiesDetails(payload.meetingId, accessToken));
          toast.success(resData.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            // transition: Bounce,
          });
        } else {
          toast.error(resData.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            // transition: Bounce,
          });
        }
        dispatch(updateAmendmentRequestResponse(resData));
      })
      .catch((err) => {
        dispatch(failRequest(err.message));
        toast.error(constantMessages.serverErrorMessage, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          // transition: Bounce,
        });
      });
  };
};

export const updateAmendmentRequestResponse = (data) => {
  return {
    type: UPDATE_AMENDMENT_REQUEST,
    payload: data,
  };
};

export const editAgenda = (payload, agendaId, accessToken) => {
  console.log(payload, accessToken);
  return (dispatch) => {
    dispatch(makeRequest(null));
    dispatch(setSuccess(false));
    const webApiUrl = `${process.env.REACT_APP_API_URL}/api/V1/agenda/updateAgenda/${agendaId}`;
    const headerObject = {
      headers: {
        "Content-Type": "application/json",
        Authorization: accessToken,
      },
    };
    // console.log("webApiUrl----------------", webApiUrl);
    // console.log("accessToken------------>>>>>", accessToken);

    // const bodyPayload = {
    //   organizationId: payload.organizationId,
    // };
    axios
      .put(webApiUrl, payload, headerObject)
      .then((res) => {
        const resData = res.data;
        console.log("resData-------------------------------->", resData);
        if (resData.data?.isInValidUser) {
          dispatch(setInValidUser(true));
          toast.error(resData.message, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            // transition: Bounce,
          });
        }
        if (resData.success) {
          dispatch(getAgendaWithMinutesDetails(payload.meetingId, accessToken));
          dispatch(getMeetingActivitiesDetails(payload.meetingId, accessToken));
          toast.success(resData.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            // transition: Bounce,
          });
        } else {
          toast.error(resData.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            // transition: Bounce,
          });
        }
        dispatch(editAgendaResponse(resData));
      })
      .catch((err) => {
        dispatch(failRequest(err.message));
        toast.error(constantMessages.serverErrorMessage, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          // transition: Bounce,
        });
      });
  };
};

export const editAgendaResponse = (data) => {
  return {
    type: EDIT_AGENDA_RESPONSE,
    payload: data,
  };
};

export const deleteAgenda = (payload, agendaId, accessToken) => {
  console.log(payload, accessToken);
  return (dispatch) => {
    dispatch(makeRequest(null));
    dispatch(setSuccess(false));
    const webApiUrl = `${process.env.REACT_APP_API_URL}/api/V1/agenda/deleteAgenda/${agendaId}`;
    const headerObject = {
      headers: {
        "Content-Type": "application/json",
        Authorization: accessToken,
      },
    };
    // console.log("webApiUrl----------------", webApiUrl);
    // console.log("accessToken------------>>>>>", accessToken);

    // const bodyPayload = {
    //   organizationId: payload.organizationId,
    // };
    axios
      .put(webApiUrl, payload, headerObject)
      .then((res) => {
        const resData = res.data;
        console.log("resData-------------------------------->", resData);
        if (resData.data?.isInValidUser) {
          dispatch(setInValidUser(true));
          toast.error(resData.message, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            // transition: Bounce,
          });
        }
        if (resData.success) {
          dispatch(getAgendaWithMinutesDetails(payload.meetingId, accessToken));
          dispatch(getMeetingActivitiesDetails(payload.meetingId, accessToken));
          toast.success(resData.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            // transition: Bounce,
          });
        } else {
          toast.error(resData.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            // transition: Bounce,
          });
        }
        dispatch(deleteAgendaResponse(resData));
      })
      .catch((err) => {
        dispatch(failRequest(err.message));
        toast.error(constantMessages.serverErrorMessage, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          // transition: Bounce,
        });
      });
  };
};

export const deleteAgendaResponse = (data) => {
  return {
    type: DELETE_AGENDA_RESPONSE,
    payload: data,
  };
};

export const deleteMinute = (meetingId, minuteId, accessToken) => {
  console.log(accessToken);
  return (dispatch) => {
    dispatch(makeRequest());
    dispatch(setSuccess(false));
    const webApiUrl = `${process.env.REACT_APP_API_URL}/api/V1/minute/deleteMinute/${minuteId}`;
    const headerObject = {
      headers: {
        "Content-Type": "application/json",
        Authorization: accessToken,
      },
    };
    // console.log("webApiUrl----------------", webApiUrl);
    // console.log("accessToken------------>>>>>", accessToken);

    const bodyPayload = {
      meetingId,
    };
    axios
      .put(webApiUrl, bodyPayload, headerObject)
      .then((res) => {
        const resData = res.data;
        console.log("resData-------------------------------->", resData);
        if (resData.data?.isInValidUser) {
          dispatch(setInValidUser(true));
          toast.error(resData.message, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            // transition: Bounce,
          });
        }
        if (resData.success) {
          dispatch(getAgendaWithMinutesDetails(meetingId, accessToken));
          dispatch(getMeetingActivitiesDetails(meetingId, accessToken));
          toast.success(resData.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            // transition: Bounce,
          });
        } else {
          toast.error(resData.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            // transition: Bounce,
          });
        }
        dispatch(updateMinuteResponse(resData));
      })
      .catch((err) => {
        dispatch(failRequest(err.message));
        toast.error(constantMessages.serverErrorMessage, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          // transition: Bounce,
        });
      });
  };
};

export const updateMinute = (payload, minuteId, accessToken) => {
  console.log(payload, accessToken);
  return (dispatch) => {
    dispatch(makeRequest(payload.status));
    dispatch(setSuccess(false));
    const webApiUrl = `${process.env.REACT_APP_API_URL}/api/V1/minute/updateMinute/${minuteId}`;
    const headerObject = {
      headers: {
        "Content-Type": "application/json",
        Authorization: accessToken,
      },
    };
    // console.log("webApiUrl----------------", webApiUrl);
    // console.log("accessToken------------>>>>>", accessToken);

    // const bodyPayload = {
    //   organizationId: payload.organizationId,
    // };
    axios
      .put(webApiUrl, payload, headerObject)
      .then((res) => {
        const resData = res.data;
        console.log("resData-------------------------------->", resData);
        if (resData.data?.isInValidUser) {
          dispatch(setInValidUser(true));
          toast.error(resData.message, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            // transition: Bounce,
          });
        }
        if (resData.success) {
          dispatch(getAgendaWithMinutesDetails(payload.meetingId, accessToken));
          dispatch(getMeetingActivitiesDetails(payload.meetingId, accessToken));
          toast.success(resData.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            // transition: Bounce,
          });
        } else {
          toast.error(resData.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            // transition: Bounce,
          });
        }
        dispatch(updateMinuteResponse(resData));
      })
      .catch((err) => {
        dispatch(failRequest(err.message));
        toast.error(constantMessages.serverErrorMessage, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          // transition: Bounce,
        });
      });
  };
};

export const acceptMOM = (meetingId, payload, accessToken) => {
  console.log(meetingId, accessToken);
  return (dispatch) => {
    dispatch(makeRequest());
    dispatch(setSuccess(false));
    const webApiUrl = `${process.env.REACT_APP_API_URL}/api/V1/minute/acceptMinutes/${meetingId}`;
    const headerObject = {
      headers: {
        "Content-Type": "application/json",
        Authorization: accessToken,
      },
    };
    // console.log("webApiUrl----------------", webApiUrl);
    // console.log("accessToken------------>>>>>", accessToken);

    // const bodyPayload = {
    //   momId: payload.momId,
    // };
    axios
      .put(webApiUrl, payload, headerObject)
      .then((res) => {
        const resData = res.data;
        console.log("resData-------------------------------->", resData);
        if (resData.data?.isInValidUser) {
          dispatch(setInValidUser(true));
          toast.error(resData.message, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            // transition: Bounce,
          });
        }
        if (resData.success) {
          dispatch(getAgendaWithMinutesDetails(meetingId, accessToken));
          dispatch(getMeetingActivitiesDetails(meetingId, accessToken));
          dispatch(getMomAcceptDetails(meetingId, accessToken));
          toast.success(resData.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            // transition: Bounce,
          });
        } else {
          toast.error(resData.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            // transition: Bounce,
          });
        }
        dispatch(updateMinuteResponse(resData));
      })
      .catch((err) => {
        dispatch(failRequest(err.message));
        toast.error(constantMessages.serverErrorMessage, {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          // transition: Bounce,
        });
      });
  };
};

export const downloadMinute = (meetingId, token) => {
  console.log("accessToken------------>>>>>", token);
  return (dispatch) => {
    dispatch(makeRequest());
    const webApiUrl = `${process.env.REACT_APP_API_URL}/api/V1/minute/downloadMinutes/${meetingId}`;
    const headerObject = {
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    };
    console.log("webApiUrl----------------", webApiUrl);
    axios
      .get(webApiUrl, headerObject)
      .then((result) => {
        console.log("result------------------------->>>>>>>", result);
        const resData = result.data;
        if (resData.data?.isInValidUser) {
          dispatch(setInValidUser(true));
          toast.error(resData.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            // transition: Bounce,
          });
        }
      })
      .catch((err) => {
        console.log("err------------------------->>>>>>>", err);
        dispatch(failRequest(err.message));
        toast.error(constantMessages.serverErrorMessage, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          // transition: Bounce,
        });
      });
  };
};

export const getMomAcceptDetails = (meetingId, token) => {
  console.log("accessToken------------>>>>>", token);
  return (dispatch) => {
    dispatch(makeRequest());
    const webApiUrl = `${process.env.REACT_APP_API_URL}/api/V1/minute/getMomAcceptDetails/${meetingId}`;
    const headerObject = {
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    };
    console.log("webApiUrl----------------", webApiUrl);
    axios
      .get(webApiUrl, headerObject)
      .then((result) => {
        console.log("result------------------------->>>>>>>", result);
        const resData = result.data;
        if (resData.data?.isInValidUser) {
          dispatch(setInValidUser(true));
          toast.error(resData.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            // transition: Bounce,
          });
        }
        dispatch(setMomAcceptDetails(resData));
      })
      .catch((err) => {
        console.log("err------------------------->>>>>>>", err);
        dispatch(failRequest(err.message));
        toast.error(constantMessages.serverErrorMessage, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          // transition: Bounce,
        });
      });
  };
};

export const setMomAcceptDetails = (data) => {
  console.log("SET_MOM_ACCEPT_DETAILS",data)
  return {
    type: SET_MOM_ACCEPT_DETAILS,
    payload: data,
  };
};
