import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useSelector, useDispatch } from "react-redux";
import AttendeeRsvpTable from "../Meeting/AttendeeRsvpTable";
import MinutesAcceptRejectStatusTable from "./MinutesAcceptRejectStatusTable";
import AddMinuteAgenda from "./AddMinuteAgenda";
import * as constantMessages from "../../constants/constatntMessages";
import {
  editAgenda,
  updateMinute,
} from "../../redux/actions/minuteActions/MinuteAction";
import { fetchAttendeesList } from "../../redux/actions/meetingActions/MeetingAction";
import { getEmployeeList } from "../../redux/actions/userAction/userAction";
import LoaderButton from "../Common/LoaderButton";

const EditMinuteModal = (props) => {
  console.log(props);
  const { minuteData } = props;
  const regularExpression = /^[0-9a-zA-Z .(),/-]+$/;
  const meetingData = useSelector((state) => state.meeting);
  const employeeData = useSelector((state) => state.user);
  const minutesDetails = useSelector((state) => state.minute);
  console.log(minutesDetails);
  const userData = JSON.parse(localStorage.getItem("userData"));
  const accessToken = localStorage.getItem("accessToken");
  const dispatch = useDispatch();
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    description: "",
    dueDate: "",
    priority: "",
    attendyType: "fromPreviousMeeting",
    responsiblePersonId: "",
    isAction: false,
    //attendeesData: null,
    name: "",
    email: "",
  });
  useEffect(() => {
    console.log(formData);
    console.log(minuteData);
    if (minuteData?.dueDate) {
      var date = new Date(minuteData?.dueDate);
      var dateString = new Date(
        date?.getTime() - date?.getTimezoneOffset() * 60000
      )
        .toISOString()
        .split("T")[0];
    }
    setFormData({
      ...formData,
      description: minuteData?.description,
      dueDate: dateString,
      priority: minuteData?.priority,
      attendyType: "fromPreviousMeeting",
      responsiblePersonId: minuteData?.assignedUserId,
      isAction: minuteData?.isAction,
      //attendeesData: null,
      name: minuteData?.name,
      email: minuteData?.email,
    });
    if (minuteData?.isSuccess) {
      props.setIsEditMinuteModal(false);
    }
    if (
      formData.attendyType === "fromPreviousMeeting" &&
      meetingData?.attendeesList?.length === 0
    ) {
      if(accessToken){
      dispatch(fetchAttendeesList(userData?.organizationId, accessToken));
      }
    }
  }, [props.IsEditMinuteModal, minuteData?.isSuccess]);

  const checkHandler = (e, uid) => {
    const { name, value, checked } = e.target;
    console.log(uid);
    console.log(checked);
    if (uid) {
      const modifiedMinutes = minutesDetails.finalMinutesData.map((obj) => {
        if (obj.uid === uid) {
          return { ...obj, isAction: checked };
        }
        return obj;
      });

      console.log(modifiedMinutes);
      // setMinuteData(modifiedMinutes);
    } else {
      setFormData({
        ...formData,
        isAction: !formData.isAction,
      });
    }
  };

  const handleChange = (e) => {
    setErrors({});
    const { name, value } = e.target;
    console.log(name, value);

    setFormData({
      ...formData,
      //   index: props.index ,
      [name]: value,
    });

    if (value === "fromEmployeeList") {
      const payload = {
        organizationId: userData.organizationId,
      };

      dispatch(getEmployeeList(payload, accessToken));
    }
  };

  const validateMinuteTitle = () => {
    console.log(formData);
    const errors = {};
    if (!formData?.description.trim()) {
      console.log(formData);
      errors.description = constantMessages.titleRequired;
      // errors.index = formData.index;
    } else if (!regularExpression.test(formData.description)) {
      errors.description = constantMessages.titleRegexError;
    } else if (
      formData.description.trim().length < 5 ||
      formData.description.trim().length > 100
    ) {
      errors.description = constantMessages.stringLengthError;
    }
    setErrors(errors);
  };

  const dateFieldValidationCheck = (e) => {
    const errors = {};
    if (formData?.dueDate?.trim()) {
      const currentDate = new Date();
      const inputDate = new Date(formData?.dueDate);
      let differenceInTime = inputDate.getTime() - currentDate.getTime();
      let differenceInDays = Math.round(differenceInTime / (1000 * 3600 * 24));
      if (differenceInDays < 0) {
        errors.date = constantMessages.invalidDate;
      }
    }
    setErrors(errors);
  };

  const nameFieldValidationCheck = (e) => {
    console.log("on blur");
    const errors = {};
    // if (!formData.password) {
    //   errors.roomId = constantMessages.roomRequired;
    //   setErrors(errors);
    // }
    // const regularExpression = /^[A-Za-z\s]+$/; //returns true if matched, vaidates for a-z and A-Z and white space
    if (!formData?.name?.trim()) {
      errors.name = constantMessages.nameRequired;
    } else if (!regularExpression.test(formData.name)) {
      errors.name = constantMessages.invalidName;
    } else if (
      formData?.name?.trim().length < 3 ||
      formData?.name?.trim().length > 50
    ) {
      errors.name = constantMessages.stringLengthError;
    }
    setErrors(errors);
  };

  const emailFieldValidationCheck = (e) => {
    const errors = {};
    // if (!formData.password) {
    //   errors.roomId = constantMessages.roomRequired;
    //   setErrors(errors);
    // }
    // if (!formData.password) {
    if (!formData?.email?.trim()) {
      errors.email = constantMessages.emailRequired;
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = constantMessages.invalidEmail;
    }

    setErrors(errors);
    // }
  };

  // const validateForm = (data) => {
  //   const errors = {};
  //   if (formData.attendyType === "addNewPeople") {
  //     //  const regularExpression = /^[A-Za-z\s]+$/; //returns true if matched, vaidates for a-z and A-Z and white space
  //     if (!formData.name.trim()) {
  //       errors.name = constantMessages.nameRequired;
  //     } else if (!regularExpression.test(formData.name)) {
  //       errors.name = constantMessages.invalidName;
  //     } else if (
  //       formData.name.trim().length < 10 ||
  //       formData.name.trim().length > 100
  //     ) {
  //       errors.title = constantMessages.stringLengthError;
  //     }

  //     if (!formData.email.trim()) {
  //       errors.email = constantMessages.emailRequired;
  //     } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
  //       errors.email = constantMessages.invalidEmail;
  //     }
  //   }
  //   // else {
  //   //   console.log("innnn", !formData.responsiblePersonId.trim());
  //   //   if (!formData.responsiblePersonId.trim()) {
  //   //     console.log("innnn2222222", !formData.responsiblePersonId.trim());
  //   //     errors.responsiblePersonId = constantMessages.responsiblePersonRequired;
  //   //   }
  //   // }

  //   if (!formData?.description.trim()) {
  //     console.log(formData);
  //     errors.description = constantMessages.titleRequired;
  //     // errors.index = formData.index;
  //   } else if (!regularExpression.test(formData.description)) {
  //     errors.description = constantMessages.titleRegexError;
  //   } else if (
  //     formData.description.trim().length < 10 ||
  //     formData.description.trim().length > 100
  //   ) {
  //     errors.description = constantMessages.stringLengthError;
  //   }

  //   if (formData?.dueDate?.trim()) {
  //     const currentDate = new Date();
  //     const inputDate = new Date(formData.dueDate);
  //     let differenceInTime = inputDate.getTime() - currentDate.getTime();
  //     let differenceInDays = Math.round(differenceInTime / (1000 * 3600 * 24));
  //     if (differenceInDays < 0) {
  //       errors.date = constantMessages.invalidDate;
  //     }
  //   }
  //   // if (!formData?.priority.trim()) {
  //   //   console.log(formData);
  //   //   errors.priority = constantMessages.priorityRequired;
  //   //   // errors.index = formData.index;
  //   // }

  //   console.log("errors----------------", errors);

  //   return errors;
  // };


  const validateForm = (data) => {
    console.log(formData);
    const errors = {};
    if (formData.attendyType === "addNewPeople") {
      //  const regularExpression = /^[A-Za-z\s]+$/; //returns true if matched, vaidates for a-z and A-Z and white space
      if (!formData?.name?.trim()) {
        errors.name = constantMessages.nameRequired;
      } else if (!regularExpression.test(formData?.name)) {
        errors.name = constantMessages.invalidName;
      } else if (
        formData?.name?.trim().length < 3 ||
        formData?.name?.trim().length > 50
      ) {
        errors.name = constantMessages.nameLengthError;
      }

      if (!formData?.email?.trim()) {
        errors.email = constantMessages.emailRequired;
      } else if (!/\S+@\S+\.\S+/.test(formData?.email)) {
        errors.email = constantMessages.invalidEmail;
      }
    }
    else {
      console.log("innnn", !formData?.responsiblePersonId?.trim());
      if (!formData?.responsiblePersonId?.trim() && formData?.isAction && formData?.name===(""||undefined) && formData?.email===(""||undefined)) {
        console.log("innnn2222222", !formData?.responsiblePersonId?.trim());
        errors.responsiblePersonId = constantMessages.responsiblePersonRequired;
      }
    }

    if (!formData?.description.trim()) {
      console.log(formData);
      errors.description = constantMessages.titleRequired;
      // errors.index = formData.index;
    } else if (!regularExpression.test(formData.description)) {
      errors.description = constantMessages.titleRegexError;
    } else if (
      formData.description.trim().length < 5 ||
      formData.description.trim().length > 100
    ) {
      errors.description = constantMessages.stringLengthError;
    }

    if (formData?.dueDate?.trim() && formData?.isAction) {
      const currentDate = new Date(meetingData?.meetingDetails?.date);
      const inputDate = new Date(formData?.dueDate);
      let differenceInTime = inputDate.getTime() - currentDate.getTime();
      let differenceInDays = Math.round(differenceInTime / (1000 * 3600 * 24));
      if (differenceInDays < 0) {
        errors.date = constantMessages.dateMoreThanMeetingDate;
      }
    }
    else if(!formData?.dueDate?.trim() && formData?.isAction){
      errors.date = constantMessages.dateRequired;
    }
    if (!formData?.priority?.trim() && formData?.isAction) {
   
      errors.priority = constantMessages.priorityRequired;
      // errors.index = formData.index;
    }


  

    console.log("errors----------------", errors);

    return errors;
  };




  let attendeesData = meetingData?.meetingDetails?.attendees.map((attendee) => {
    console.log("ttttttttt", attendee);
    return {
      id: attendee._id,
      status: "PENDING",
    };
  });
  console.log(attendeesData);
  console.log(formData);
  console.log(minuteData);
  const onAddMinute = () => {
    console.log(formData);
    console.log(minuteData);
    const newErrors = validateForm(formData);
    setErrors(newErrors);
    console.log(newErrors);
    if (Object.keys(newErrors).length === 0) {
      console.log("inside form submit success");
      //   setFormData({
      //     ...formData,
      //     index: formData.index + 1,
      //   });

      // const responsiblePersonId =
      //   formData.responsiblePersonId === "" &&
      //   formData.name === "" &&
      //   formData.email === ""
      //     ? userData._id
      //     : formData.responsiblePersonId;
      // console.log(meetingData?.meetingDetails, formData);
      // const newMinuteData = {
      //   meetingId: meetingData?.meetingDetails?._id?.toString(),
      //   description: formData.description,
      //   dueDate: formData.dueDate,
      //   priority: formData.priority === "" ? "LOW" : formData.priority,
      //   isNewUser:
      //     formData.name !== ("" || undefined) &&
      //     formData.email !== ("" || undefined)
      //       ? true
      //       : false,
      //   //  attendyType: formData.attendyType,
      //   assignedUserId: responsiblePersonId,
      //   isAction: formData.isAction,
      //   //attendeesData: null,
      //   name: formData.name,
      //   email: formData.email,
      //   // uid,
      // };

      const responsiblePersonId =
      formData.responsiblePersonId === "" &&
      formData.name === "" &&
      formData.email === ""
        ? userData._id
        : formData.responsiblePersonId;
    console.log(meetingData?.meetingDetails, formData);
    const isNewUser=
    formData.name !== undefined &&
    formData.email !== undefined &&
    formData.isAction === true
      ? true
      : formData.name === undefined &&
        formData.email === undefined &&
        formData.isAction === true
      ? false
      : undefined;
    const newMinuteData = {
      // createdById: userData._id,
     organizationId: userData?.organizationId,
     // agendaId: props.agenda?._id,
      meetingId: meetingData?.meetingDetails?._id?.toString(),
     // attendees: attendeesData,
      description: formData.description,
      dueDate: formData.isAction === true && formData.dueDate !== ""? formData.dueDate:undefined,
      priority: formData.isAction === true && formData.priority !== ""
          ? formData.priority
          : undefined,
          isNewUser,
      //  attendyType: formData.attendyType,
      assignedUserId:
        formData.isAction === true && isNewUser===false ? responsiblePersonId : undefined,
      isAction: formData.isAction,
      //attendeesData: null,
      name: formData.isAction === true && isNewUser===true ? formData.name : undefined,
      email: formData.isAction === true && isNewUser===true ? formData.email : undefined,
      // uid,
    };

    console.log(newMinuteData);

      console.log(newMinuteData);
      // setMinuteData(newMinuteData);
      dispatch(updateMinute(newMinuteData, minuteData._id, accessToken));
      props.setIsEditMinuteModal(false);
      //dispatch(setFinalMinuteData(newMinuteData));
      //   dispatch(
      //     createMinutes(
      //       {
      //         meetingId: meetingData.meetingDetails._id,
      //         minutes:[newMinuteData]
      //         // minutes: minuteData.finalMinutesData.map(
      //         //   ({ uid, attendyType, responsiblePersonId, ...keepAttrs }) =>
      //         //     keepAttrs
      //         // ),
      //       },
      //       accessToken
      //     )
      //   );
      setFormData({
        ...formData,
        description: " ",
        dueDate: "",
        priority: "",
        attendyType: "fromPreviousMeeting",
        responsiblePersonId: "",
        isAction: false,
        //attendeesData: null,
        name: "",
        email: "",
      });
      // toast.info("One minute added.", {
      //   position: "top-right",
      //   autoClose: 1000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "colored",
      //   // transition: Bounce,
      // });
    } else {
      console.log("inside form submit errror");

      //setNumAgenda(numAgenda + 1);
    }
  };

  const handleSubmit = () => {};
console.log(formData)
  return (
    <>
      <Modal
        show={props.IsEditMinuteModal}
        onHide={(e) => props.setIsEditMinuteModal(false)}
      >
        <Modal.Dialog>
          {/* <Modal.Title> */}
          <div className="modal-header attendees-modal">
            <div>
              <h4 className="modal-title">Edit Minute</h4>
            </div>
            <div>
              <button
                type="button"
                className="btn-close"
                aria-label="Close"
                onClick={(e) => props.setIsEditMinuteModal(false)}
              ></button>
            </div>
          </div>

          <Modal.Body className="attendees-popup modal-margin">
            <div className="open-div">
            
                <form className="addminutesboxfrom">
                  <div
                    className={
                      props.isMinuteShow && props.index === props.currentIndex
                        ? "minutes-box show"
                        : !props.isMinuteShow &&
                          props.index === props.currentIndex
                        ? "minutes-box"
                        : "minutes-box"
                    }
                    // className="mt-4 minutes-box show"
                  >
                    <div className="form-group">
                      <div className=" mt-1 mb-1 d-flex justify-content-between align-items-center ">
                        <label>Minutes</label>

                        <div className="form-check ">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            // value={formData.isAction}
                            id="flexCheckDefault"
                            name="isAction"
                            onChange={checkHandler}
                            //  checked={formData.isAction}
                            checked={formData.isAction}
                          />
                          <label
                            className="form-check-label "
                            id="flexCheckDefault"
                            for="flexCheckDefault"
                          >
                            Add Action
                          </label>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <div>
                            <textarea
                             // type="text"
                              placeholder="Enter minute description here"
                              name="description"
                              value={formData.description}
                              onChange={handleChange}
                              onBlur={validateMinuteTitle}
                              autoComplete="off"
                            />

                            {errors.description ? (
                              <span className="error-message">
                                {errors.description}
                              </span>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="add-action">
                      {formData.isAction ? (
                        <>
                          <div className="form-group">
                            <div className="position-relative">
                              <label className="pb-2 input-date">
                                {" "}
                                Due Date
                              </label>
                              <input
                                type="date"
                                name="dueDate"
                                value={formData.dueDate}
                                onChange={handleChange}
                                onBlur={dateFieldValidationCheck}
                              />
                              {/* <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="#fff"
                  className="bi bi-calendar3 calender"
                  viewBox="0 0 16 16"
                >
                  <path d="M14 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2M1 3.857C1 3.384 1.448 3 2 3h12c.552 0 1 .384 1 .857v10.286c0 .473-.448.857-1 .857H2c-.552 0-1-.384-1-.857z" />
                  <path d="M6.5 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2m3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2m3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2m-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2m3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2m3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2m3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2m-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2m3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2m3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2" />
                </svg> */}
                            </div>
                            {errors.date && (
                              <span className="error-message">
                                {errors.date}
                              </span>
                            )}
                          </div>

                          <div className="form-group">
                            <div className="position-relative">
                              <label className="pb-2 input-date">
                                Priority
                              </label>
                              <div>
                                <select
                                  name="priority"
                                  id="priority"
                                  onChange={handleChange}
                                >
                                  {minuteData.priority ? (
                                    <option value={minuteData.priority}>
                                      {minuteData.priority
                                        .charAt(0)
                                        .toUpperCase() +
                                        minuteData.priority
                                          .slice(1)
                                          .toLowerCase()}
                                    </option>
                                  ) : (
                                    <option value="">Select Priority</option>
                                  )}

                                  <option value="HIGH">High</option>
                                  <option value="NORMAL">Normal</option>
                                  <option value="LOW">Low</option>
                                </select>
                              </div>
                            </div>
                            {errors.priority ? (
                              <span className="error-message">
                                {errors.priority}
                              </span>
                            ) : null}
                          </div>

                          <div className="form-group pb-3 border-bottom">
                            <label className="pb-2">
                              Select Responsible Person
                            </label>

                            {/* <div className="pb-2 d-flex w-100">
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  value="manually"
                  type="radio"
                  name="locationtype"
                  id="flexRadioDefault1"
                />
                <label className="form-check-label">
                  Select From Attendees
                </label>
              </div>
              <div className="form-check form-check-inline">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    value="meetingroom"
                    type="radio"
                    name="locationtype"
                    id="flexRadioDefault1"
                  />
                  <label className="form-check-label">
                    Select From Employees
                  </label>
                </div>
              </div>
            </div> */}

                            <div className="w-100">
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="attendyType"
                                  value="fromPreviousMeeting"
                                  id="flexRadioDefault1"
                                  onChange={handleChange}
                                  checked={
                                    formData.attendyType ===
                                    "fromPreviousMeeting"
                                  }
                                />
                                <label
                                  className="mb-2 form-check-label"
                                  for="flexRadioDefault1"
                                  id="flexRadioDefault1"
                                >
                                  Select From Previous Meetings
                                </label>
                              </div>

                              <div className="form-check-inline">
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="attendyType"
                                    value="fromEmployeeList"
                                    id="flexRadioDefault2"
                                    onChange={handleChange}
                                    checked={
                                      formData.attendyType ===
                                      "fromEmployeeList"
                                    }
                                  />
                                  <label
                                    className=" mb-2 form-check-label"
                                    for="flexRadioDefault2"
                                    id="flexRadioDefault2"
                                  >
                                    Select From Employees
                                  </label>
                                </div>
                              </div>

                              <div className="form-check-inline">
                                <div className="form-check">

                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="attendyType"
                                    value="addNewPeople"
                                    id="flexRadioDefault3"
                                    checked={
                                      formData.attendyType === "addNewPeople"
                                    }
                                    // checked={this.state.value === 1}
                                    onChange={handleChange}
                                  />
                                  <label
                                    className=" mb-2 form-check-label"
                                    for="flexRadioDefault3"
                                    id="flexRadioDefault3"
                                  >
                                    Add New People
                                  </label>
                                </div>
                              </div>
                            </div>

                            {formData.attendyType === "fromPreviousMeeting" ? (
                              <select
                                className=""
                                // name="attendeesData"
                                // onChange={handleAttendeeChange}
                                // value={attendeeId}

                                onChange={handleChange}
                                name="responsiblePersonId"
                                value={formData.responsiblePersonId}
                              >
                                {minuteData?.assignedUserDetails?._id ? (
                                  <option
                                    key={minuteData?.assignedUserDetails?._id}
                                    value={minuteData?.assignedUserDetails?._id}
                                  >
                                    {minuteData?.assignedUserDetails?.name} (
                                    {minuteData?.assignedUserDetails?.email})
                                  </option>
                                ) : (
                                  <option value="" selected={true}>
                                    {" "}
                                    Name (Email Address)
                                  </option>
                                )}

                                {meetingData.attendeesList &&
                                  meetingData.attendeesList
                                    .filter(
                                      (item) =>
                                        item._id !==
                                        minuteData?.assignedUserDetails?._id
                                    )
                                    .map((attendee, index) => {
                                      return (
                                        <option
                                          key={index}
                                          value={attendee._id}
                                        >
                                          {attendee.name} / {attendee.email}
                                        </option>
                                      );
                                    })}
                              </select>
                            ) : formData.attendyType === "fromEmployeeList" ? (
                              <select
                                className="mb-2"
                                onChange={handleChange}
                                name="responsiblePersonId"
                                value={formData.responsiblePersonId}
                                selected={true}
                              >
                                <option value="" selected>
                                  {" "}
                                  Name / Employee ID
                                </option>
                                {employeeData.employeeList &&
                                  employeeData.employeeList.map(
                                    (employee, index) => {
                                      return (
                                        <option
                                          value={employee._id}
                                          key={index}
                                        >
                                          {employee.name} / {employee.empId}
                                        </option>
                                      );
                                    }
                                  )}
                              </select>
                            ) : formData.attendyType === "addNewPeople" ? (
                              <div>
                                <label className="mb-1">Add New People</label>
                                <div className="row">
                                  <div className="col-xl-6 col-md-6">
                                    
                                    <input
                                      type="text"
                                      autoComplete="off"
                                      placeholder="Name"
                                      name="name"
                                      value={formData.name}
                                      onChange={handleChange}
                                      onBlur={nameFieldValidationCheck}
                                    />
                                    {errors.name && (
                                      <span className="error-message">
                                        {errors.name}
                                      </span>
                                    )}
                                  </div>
                                  <div className="col-xl-6 col-md-6 ">
                                    <input
                                      type="text"
                                      placeholder="Email"
                                      autoComplete="off"
                                      name="email"
                                      value={formData.email}
                                      onBlur={emailFieldValidationCheck}
                                      onChange={handleChange}
                                    />
                                    {errors.email && (
                                      <span className="error-message">
                                        {errors.email}
                                      </span>
                                    )}
                                  </div>
                                </div>
                              </div>
                            ) : null}
                            {errors.responsiblePersonId && (
                <span className="error-message">
                  {errors.responsiblePersonId}
                </span>
              )}
                          </div>
                        </>
                      ) : null}
                      {/* <div className="form-group m-0 d-flex ">
            <button
              type="button"
              className="btn rounded-pill add-btn Mom-btn d-flex align-items-center justify-content-center"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="#fff"
                className="bi bi-plus-circle pointer me-2"
                viewBox="0 0 16 16"
              >
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4" />
              </svg>
              <p> Add </p>
            </button>
          </div> */}

                      {/* <div className="form-group m-0 d-flex ">
              {!employeeData.loading ? (
                <button
                  type="button"
                  className="btn rounded-pill add-btn Mom-btn d-flex align-items-center justify-content-center "
                  onClick={onAddMinute}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="#fff"
                    className="bi bi-plus-circle pointer me-2"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                    <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4" />
                  </svg>
                  <p> Add </p>
                </button>
              ) : (
                <LoaderButton />
              )}
            </div> */}
                    </div>
                  </div>
                </form>
            
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="light"
              onClick={(e) => props.setIsEditMinuteModal(false)}
              className="btn-light"
            >
              Cancel
            </Button>
            <Button variant="primary" onClick={onAddMinute}>
              {/* {props.buttonName} */}
              Update
            </Button>
          </Modal.Footer>
          {/* <Button variant="primary"> 
           Save changes 
          </Button> 
          <Button variant="secondary"> 
           Close 
          </Button>  */}
        </Modal.Dialog>
      </Modal>
    </>
  );
};

export default EditMinuteModal;
