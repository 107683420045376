
import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import * as constantMessages from "../../constants/constatntMessages";
import { useSelector, useDispatch } from "react-redux";
import { deleteAgenda } from "../../redux/actions/minuteActions/MinuteAction";

const DeleteAgendaModal = (props) => {
  console.log(props);
 const {agendaData}=props
 const accessToken = localStorage.getItem("accessToken");
 const dispatch = useDispatch();
  const minuteData = useSelector((state) => state.minute);
  useEffect(() => {
  
    
      if(minuteData.isSuccess){
        props.setIsDeleteAgendaModal(false)
      }
    
  }, [minuteData.isSuccess]);

  const handleSubmit = () => {
   
    // props.handleSubmit(remark);
    const payload={
        meetingId:agendaData.meetingId
    }
    console.log(payload)
    dispatch(deleteAgenda(payload,agendaData._id,accessToken))
   }
 

  return (
    <>


<Modal
        show={props.isDeleteAgendaModal}
        onClick={(e) => props.setIsDeleteAgendaModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete Agenda</Modal.Title>
        </Modal.Header>
        <Modal.Body>Do you want to delete {props.agendaData?.title} ?</Modal.Body>
        <Modal.Footer>

        <Button  variant="light"
            onClick={(e) => props.setIsDeleteAgendaModal(false)}
            className="btn-light"
          >
            No
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            {/* {props.buttonName} */}
            Yes
          </Button>
          
        </Modal.Footer>
      </Modal>

      {/* <Modal
        show={props.isDeleteAgendaModal}
        onHide={(e) => props.setIsDeleteAgendaModal(false)}
      >
        <div className="modal-header">
          <h4 className="modal-title">Delete Agenda</h4>
          <button
            type="button"
            className="btn-close"
            aria-label="Close"
            onClick={(e) => props.setIsDeleteAgendaModal(false)}
          ></button>
        </div>
  

        <div className="modal-footer">
          <button
            type="button"
            onClick={handleSubmit}
            className="Mom-btn btn btn-secondary bg-primary border-primary"
          >
            <p>Delete</p>
          </button>
          <button
            type="button"
            onClick={(e) => props.setIsDeleteAgendaModal(false)}
            className="reset btn btn-light bg-white border-primary text-primary "
          >
            <p>Close</p>
          </button>
        </div>
      </Modal> */}
    </>
  );
};

export default DeleteAgendaModal;
