import {
   
    MAKE_REQUEST,
    FAIL_REQUEST,
    GET_NOTIFICATION_LIST,
    UPDATE_ISSUCCESS
  } from "../actions/notificationAction/actionTypes";
  
  const initialObject = {
    loading: false,
    message: "",
    totalCount: 0,
    isSuccess: false,
    notificationList: [],
  };
  
  export const notificationReducer = (state = initialObject, action) => {
    console.log("----------------------->>>>>>", action);
    switch (action.type) {
      case MAKE_REQUEST:
        return {
          ...state,
          loading: true,
          isDuplicateUser: "null",
          // meetingList: []
        };
      case FAIL_REQUEST:
        return {
          ...state,
          loading: false,
          message: action.payload.message,
        };
  
      case GET_NOTIFICATION_LIST:
        return {
          ...state,
          loading: false,
          //  loading: false,
          message: action.payload.message,
          notificationList: action.payload?.data?.notificationList,
          isSuccess: action.payload.success,
        };
        
        case UPDATE_ISSUCCESS:
          return {
            ...state,
           // loading: false,
            //  loading: false,
           // message: action.payload.message,
            //notificationList: action.payload?.data?.notificationList,
            isSuccess: action.payload,
          };
      default:
        return state;
    }
  };
  