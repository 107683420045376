import React, { useState, useEffect } from "react";
import profileImage from "../../../assets/images/profile.png";
import { ToastContainer, toast } from "react-toastify";
import * as constantMessages from "../../../constants/constatntMessages";
import "./Footer.css";
// @ts-ignore
import { useNavigate, Navigate, Link } from "react-router-dom";
// @ts-ignore
import ButtonGroup from "react-bootstrap/ButtonGroup";
// @ts-ignore
import Dropdown from "react-bootstrap/Dropdown";
// @ts-ignore
import DropdownButton from "react-bootstrap/DropdownButton";
import { logOut } from "../../../redux/actions/authActions/authAction";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchSingleUser,
  viewSingleUser,
} from "../../../redux/actions/userAction/userAction";
import CommonModal from "../CommonModal";
import { logOutMessage } from "../../../constants/constatntMessages";
import { setCreateNewMeetingPage } from "../../../redux/actions/meetingActions/MeetingAction";
import io from "socket.io-client";
import { viewAllNotifications } from "../../../redux/actions/notificationAction/NotificationAction";

// const socket = io.connect('http://localhost:8000');
console.log(process.env.REACT_APP_API_URL);
const Footer = () => {
  // const socket = io.connect("http://localhost:8080/", {
  //  // autoConnect: false,
  // //  transports: ["websocket"],
  // });
  const socket = io.connect("http://192.168.1.125:8080", {
    // autoConnect: false,
    transports: ["websocket"],
  });

  console.log(socket);
  const [name, setName] = useState("");
  // const [navigate, setNavigate] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const dispatch = useDispatch();

  const userData = JSON.parse(localStorage.getItem("userData"));
  const accessToken = localStorage.getItem("accessToken");
  const navigate = useNavigate();
  const handleLogout = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("userData");
    localStorage.removeItem("rememberMe");
    dispatch(logOut());
    setIsModalOpen(false);
    socket.emit("notification", "message from frontend");
    // alert("You will be log out");
  };
  const authData = useSelector((state) => state.auth);
  const employeeData = useSelector((state) => state.user);
  const notificationData = useSelector((state) => state.notification);
  console.log(socket);
  let notificationCalled = false;
  useEffect(() => {
    console.log("use effect called-------------------");
    socket.on("connection", () => {
      console.log(`I'm connected with the back-end`);
    });
    console.log(socket);
    socket.on("notification", (message) => {
      console.log(`notification is ${message}`);
      notificationCalled = true;
      //   dispatch(viewAllNotifications({organizationId:userData.organizationId}, accessToken));
      //   toast.info(constantMessages.notificationAdded, {
      //     position: "top-right",
      //     autoClose: 2000,
      //     hideProgressBar: false,
      //     closeOnClick: true,
      //     pauseOnHover: true,
      //     draggable: true,
      //     progress: undefined,
      //     theme: "colored",
      //     // transition: Bounce,
      //   });
      //  // alert(message)
      if (accessToken) {
        dispatch(
          viewAllNotifications(
            { organizationId: userData.organizationId },
            accessToken
          )
        );
      }
    });
    console.log("userData", employeeData.userData);
    if (userData) {
      setName(employeeData.userData?.name);
      dispatch(viewSingleUser(userData._id, accessToken));
    } else {
      navigate("/login");
    }

    // if (employeeData?.userData === null && !authData.isLogInSuccess) {
    //   localStorage.removeItem("accessToken");
    //   localStorage.removeItem("userData");
    //   localStorage.removeItem("rememberMe");
    //   navigate("/login");
    // }

    if (notificationCalled === false && accessToken) {
      dispatch(
        viewAllNotifications(
          { organizationId: userData?.organizationId },
          accessToken
        )
      );
    }
    return () => {
      console.log("disconnected----------------------");

      // socket.emit('disconect', () => {
      //   console.log(`I'm disconect with the back-end`);
      // });
    };
  }, [notificationData.isSuccess]);
  console.log(name);
  return (
    <>
      {!accessToken || employeeData?.userData === null ? (
        <Navigate to="/login" />
      ) : null}

      <section className="footer">
        <div className="container-fluid">
     
          <div className="create-meeting-button">
                    <Link
                      to="/create-meeting"
                      state={{ isNewMeeting: true }}
                      style={{ textDecoration: "none" }}
                    >
                      <button
                        className="create-meeting-button Mom-btn"
                        onClick={() => {
                          dispatch(setCreateNewMeetingPage(true));
                        }}
                      >
                        <p>Create Meeting</p>
                      </button>
                    </Link>
                  </div>
            
        
        </div>
        <CommonModal
          message={logOutMessage}
          title={"Logout"}
          setIsModalOpen={setIsModalOpen}
          isModalOpen={isModalOpen}
          handleSubmit={handleLogout}
          buttonName={"Logout"}
        />
      </section>
    </>
  );
};

export default Footer;
