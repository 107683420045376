import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Navigate, Link, useLocation, useNavigate,useParams } from "react-router-dom";
import { logOut } from "../../redux/actions/authActions/authAction";
import NoDataFound from "../Common/NoDataFound";
import Loader from "../Common/Loader";
import Alert from "../Common/Alert";
import { getMeetingActivitiesDetails } from "../../redux/actions/meetingActions/MeetingAction";
import {
  convertFirstLetterOfFullNameToCapital,
  convertFirstLetterToCapital,
  customName,
} from "../../helpers/commonHelpers";

const MeetingActivities = (props) => {
  let { meetingId } = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const authData = useSelector((state) => state.auth);
  const navigate = useNavigate();
  if (authData.isInValidUser) {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("userData");
    localStorage.removeItem("rememberMe");
    dispatch(logOut());
    navigate("/login");
  }
  const stateData = location.state;
  console.log(stateData);
  const accessToken = localStorage.getItem("accessToken");
  const userData = JSON.parse(localStorage.getItem("userData"));
  const meetingRoomData = useSelector((state) => state.meetingRoom);
  const meetingData = useSelector((state) => state.meeting);
  const minuteData = useSelector((state) => state.minute);
  const [isViewMeetingPage, setIsViewMeetingPage] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [attendeesData, setAttendeesData] = useState([]);
  console.log(meetingData);
  const employeeData = useSelector((state) => state.user);
  console.log(meetingData);

  useEffect(() => {
    if (!props.meetingId && accessToken) {
      console.log(meetingData)
      dispatch(
        getMeetingActivitiesDetails(
          meetingId,
          accessToken
        )
      );
    }
    else{
      console.log(props.meetingId)
      if(accessToken){
      dispatch(getMeetingActivitiesDetails(props.meetingId, accessToken))
      }
    }
   
    return () => {
      // Anything in here is fired on component unmount.
      console.log("component un mount");
      // dispatch(updateIsSuccess(false));
    };
  }, [meetingData?.meetingActivitiesDetails?.length]);
  console.log(meetingData?.meetingActivitiesDetails);
  return (
    <div className="form-group agenda mom-activities">
      <label className="mt-3 mb-3 add-agenda">
        <h4>MOM Activities</h4>
      </label>

      <div className="mt-2 agenda-box-border attendees-popup-list">
        {/* <div className="form-group m-0">
          <div className="row">
            <div className="col-12">
              <label className="mt-1 p-3 topic-head">
                {" "}
                MOM Meeting Activities
              </label>
            </div>
          </div>
        </div> */}
        {meetingData?.meetingActivitiesDetails?.length > 0 &&
        !meetingData.loading ? (
          <>
            {meetingData?.meetingActivitiesDetails.map((activity) => {
              return (
                <div className=" form-group p-3 m-0">
                 
                    <div className="inner-notification req-by">
                      <div>
                      <div className="attendee-list pk me-3">
                        {customName(activity.employeeDetails?.name)}
                      </div>
                      </div>
                      <div className="text">
                        <div className="heading">
                          {convertFirstLetterToCapital(activity?.activityDetails)}{" "}
                          {convertFirstLetterToCapital(activity?.activityTitle)}{" "}
                          by{" "}
                          {convertFirstLetterOfFullNameToCapital(
                            activity.employeeDetails?.name
                          )}
                        </div>

                        <div className="date">{activity.date}</div>
                        {/* <div className="mt-3">
                    <button className="reset" type="button">
                    <p>View Details</p>
                  </button>
                  </div> */}
                      </div>
                    </div>
                  
                </div>
              );
            })}
          </>
        ) : meetingData?.meetingActivitiesDetails?.length === 0 &&
          !meetingData.loading ? (
          <div className="no-data-img">
            <Alert
              status={"info"}
              message={"No data available."}
              timeoutSeconds={0}
            />
            <NoDataFound dataType={"meetingActivities"} />
          </div>
        ) : (
          <div
            className="meeting-page "
            style={{ textAlign: "center", paddingTop: 20 }}
          >
            <Loader />
          </div>
        )}
      </div>
    </div>
  );
};

export default MeetingActivities;
