

// const AttendeesDetails = (props) => {
//   console.log(props.attendeesData.attendees);
//   const { attendees } = props.attendeesData;
//   return (
//     <>
//       <h4 className="meeting-header-text">Attendee(s)</h4>
//       <form className="mt-2 details-form details-form-right">
//         <div className="form-group mt-2">
//           <div className="status-detail">
//             <p className="rsvpStatus">Summary</p>
//             <div className="respond-button">
//               <button className="respond-action" disabled>
//                 {checkRsvpCount(attendees).yesCount} Yes
//               </button>
//               <button className="respond-action" disabled>
//                 {checkRsvpCount(attendees).noCount} No
//               </button>
//               <button className="respond-action" disabled>
//                 {checkRsvpCount(attendees).mayBeCount} May Be
//               </button>
//               <button className="respond-action" disabled>
//                 {checkRsvpCount(attendees).pendingCount} Awaiting
//               </button>
//               {/* <p>{attendees.length} Attendees</p>
//                         <p className="detail-date-time">
//                           {checkRsvpCount(attendees)}
//                         </p> */}
//             </div>
//           </div>

//           <table className="mt-3 table">
//             <thead>
//               <tr>
//                 <th scope="col">Sl. No.</th>
//                 <th scope="col">Attendees</th>
//                 <th scope="col">RSVP</th>
//               </tr>
//             </thead>
//             <tbody>
//               {attendees.length !== 0 ? (
//                 <>
//                   {attendees.map((attendee, index) => {
//                     return (
//                       <tr key={index}>
//                         <th scope="row">{index + 1}</th>
//                         <td data-label="Attendees">
//                           <div className="attendees">
//                             <div className="attendee-list">
//                               {" "}
//                               {customName(attendee.name)}
//                             </div>
//                             <span className="ms-2">
//                               {convertFirstLetterOfFullNameToCapital(
//                                 attendee.name
//                               )}
//                             </span>
//                           </div>
//                         </td>
//                         <td data-label="RSVP">
//                           {convertFirstLetterToCapital(attendee.rsvp)}
//                         </td>
//                       </tr>
//                     );
//                   })}
//                 </>
//               ) : (
//                 <>
//                   <Alert
//                     status={"info"}
//                     message={"No Attendee Added"}
//                     timeoutSeconds={0}
//                   />
//                   <div className="mt-3 agenda-box-border">
//                     <NoDataFound dataType={"attendee"} />
//                   </div>
//                 </>
//               )}
//             </tbody>
//           </table>
//         </div>
//       </form>
//     </>
//   );
// };

// export default AttendeesDetails;



import React from "react";
import NoDataFound from "../Common/NoDataFound";
import { Alert } from "bootstrap";
import Badge from "react-bootstrap/Badge";
import {
  convertFirstLetterOfFullNameToCapital,
  convertFirstLetterToCapital,
  customName,
} from "../../helpers/commonHelpers";
import { useSelector, useDispatch } from "react-redux";
import Loader from "../Common/Loader";

const AttendeesDetails = (props) => {
  const meetingData = useSelector((state) => state.meeting);
  console.log(meetingData);
  const { attendees } = props.attendeesData;
  return (
    <div className="form-group agenda">
      <label className="mt-2 mb-3 add-agenda">
        <h4>Attendee(s)</h4>
      </label>
      <div className="mt-2 agenda-box-border attendees-popup-list">
        {/* <div className="form-group m-0">
          <div className="row">
            <div className="col-12">
              <label className="mt-1 p-3 topic-head"> Meeting Attendance</label>
            </div>
          </div>
        </div> */}

        {attendees.length > 0 ? (
          <form className=" details-form details-form-right responsive-table tbl-2-responsive">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th scope="col">Sl. No.</th>
                  <th scope="col">Attendees</th>
                  <th scope="col">RSVP</th>
                </tr>
              </thead>

              <tbody>
                {attendees.length !== 0 ? (
                  <>
                    {attendees.map(
                      (attendee, index) => {
                        return (
                          <tr key={index}>
                            <td data-label="Sl. No." className="blue-bold-text" scope="row">{index + 1}</td>
                            <td data-label="Attendees">
                              <div className="attendees responsive-right">
                                <div className="attendee-list">
                                  {customName(attendee.name)}
                                </div>
                                {/* <span className="ms-2">
                                  {convertFirstLetterOfFullNameToCapital(
                                    attendee.name
                                  )}
                                </span> */}
                                <div className="action-cmnt-text">
                                  <p className="detail-name">
                                    {convertFirstLetterOfFullNameToCapital(
                                      attendee.name
                                    )}
                                  </p>
                                  {/* body > ngb-modal-window > div > div > div.modal-body.attendees-popup > div:nth-child(1) > div.action-cmnt-text >  */}
                                  <p className="name-undertext comment-text">
                                    {attendee.email}
                                  </p>
                                </div>
                              </div>
                            </td>
                            <td data-label="RSVP">
                           {convertFirstLetterToCapital(attendee.rsvp)}
                        </td>
                          </tr>
                        );
                      }
                    )}
                  </>
                ) : null}
              </tbody>
            </table>
          </form>
        ) : attendees.length === 0 &&
          !meetingData.loading ? (
          <div className="no-data-img">
            <Alert
              status={"info"}
              message={"No data available."}
              timeoutSeconds={0}
            />
            <NoDataFound dataType={"meetingActivities"} />
          </div>
        ) : (
          <div
            className="meeting-page "
            style={{ textAlign: "center", paddingTop: 20 }}
          >
            <Loader />
          </div>
        )}
      </div>
    </div>
  );
};

export default AttendeesDetails;

