import { OPEN_SIDEBAR, CLOSE_SIDEBAR } from "./actionTypes";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import { setInValidUser } from "../authActions/authAction";
import * as constantMessages from "../../../constants/constatntMessages";

export const openSidebar = () => {
  return {
    type: OPEN_SIDEBAR
  };
};
export const closeSidebar = () => {
  return {
    type: CLOSE_SIDEBAR
  };
};