import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import * as constantMessages from "../../constants/constatntMessages";
import { setFinalMinuteData } from "../../redux/actions/minuteActions/MinuteAction";
import { checkCharacterLeft } from "../../helpers/commonHelpers";
import { textBoxStrLength } from "../../config/config";
import { useSelector, useDispatch } from "react-redux";
import { approveAction, reopenAction } from "../../redux/actions/actionAction/ActionAction";
import LoaderButton from "../Common/LoaderButton";
const ReopenActionModal = (props) => {
  console.log(props);
  const dispatch = useDispatch();
  const [reason, setReason] = useState("");
  const [errors, setErrors] = useState({});
  const actionData = useSelector((state) => state.action);
  console.log(actionData);
  useEffect(() => {
    console.log("in-------------22222222222222");
    if (actionData?.isSuccess === true) {
      props.setIsReopenMinuteModal(false);
    }
    return () => {
      console.log("call--------------->>>>>>>>>>>>>>");
      setReason("");
    };
  }, [actionData.isSuccess]);

  const handleSubmit = () => {
    const newErrors = validateForm();
    setErrors(newErrors);
    if (Object.keys(newErrors).length === 0) {
      const payload = {
        remark: reason,
        assignedUserDetails: props?.minuteDetails?.assignedUserDetails,
      };
      dispatch(
        reopenAction(props.parentMeetingId,props?.minuteDetails?._id, payload, props?.accessToken)
      );
      // props.handleReopenSubmit(reason);
    }
  };

  const handleApproveSubmit = () => {
  
      const payload = {
        remark: reason,
        assignedUserDetails: props?.minuteDetails?.assignedUserDetails,
       
      };
      dispatch(
        approveAction( props.parentMeetingId,props?.minuteDetails?._id, payload, props?.accessToken)
      );
      // props.handleReopenSubmit(reason);
    }
  
  const validateForm = () => {
    console.error(reason);
    const errors = {};
    if (!reason.trim()) {
      errors.reason = constantMessages.reasonRequired;
    }
    setErrors(errors);
    return errors;
  };

  const closeModal = () => {
    setReason("");
    setErrors({});
    props.setIsReopenMinuteModal(false);
  };

  const updateValues = (e) => {
    setErrors({});
    const { name, value } = e.target;
    if (value.length <= textBoxStrLength) {
      setReason(value);
    }
  };
  

  //   console.log(props);
  //   const dispatch = useDispatch();
  //   const handleSubmit = () => {
  //     dispatch(deleteMinute(props.meetingId, props.minuteId, props.accessToken));
  //     props.setIsDeleteMinuteModal(false);
  //   };

  //   const closeModal = () => {
  //     props.setIsDeleteMinuteModal(false);
  //   };

  return (
    <>
      <Modal show={props?.IsReopenMinuteModal} onHide={closeModal}>
        {props?.minuteDetails?.modalStatus === "REOPEN" ? (
          <>
            <div className="modal-header">
              <h4 className="modal-title">Reopen Action</h4>
              
              <button
                type="button"
                className="btn-close"
                aria-label="Close"
                onClick={closeModal}
              ></button>
            </div>
            <div className="modal-body delete-txt">
              
              <label className="mb-1"> Reason
              <span className="star-mark">*</span>
              </label>
            
           
              <textarea
                cols="40"
                rows="4"
                id="remark"
                name="remark"
                onBlur={validateForm}
                onChange={updateValues}
                value={reason}
                required
              ></textarea>
              <p className="success-message">
                {checkCharacterLeft(reason)} Characters left
              </p>
              {errors.reason && (
                <span className="error-message">{errors.reason}</span>
              )}
            </div>

            <div className="modal-footer">
            {actionData.loading && !actionData.isSuccess  ?  <LoaderButton />:(
              <button
                type="button"
                onClick={handleSubmit}
                className="Mom-btn btn btn-secondary bg-primary border-primary"
              >
                <p>Reopen</p>
              </button>
            )}
            </div>
          </>
        ) : (
          <>
            <div className="modal-header">
              <h4 className="modal-title">Approve Action</h4>
              <button
                type="button"
                className="btn-close"
                aria-label="Close"
                onClick={closeModal}
              ></button>
            </div>
            <div className="modal-body delete-txt">
              <h6>Remark</h6>
              <textarea
                cols="40"
                rows="4"
                id="remark"
                name="remark"
               // onBlur={validateForm}
                onChange={updateValues}
                value={reason}
                required
              ></textarea>
              <p className="success-message">
                {checkCharacterLeft(reason)} Characters left
              </p>
              {errors.reason && (
                <span className="error-message">{errors.reason}</span>
              )}
            </div>

            <div className="modal-footer">
            {actionData.loading && !actionData.isSuccess  ?  <LoaderButton />:(
              <button
                type="button"
                onClick={handleApproveSubmit}
                className="Mom-btn btn btn-secondary bg-primary border-primary"
              >
                <p>Approve</p>
              </button>
            )}
            </div>
          </>
        )}
      </Modal>
    </>
  );
};

export default ReopenActionModal;
