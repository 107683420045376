import React, { useEffect, useState } from "react";
import Select from "react-select";

const EditDepartmentDropdown = ({
  departments,
  selectedDepartmentId,
  handleChange,
  value,
}) => {
  const [department, setDepartment] = useState(value);
  const departmentOptions = departments
    .filter((dep) => dep.isActive)
    .map((department) => ({
      value: department?._id,
      label: department?.name,
    }));

  const handleDepartmentChange = (selectedOption) => {
    console.log(selectedOption);
    setDepartment({
      name: "departmentId",
      label: selectedOption ? selectedOption.label : "",
      value: selectedOption ? selectedOption.value : "",
    });
    handleChange({
      name: "departmentId",
      label: selectedOption ? selectedOption.label : "",
      value: selectedOption ? selectedOption.value : "",
    });
  };
  return (
    <div className="form-group">
      <label className="mb-1">
        Department<span className="star-mark"> *</span>
      </label>
      <Select
        name="departmentId"
        value={department}
        onChange={handleDepartmentChange}
        options={departmentOptions}
        placeholder="Select Department"
      />
    </div>
  );
};
export default EditDepartmentDropdown;
