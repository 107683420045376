import React, { useEffect } from "react";
import NoDataFound from "../Common/NoDataFound";
import Alert from "../Common/Alert";
import Badge from "react-bootstrap/Badge";
import {
  convertFirstLetterOfFullNameToCapital,
  customName,
} from "../../helpers/commonHelpers";
import { useSelector, useDispatch } from "react-redux";
import Loader from "../Common/Loader";
import { getMomAcceptDetails, setMomAcceptDetails } from "../../redux/actions/minuteActions/MinuteAction";

const MOMAcceptanceDetails = (props) => {
  const dispatch = useDispatch();
  const accessToken = localStorage.getItem("accessToken");
  const minuteData = useSelector((state) => state.minute);
  console.log(minuteData?.momAcceptDetails);
  useEffect(() => {
    console.log("api called");
    dispatch(getMomAcceptDetails(props?.meetingId, accessToken));
  }, []);

  return (
    <div className="form-group agenda">
      <label className="mt-2 mb-3 add-agenda">
        <h4>MOM Acceptance Details</h4>
      </label>
      <div className="mt-2 agenda-box-border attendees-popup-list">
        {/* <div className="form-group m-0">
          <div className="row">
            <div className="col-12">
              <label className="mt-1 p-3 topic-head"> Meeting Attendance</label>
            </div>
          </div>
        </div> */}

        {minuteData?.momAcceptDetails?.length !== 0 ? (
          <form className=" details-form details-form-right">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">Sl. No.</th>
                  <th scope="col">Attendees</th>
                  <th scope="col">Status</th>
                </tr>
              </thead>

              <tbody>
                {minuteData?.momAcceptDetails?.length !== 0 ? (
                  <>
                    {minuteData?.momAcceptDetails?.map((attendee, index) => {
                      return (
                        <tr key={index}>
                          <th scope="row">{index + 1}</th>
                          <td data-label="Attendees">
                            <div className="attendees">
                              <div className="attendee-list">
                                {customName(attendee?.userDetails?.name)}
                              </div>
                              {/* <span className="ms-2">
                                  {convertFirstLetterOfFullNameToCapital(
                                    attendee.name
                                  )}
                                </span> */}
                              <div className="action-cmnt-text">
                                <p className="detail-name">
                                  {convertFirstLetterOfFullNameToCapital(
                                    attendee?.userDetails?.name
                                  )}
                                </p>
                                {/* body > ngb-modal-window > div > div > div.modal-body.attendees-popup > div:nth-child(1) > div.action-cmnt-text >  */}
                                <p className="name-undertext comment-text">
                                  {attendee?.userDetails?.email}
                                </p>
                              </div>
                            </div>
                          </td>
                          <td data-label="ATTENDANCE">
                            {attendee?.isAutoAccepted ? (
                              <p>
                                <Badge bg="success">Accepted (Auto)</Badge>
                              </p>
                            ) : (
                              <p>
                                <Badge bg="info">Accepted</Badge>
                              </p>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </>
                ) : null}
              </tbody>
            </table>
          </form>
        ) : minuteData?.momAcceptDetails?.length === 0 &&
          !minuteData?.loading ? (
          <div className="no-data-img">
            <Alert
              status={"info"}
              message={"No data available."}
              timeoutSeconds={0}
            />
            <NoDataFound dataType={"meetingActivities"} />
          </div>
        ) : (
          <div
            className="meeting-page "
            style={{ textAlign: "center", paddingTop: 20 }}
          >
            <Loader />
          </div>
        )}
      </div>
    </div>
  );
};

export default MOMAcceptanceDetails;
