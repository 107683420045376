import React from "react";
import Header from "../Common/Header/Header";
import Sidebar from "../Common/Sidebar/Sidebar";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, Navigate, Link } from "react-router-dom";
import importantLogo from "../../assets/images/star.png";
import "./style/notification.css";
import { convertFirstLetterOfFullNameToCapital } from "../../helpers/commonHelpers";
import ActionDropDown from "../Action/ActionDropDown";
import NotificationDropdown from "./NotificationDropDown";
import NotificationFilterDropDown from "./NotificationFilterDropDown";
import Alert from "../Common/Alert";
import NoDataFound from "../Common/NoDataFound";
import Loader from "../Common/Loader";

import { logOut } from "../../redux/actions/authActions/authAction";
const ViewNotification = () => {
  const accessToken = localStorage.getItem("accessToken");
  const userData = JSON.parse(localStorage.getItem("userData"));
  const authData = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  if (authData.isInValidUser) {
    console.log("innnnnnnnnnnnnnnnnnnnnnnnnnnn");
    localStorage.removeItem("accessToken");
    localStorage.removeItem("userData");
    localStorage.removeItem("rememberMe");
    dispatch(logOut());
    navigate("/login");
  }
  const notificationData = useSelector((state) => state.notification);
  const meetingData = useSelector((state) => state.meeting);
  console.log(notificationData);
  const notificationId = notificationData?.notificationList?.map((nId) => {
    console.log(nId);
  });
  // notificationId();
  console.log(notificationId);
  return (
    <>
      <Header />
      <Sidebar />

      <div className="main-content">
        <div className="row">
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 detail-col">
            <section className="notification-page">
              <div className="meeting-header-text">
                <div>
                  <h4>Notifications</h4>
                </div>
                {/* <div className="d-inline-block custom-dropdown  notification-filter">
                 */}
                <NotificationFilterDropDown />
              </div>

              <div className="table-box mt-2">
                {notificationData?.notificationList?.length !== 0 ? (
                  <>
                    {notificationData?.notificationList?.map((notification) => {
                      return (
                        <div
                          className={
                            notification.isRead
                              ? "notification unseen"
                              : "notification"
                          }
                        >
                          <div className="inner-notification">
                            {/* <div className="round-bg1">PK</div> */}
                            <div className="text">
                              <div className="heading">
                                {notification?.isImportant ? (
                                  <img src={importantLogo} />
                                ) : null}
                                <Link
                                  to={
                                    notification?.type === "MEETING"
                                      ? `/view-meeting-details/${notification?.typeId?.toString()}`
                                      : notification?.type === "ACTION"
                                      ? `/view-action-details/${notification?.typeId?.toString()}`
                                      : notification?.type === "MINUTE"
                                      ? `/view-minute-details/${notification?.typeId?.toString()}`
                                      : undefined
                                  }
                                  // state={{ meetingId: props.meetingId,isViewMeetingPage:true}}
                                  // style={{
                                  //   textDecoration: "none",
                                  //   color: "black",
                                  // }}
                                >
                                  {convertFirstLetterOfFullNameToCapital(
                                    notification.title
                                  )}
                                </Link>
                              </div>
                              <div className="msg">
                                {/* Hi, Sonali ! Quick reminder that our scheduled
                                meeting is just a day away. */}
                                {notification?.details
                                  ? convertFirstLetterOfFullNameToCapital(
                                      notification?.details
                                    )
                                  : null}
                                {/* {notification?.details} */}
                              </div>
                              <div className="topic">
                                Meeting Title :{" "}
                                {notification?.meetingTitle
                                  ? convertFirstLetterOfFullNameToCapital(
                                      notification?.meetingTitle
                                    )
                                  : null}
                              </div>
                              <div className="date">
                                {notification.date} , {notification.time}
                              </div>
                            </div>
                          </div>
                          <div className="d-inline-block menu-dropdown custom-dropdown">
                            <NotificationDropdown
                              notificationId={notification._id}
                              notification={notification}
                              //  status={meeting.meetingStatus.status}
                              // handleRequestForReassignModal={() => {
                              //   handleRequestForReassignModal(action._id);
                              // }}
                              // handleReassignModal={() => {
                              //   handleReassignModal(action._id);
                              // }}
                            />
                          </div>
                        </div>
                      );
                    })}
                  </>
                ) : !notificationData?.loading &&
                  notificationData?.notificationList?.length === 0 ? (
                  <div className="no-data-img">
                    <Alert
                      status={"info"}
                      message={"No data available."}
                      timeoutSeconds={0}
                    />
                    {/* <Alert message="No Data Found !" status={false} /> */}

                    <NoDataFound dataType={"meeting"} />
                  </div>
                ) : (
                  <div
                    className="meeting-page "
                    style={{ textAlign: "center", paddingTop: 20 }}
                  >
                    <Loader />
                  </div>
                )}
              </div>
            </section>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewNotification;
