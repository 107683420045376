import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import * as constantMessages from "../../constants/constatntMessages";
import { setFinalMinuteData } from "../../redux/actions/minuteActions/MinuteAction";
import { checkCharacterLeft, convertFirstLetterOfFullNameToCapital } from "../../helpers/commonHelpers";
import { textBoxStrLength } from "../../config/config";
import { useSelector, useDispatch } from "react-redux";
import { reopenAction } from "../../redux/actions/actionAction/ActionAction";
const OtherModal = (props) => {
  console.log(props);
  const dispatch = useDispatch();
  const [reason, setReason] = useState("");
  const [errors, setErrors] = useState({});

  useEffect(() => {
    return () => {
      console.log("call--------------->>>>>>>>>>>>>>");
      setReason("");
    };
  }, []);

  const closeModal = () => {
    setReason("");
    setErrors({});
    props.setIsModalOpen(false);
  };

  return (
    <>
      <Modal show={props.IsModalOpen} onHide={closeModal}>
        <div className="modal-header">
          <h4 className="modal-title">{props?.activity?.activityTitle?convertFirstLetterOfFullNameToCapital(props?.activity?.activityTitle):null}</h4>
          <button
            type="button"
            className="btn-close"
            aria-label="Close"
            onClick={closeModal}
          ></button>
        </div>
        <div className="modal-body delete-txt">
          <h6>{props?.activity?.activityDetails}</h6>
        </div>
      </Modal>
    </>
  );
};

export default OtherModal;
