import { setInValidUser } from "../authActions/authAction";
import {
  FAIL_REQUEST,
  MAKE_REQUEST,
  GET_NOTIFICATION_LIST,
  UPDATE_ISSUCCESS,
} from "./actionTypes";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import * as constantMessages from "../../../constants/constatntMessages";


export const makeRequest = (data) => {
  return {
    type: MAKE_REQUEST,
    payload: data,
  };
};
export const failRequest = (err) => {
  return {
    type: FAIL_REQUEST,
    payload: err,
  };
};
export const updateSuccess = (data) => {
  return {
    type: UPDATE_ISSUCCESS,
    payload: data,
  };
};
export const viewAllNotifications = (payload, accessToken) => {
  return (dispatch) => {
    dispatch(makeRequest());
    const webApiUrl = `${process.env.REACT_APP_API_URL}/api/V1/notification/viewNotifications`;
    const headerObject = {
      headers: {
        "Content-Type": "application/json",
        Authorization: accessToken,
      },
      // params: {
      //   limit: payload.limit,
      //   page: payload.page,
      //   order: payload.order,
      // },
    };
    console.log("webApiUrl----------------", webApiUrl);
    console.log("accessToken------------>>>>>", accessToken);
    const bodyPayload = {
      // actionStatus: payload.actionStatus ? payload.actionStatus : undefined,
      // createdById: payload.createdById ? payload.createdById : undefined,
      organizationId: payload.organizationId,
      // meetingId: payload.meetingId,
      // searchKey: payload.searchKey ? payload.searchKey : undefined,
      // // meetingStatus: payload.meetingStatus ? payload.meetingStatus : undefined,
      // toDate: payload.toDate ? payload.toDate : undefined,
      // fromDate: payload.fromDate ? payload.fromDate : undefined,
    };
    axios
      .post(webApiUrl, bodyPayload, headerObject)
      .then((result) => {
        console.log("result------------------------->>>>>>>", result);
        const resData = result.data;
        console.log("resData------------------------->>>>>>>", resData);
        if (resData.data?.isInValidUser) {
          dispatch(setInValidUser(true));
        }
        if (
          (!resData.success && resData.data?.totalCount !== 0) ||
          resData.data?.isInValidUser
        ) {
          // toast.error(resData.message, {
          //   position: "top-right",
          //   autoClose: 5000,
          //   hideProgressBar: false,
          //   closeOnClick: true,
          //   pauseOnHover: true,
          //   draggable: true,
          //   progress: undefined,
          //   theme: "colored",
          //   // transition: Bounce,
          // });
        }
        console.log("resData------------------------->>>>>>>", resData);
        dispatch(getNotificationList(resData));
      })
      .catch((err) => {
        console.log("err------------------------->>>>>>>", err);
        dispatch(failRequest(err.message));
        toast.error(constantMessages.serverErrorMessage, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          // transition: Bounce,
        });
      });
  };
};

export const getNotificationList = (data) => {
  return {
    type: GET_NOTIFICATION_LIST,
    payload: data,
  };
};

