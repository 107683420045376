import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import FormGroup from "@mui/material/FormGroup";
import Switch from "@mui/material/Switch";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import axios from "../../../../node_modules/axios/index";

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));

const CustomizedSwitches = ({ employee, onToggle }) => {
  const userData = JSON.parse(localStorage.getItem("userData"));
  const userId = userData._id;
  const accessToken = localStorage.getItem("accessToken");
  const organizationId = userData?.organizationId;
  const [empId, setEmpId] = useState("");
  const [isActive, setIsActive] = useState(employee.isActive);
  console.log(isActive);
  useEffect(() => {
    if (employee) {
      setIsActive(employee.isActive);
    }
  }, [employee]);

  const handleToggle = async (empId, isactive) => {
    // const newStatus = event.target.checked;
    // console.log(newStatus);
    console.log(empId);
    const updateEmployee = { isActive: isactive };
    const response = await axios.put(
      `${process.env.REACT_APP_API_URL}/api/V1/employee/editEmployee/${empId}`,
      updateEmployee,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: accessToken,
        },
      }
    );
    setIsActive(!isActive);
    // onToggle(employee.id, response);
  };

  return (
    <FormGroup>
      <Stack direction="row" spacing={1} alignItems="center">
        <Typography>{isActive ? "Active" : "Deactive"}</Typography>
        {/* <AntSwitch
          checked={isActive}
          onClick={() => {
            handleToggle(employee._id, !isActive);
          }}
          inputProps={{ "aria-label": "ant design" }}
        /> */}

        <Switch
          checked={isActive}
          onClick={() => {
            handleToggle(employee._id, !isActive);
          }}
          inputProps={{ "aria-label": "ant design" }}
        />
      </Stack>
    </FormGroup>
  );
};

export default CustomizedSwitches;
