import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import * as constantMessages from "../../constants/constatntMessages";
import { useSelector, useDispatch } from "react-redux";
import { deleteMinute } from "../../redux/actions/minuteActions/MinuteAction";

const DeleteMeetingModal = (props) => {
  console.log(props);
  const dispatch = useDispatch();
  const handleSubmit = () => {
    dispatch(deleteMinute(props.meetingId, props.minuteId, props.accessToken));
    props.setIsDeleteMinuteModal(false);
  };

  const closeModal = () => {
    props.setIsDeleteMinuteModal(false);
  };

  return (
    <>
      <Modal show={props.IsDeleteMinuteModal} onHide={closeModal}>
        <div className="modal-header">
          <h4 className="modal-title">Do you want to delete the minute ?</h4>
          <button
            type="button"
            className="btn-close"
            aria-label="Close"
            onClick={closeModal}
          ></button>
        </div>

        <div className="modal-footer">
          <button
            type="button"
            onClick={handleSubmit}
            className="Mom-btn btn btn-secondary bg-primary border-primary"
          >
            <p>Delete</p>
          </button>
          <button
            type="button"
            onClick={closeModal}
            className="reset btn btn-light bg-white border-primary text-primary "
          >
            <p>Close</p>
          </button>
        </div>
      </Modal>
    </>
  );
};

export default DeleteMeetingModal;
